<template>
  <v-container v-if="!loading" fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="12" md="12" sm="12">
        <div class="user-login">
          <v-card
            class="mx-auto login-card px-4 py-6"
            elevation="5"
            width="23rem"
            height="480px"
          >
            <v-card-text>
              <div class="doormeet-login text-center">
                <div class="login-title text-center pb-12">
                  <h1 class="font-bold mb-4">Admin Login</h1>
                  <!-- <div class="d-flex justify-content-center">
                    <v-divider class=" mb-1 mx-auto title-divider"></v-divider>
                  </div> -->
                  <div class="doormeet-logo">
                  <img src="/img/sajha.jpg" alt="" width="35%" />
                </div>
                </div>
                
                
                <v-form ref="form" v-model="loginValid" lazy-validation>
                  <v-text-field
                    v-model.trim="login_email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-model.trim="login_password"
                    outlined
                    dense
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :type="show3 ? 'text' : 'password'"
                    name="input-10-2"
                    label="Password"
                    hint="At least 8 characters with one number and special character"
                    class="input-group--focused"
                    @click:append="show3 = !show3"
                  ></v-text-field>

                  <v-btn
                    color="#d2302c"
                    class="btn-block mt-8"
                    dark
                    :disabled="loginBtn"
                    :loading="loginLoad"
                    @click="login"
                    >Login Securely</v-btn
                  >
                </v-form>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
  <circular-loaders v-else></circular-loaders>
</template>
<script>
import Swal from "sweetalert2";
import CircularLoaders from "../loaders/CircularLoaders";
export default {
  components: {
    CircularLoaders
  },
  data() {
    return {
      loginLoad: false,
      loginValid: true,
      login_password: "",
      loading: false,
      login_email: "",
      show3: false,
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      passwordRules: [
        v => !!v || "Password is required",
        v => (v && v.length >= 6) || "Password must have 6+ characters",
        v => /(?=.*\d)/.test(v) || "Password must have one number"
      ]
    };
  },
  computed: {
    loginBtn() {
      return !this.loginValid;
    }
  },
  methods: {
    login() {
      this.loginLoad = !this.loginLoad;
      const credential = {
        email: this.login_email,
        password: this.login_password
      };
      this.$store
        .dispatch("login", credential)
        .then(response => {
          if (response[0] === 422) {
            Swal.fire("Failed", response.message, "error");
          } else {
            if (response.roles.slug === "super-admin") {
              this.$router.push("/dashboard");
            } else {
              Swal.fire("Failed", "Unauthorized Access", "error");
            }
          }

          this.loginLoad = !this.loginLoad;
        })
        .catch(error => {
          this.loginLoad = !this.loginLoad;
          Swal.fire("Failed", error.message, "error");
        });
    }
  },

  created() {
    this.loading = true;
    if (localStorage.getItem("a_token")) {
      this.$store
        .dispatch("getUser")
        .then(response => {
          if (response.data.roles.slug === "super-admin") {
            const redirectUrl = this.$route.query.redirect;
            redirectUrl
              ? this.$router.push(redirectUrl)
              : this.$router.push("/dashboard");
          } else {
            this.loading = false;
            localStorage.removeItem("a_token");
          }
        })
        .catch(() => {
          localStorage.removeItem("a_token");
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }
};
</script>
<style>
.font-bold {
  color: #000;
  font-weight: 600;
  font-family: "Roboto", "Courier New", Courier, monospace, sans-serif;
}
.login-card {
  border-radius: 0.75rem !important;
}
.title-divider {
  border-bottom: 6px solid #d2302c !important;
  max-width: 130px !important;
  margin-top: 0.75rem;
  border-radius: 0.5rem;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(172, 165, 165, 0.12) !important;
  color: #000 !important;
}
</style>
