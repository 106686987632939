var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',[_vm._v("Closed Tickets "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getSupport,"search":_vm.search},scopedSlots:_vm._u([{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"id":'descriptionDialog' + item.id,"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",attrs:{"size":"14"}},[_vm._v("mdi-eye")]),_vm._v(" Subject ")],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Subject Details")]),_c('v-card-text',{staticClass:"py-2"},[_c('p',[_vm._v(_vm._s(item.subject))])])],1)],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"id":'descriptionDialog' + item.id,"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",attrs:{"size":"14"}},[_vm._v("mdi-eye")]),_vm._v(" Description ")],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Support Description")]),_c('v-card-text',{staticClass:"py-2"},[_c('p',[_vm._v(_vm._s(item.description))])])],1)],1)]}},{key:"item.support_image",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"id":'imageDialog' + item.id,"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",attrs:{"size":"14"}},[_vm._v("mdi-eye")]),_vm._v(" Image ")],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Support Image")]),_c('v-card-text',{staticClass:"py-2"},[_c('img',{attrs:{"src":item.image,"alt":""}})])],1)],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }