import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../components/login/login.vue"),
  },
  {
    path: "/dashboard",
    component: () => import("../components/layouts/Layout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import("../views/pages/Dashboard.vue"),
      },
      {
        path: "/account",
        name: "Account",
        component: () => import("../views/pages/account/Account.vue"),
      },
      {
        path: "/products/all-products",
        name: "Products",
        component: () => import("../views/pages/products/Product.vue"),
      },
      {
        path: "/products/brands",
        name: "Brand",
        component: () => import("../views/pages/products/brand/Brand.vue"),
      },
      {
        path: "/products/tags",
        name: "Tags",
        component: () => import("../views/pages/products/tag/Tags.vue"),
      },
      {
        path: "/products/categories",
        name: "Categories",
        component: () =>
          import("../views/pages/products/categories/Categories.vue"),
      },
      {
        path: "/products/subcategories",
        name: "SubCategories",
        component: () =>
          import("../views/pages/products/categories/SubCategories.vue"),
      },
      {
        path: "/products/sub-subcategories",
        name: "SubSubCategories",
        component: () =>
          import("../views/pages/products/categories/SubSubCategories.vue"),
      },
      {
        path: "/products/reviews",
        name: "Reviews",
        component: () => import("../views/pages/products/reviews/Reviews.vue"),
      },
      {
        path: "/products/sizes",
        name: "Size",
        component: () => import("../views/pages/products/size/Size.vue"),
      },
      {
        path: "/flashdeals/all-flashdeals",
        name: "FlashDeal",
        component: () => import("../views/pages/flashdeals/FlashDeal.vue"),
      },
      {
        path: "/flash-deals/sellers-list",
        name: "SellerParticipation",
        component: () =>
          import("../views/pages/flashdeals/SellerParticipation.vue"),
      },
      {
        path: "/seller/add-seller",
        name: "addSeller",
        component: () => import("../views/subpages/seller/AddSeller.vue"),
      },
      {
        path: "/seller/edit-seller/:id",
        name: "editSeller",
        component: () => import("../views/subpages/seller/EditSeller.vue"),
        props: (route) => {
          const id = Number.parseInt(route.params.id);
          return { id };
        },
      },
      {
        path: "/seller/pending-seller",
        name: "PendingSellers",
        component: () => import("../views/pages/sellers/PendingSellers.vue"),
      },
      {
        path: "/seller/verified-seller",
        name: "VerifiedSellers",
        component: () => import("../views/pages/sellers/VerifiedSellers.vue"),
      },
      {
        path: "/seller/rejected-seller",
        name: "RejectedSellers",
        component: () => import("../views/pages/sellers/RejectedSellers.vue"),
      },
      {
        path: "/payments/withdraw-request",
        name: "WithdrawRequest",
        component: () => import("../views/pages/payments/WithdrawRequest.vue"),
      },
      {
        path: "/payments/pending-payments",
        name: "PendingPayments",
        component: () => import("../views/pages/payments/PendingPayments.vue"),
      },
      {
        path: "/payments/verified-payments",
        name: "VerifiedPayments",
        component: () => import("../views/pages/payments/VerifiedPayments.vue"),
      },
      {
        path: "/payments/rejected-payments",
        name: "RejectedPayments",
        component: () => import("../views/pages/payments/RejectedPayments.vue"),
      },
      {
        path: "/all-customers",
        name: "Users",
        component: () => import("../views/pages/customers/Users.vue"),
      },
      {
        path: "/all-buyers",
        name: "Buyers",
        component: () => import("../views/pages/customers/Buyers.vue"),
      },
      {
        path: "/order/active-orders",
        name: "AvtiveOrders",
        component: () => import("../views/pages/orders/ActiveOrders.vue"),
      },
      {
        path: "/order/pending-orders",
        name: "PendingOrders",
        component: () => import("../views/pages/orders/PendingOrders.vue"),
      },
      {
        path: "/order/cancelled-orders",
        name: "CancelledOrders",
        component: () => import("../views/pages/orders/CancelledOrders.vue"),
      },
      {
        path: "/order/completed-orders",
        name: "CompletedOrders",
        component: () => import("../views/pages/orders/CompletedOrders.vue"),
      },
      {
        path: "/drop-off-details",
        name: "DeliveryDropOff",
        component: () =>
          import("../views/pages/delivery-options/DeliveryDropOff.vue"),
      },
      {
        path: "/pick-up-details",
        name: "DeliveryPickUp",
        component: () =>
          import("../views/pages/delivery-options/DeliveryPickUp.vue"),
      },
      {
        path: "/delivery-price",
        name: "DeliveryCharge",
        component: () =>
          import("../views/pages/delivery-options/DeliveryCharge.vue"),
      },
      {
        path: "/support/new-tickets",
        name: "NewTickets",
        component: () =>
          import("../views/pages/support-tickets/NewTickets.vue"),
      },
      {
        path: "/support/pending-tickets",
        name: "PendingTickets",
        component: () =>
          import("../views/pages/support-tickets/PendingTickets.vue"),
      },
      {
        path: "/support/resolved-tickets",
        name: "ResolvedTickets",
        component: () =>
          import("../views/pages/support-tickets/ResolvedTickets.vue"),
      },
      {
        path: "/support/closed-tickets",
        name: "ClosedTickets",
        component: () =>
          import("../views/pages/support-tickets/ClosedTickets.vue"),
      },
      {
        path: "/report/sales-report",
        name: "SalesReport",
        component: () => import("../views/pages/reports/SalesReport.vue"),
      },
      {
        path: "/report/stocks-report",
        name: "StocksReport",
        component: () => import("../views/pages/reports/StocksReport.vue"),
      },
      {
        path: "/report/sellers-report",
        name: "SellersReport",
        component: () => import("../views/pages/reports/SellersReport.vue"),
      },
      {
        path: "/report/delivery-report",
        name: "DeliveryReport",
        component: () => import("../views/pages/reports/DeliveryReport.vue"),
      },
      {
        path: "/report/categories-report",
        name: "CategoriesReport",
        component: () => import("../views/pages/reports/CategoriesReport.vue"),
      },
      {
        path: "/report/wishlists-report",
        name: "WishlistReport",
        component: () => import("../views/pages/reports/WishlistReport.vue"),
      },
      {
        path: "/report/orders-report",
        name: "OrdersReport",
        component: () => import("../views/pages/reports/OrdersReport.vue"),
      },
      {
        path: "/staff-roles",
        name: "StaffRoles",
        component: () => import("../views/pages/staffs/StaffRoles.vue"),
      },
      {
        path: "/all-staffs",
        name: "Staffs",
        component: () => import("../views/pages/staffs/Staffs.vue"),
      },
      {
        path: "/all-riders",
        name: "Riders",
        component: () => import("../views/pages/riders/Riders.vue"),
      },
      {
        path: "/homepage-banner",
        name: "BannerImage",
        component: () =>
          import("../views/pages/frontend-settings/BannerImage.vue"),
      },
      {
        path: "/social-media-links",
        name: "SocialMedia",
        component: () =>
          import("../views/pages/frontend-settings/SocialMedia.vue"),
      },
      {
        path: "/about-us",
        name: "AboutUs",
        component: () => import("../views/pages/frontend-settings/AboutUs.vue"),
      },
      {
        path: "/deal",
        name: "Deal",
        component: () => import("../views/pages/frontend-settings/Deal.vue"),
      },
      {
        path: "/faq",
        name: "Faq",
        component: () => import("../views/pages/frontend-settings/Faq.vue"),
      },
      {
        path: "/user-support",
        name: "UserSupport",
        component: () =>
          import("../views/pages/frontend-settings/UserSupport.vue"),
      },
      {
        path: "/career",
        name: "Career",
        component: () => import("../views/pages/frontend-settings/Career.vue"),
      },
      {
        path: "/job-applications",
        name: "JobApplication",
        component: () =>
          import("../views/pages/frontend-settings/JobApplication.vue"),
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: () => import("../views/pages/policy/PrivacyPolicy.vue"),
      },
      {
        path: "/cookie-policy",
        name: "CookiePolicy",
        component: () => import("../views/pages/policy/CookiePolicy.vue"),
      },
      {
        path: "/data-policy",
        name: "dataPolicy",
        component: () => import("../views/pages/policy/DataPolicy.vue"),
      },
      {
        path: "/warranty-policy",
        name: "warrantyPolicy",
        component: () => import("../views/pages/policy/WarrantyPolicy.vue"),
      },
      {
        path: "/refund-policy",
        name: "refundPolicy",
        component: () => import("../views/pages/policy/RefundPolicy.vue"),
      },
      {
        path: "/buyer-protection",
        name: "buyerProtection",
        component: () => import("../views/pages/policy/BuyerProtection.vue"),
      },
      {
        path: "/seller-policy",
        name: "SellerPolicy",
        component: () => import("../views/pages/policy/SellerPolicy.vue"),
      },
      {
        path: "/return-policy",
        name: "ReturnPolicy",
        component: () => import("../views/pages/policy/ReturnPolicy.vue"),
      },
      {
        path: "/support-policy",
        name: "SupportPolicy",
        component: () => import("../views/pages/policy/SupportPolicy.vue"),
      },
      {
        path: "/terms",
        name: "TermsAndConditions",
        component: () => import("../views/pages/policy/TermsAndConditions.vue"),
      },
      {
        path: "/seo",
        name: "Seo",
        component: () => import("../views/pages/seo/Seo.vue"),
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: () =>
          import("../views/pages/notifications/Notifications.vue"),
      },
      {
        path: "/coupons",
        name: "Coupons",
        component: () => import("../views/pages/coupons/Coupons.vue"),
      },
      {
        path: "/contact-message",
        name: "ContactMessage",
        component: () => import("../views/pages/customers/ContactMessage"),
      },
      {
        path: "/custom-order/new-orders",
        name: "NewCustomOrders",
        component: () =>
          import("../views/pages/customOrders/NewCustomOrders.vue"),
      },
      {
        path: "/custom-order/pending-orders",
        name: "PendingCustomOrders",
        component: () =>
          import("../views/pages/customOrders/PendingCustomOrders.vue"),
      },
      {
        path: "/custom-order/completed-orders",
        name: "CompleteCustomOrders",
        component: () =>
          import("../views/pages/customOrders/CompleteCustomOrders.vue"),
      },
      {
        path: "/custom-order/cancelled-orders",
        name: "CancelledCustomOrders",
        component: () =>
          import("../views/pages/customOrders/CancelledCustomOrders.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  linkActiveClass: "active",
  base: process.env.VUE_APP_BASE_URL,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.isAuthenticated) {
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
