<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>Sales Report
            <div class="pt-4 px-4">
              <v-autocomplete
                  v-model="selectedVendor"
                  :items="vendors"
                  item-text="details.business_name"
                  item-value="details.id"
                  outlined
                  dense
                  label="Filter According to Sellers"
              ></v-autocomplete>
            </div>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
            ></v-text-field>
            <v-btn color="primary" class="ml-4" rounded @click="exportToExcel">Export to Excel</v-btn>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="formattedSales"
              :search="search"
          >
          </v-data-table>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {json2excel} from 'js2excel';
import axios_api from "@/axios/axios_api";

export default {
  Components: {json2excel},
  data() {
    return {
      dialog: false,
      search: '',
      sales: [],
      headers: [
        {text: "S.N.", value: "sn"},
        {
          text: 'Product Name',
          align: 'start',
          sortable: false,
          value: 'product_name',
        },
        {text: 'Seller Name', value: 'seller_name'},
        {text: 'No. of Sales', value: 'sale'},
      ],
      formattedSales: [],
      vendors: [],
      selectedVendor: "",
      filteredSales: [],
    }
  },
  mounted() {
    axios_api.get("salesReport")
        .then(response => {
          this.sales = response.data.data;
        });
    axios_api.get("vendor")
        .then(response => {
          this.vendors = response.data;
        });
  },
  watch: {
    sales(val) {
      this.filteredSales = val;
    },
    selectedVendor(val) {
     
      if (val)
        this.filteredSales = this.sales.filter(s => (s.seller_name === val));
      else
        this.filteredSales = this.sales;
    },
    filteredSales(val) {
      this.formattedSales = val.map((v, index) => ({...v, sn: index + 1}));
    },
  },
  methods: {
    exportToExcel() {
      // this will be export a excel and the file's name is user-info-data.xlsx
      // the default file's name is excel.xlsx
      let data = this.formattedSales;
      try {
        json2excel({
          data,
          name: 'sales-report',
          // formateDate: 'yyyy/mm/dd'
        });
      } catch (e) {
        alert("Something Went Wrong. Try Again!");
      }
    }
  },

}
</script>

<style>

</style>