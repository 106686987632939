<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="teal"
        ></v-progress-linear>
        <v-data-table
          :headers="headers"
          :items="getTag"
          @pagination="paginate"
          :footer-props="{ 'items-per-page-options': [10, 15, 20] }"
          class="elevation-5 pt-2"
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>Product Tags</v-toolbar-title>

              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    small
                    class="ml-6"
                    v-bind="attrs"
                    v-on="on"
                    rounded
                  >
                    Create New Tag
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text class="pa-0">
                    <v-container>
                      <v-form ref="form" v-model="valid">
                        <v-row>
                          <v-col cols="12" class="pb-0">
                            <v-text-field
                              v-model="editedItem.name"
                              outlined
                              dense
                              :rules="[rules.required]"
                              label="Tag Name"
                            ></v-text-field>
                            <v-autocomplete
                              :items="subCategories"
                              v-model="editedItem.sub_category_id"
                              @change="getCategory()"
                              item-text="name"
                              :rules="[rules.required]"
                              item-value="id"
                              outlined
                              dense
                              label="Select Sub Category"
                            ></v-autocomplete>
                            <v-autocomplete
                              :items="subsubcategories"
                              v-model="editedItem.sub_sub_category_id"
                              item-text="name"
                              item-value="id"
                              outlined
                              dense
                              label="Select Sub Sub Category"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="px-6 my-2"
                      rounded
                      dark
                      @click="save"
                      :disabled="!valid"
                    >
                      Save &amp; Continue
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                @keyup.enter="searchs"
                append-icon="mdi-magnify"
                label="Search"
                single-line
              ></v-text-field>
              <v-dialog v-model="dialogDelete" max-width="400px">
                <v-card>
                  <v-card-text class="pt-4 text-center">
                    <v-icon color="error" size="50">mdi-close-circle</v-icon>
                    <h2 class="font-bold py-4">
                      Are you sure want to delete this Item?
                    </h2>
                    <h4 class="font-bold text-left">
                      Please be aware that you cannot revert this item in
                      future.
                    </h4>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      class="px-8"
                      rounded
                      dark
                      @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ formatDate(item.created_at) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  data: () => ({
    dialog: false,
    valid: false,
    rules: {
      required: (v) => !!v || "This field is required.",
    },
    tags: [],
    subsubcategories: [],
    loading: false,
    subCategories: [],
    subSubCategories: [],
    dialogDelete: false,
    search: "",
    headers: [
      { text: "S.N.", value: "sn" },
      {
        text: "Tag Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Created Date", value: "created_at" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    required: [(v) => !!v || "This Field is required"],
    editedIndex: -1,
    editedItem: {
      name: "",
      created_at: "",
    },
    defaultItem: {
      name: "",
      created_at: "",
    },
  }),

  computed: {
    ...mapGetters({
      getAllTags: "getTags",
      getAllSubCategories: "getSubCategories",
      getAllSubSubCategories: "getSubSubCategories",
    }),
    getTag() {
      return this.tags.map((item, index) => ({
        ...item,
        sn: index + 1,
      }));
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add New Tag" : "Edit Tag Details";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    search(val) {
      if (val === "" || this.search.indexOf(" ") >= 0) {
        this.tags = [...this.getAllTags];
      }
    },
  },

  methods: {
    ...mapActions([
      "getTags",
      "storeTag",
      "updateTag",
      "deleteTag",
      "getSubCategories",
      "getSubSubCategories",
    ]),
    paginate(e) {
      if (this.$store.state.pages.tags < e.page) {
        this.$store.commit("setTagPage", e.page);
        this.getTags(e.page).then(() => {
          this.tags = this.getAllTags;
        });
      }
    },
    searchs() {
      if (this.search === "" || this.search.indexOf(" ") >= 0) {
        this.tags = this.getAllTags;
      } else {
        this.$store.dispatch("getTagSearch", this.search).then((res) => {
          this.tags = res.data;
        });
      }
    },
    formatDate(value) {
      return moment(value).format("MMMM DD YYYY");
    },

    getCategory() {
      this.subsubcategories = this.subSubCategories.filter(
        (value) => value.cat_id === this.editedItem.sub_category_id
      );
    },

    editItem(item) {
      let temp = this.tags.filter((tag) => tag.id === item.id)[0];
      this.editedIndex = this.tags.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      let temp = this.tags.filter((tag) => tag.id === item.id)[0];
      this.editedIndex = this.tags.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true;
      this.deleteTag(this.editedItem.id)
        .then(() => {
          let newIndex = this.getAllTags.indexOf(
            this.tags[this.editedIndex]
          );
          this.tags.splice(this.editedIndex, 1);
          if (newIndex >= 0) {
            this.$store.commit("setTags", this.tags);
          }

          Swal.fire("Success!", "Tag Deleted Successfully!", "success");
          this.loading = false;
          this.editedIndex = -1;
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.loading = false;
          this.editedIndex = -1;
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        this.loading = false;
        if (this.editedIndex > -1) {
          let temp = Object.assign(
            {},
            this.tags[this.editedIndex],
            this.editedItem
          );
          this.updateTag(this.editedItem)
            .then(() => {
              Object.assign(this.tags[this.editedIndex], temp);
              let newIndex = this.getAllTags.indexOf(
                this.tags.filter((item) => item.id === temp.id)[0]
              );
              if (newIndex >= 0) {
                this.$store.commit("setTags", this.tags);
              }
              this.editedIndex = -1;
              this.loading = false;
              Swal.fire("Success!", "Tag Updated Successfully!", "success");
              this.$refs.form.reset();
            })
            .catch(() => {
              this.loading = false;
              this.editedIndex = -1;
              Swal.fire("Oops...", "Something went wrong!", "error");
            });
        } else {
          this.storeTag(this.editedItem)
            .then((response) => {
              Swal.fire("Success!", "Tag Added Successfully!", "success");
              this.$refs.form.reset();
              console.log(response.status);
            })
            .catch(() => {
              this.loading = false;
              this.editedIndex = -1;
              Swal.fire("Oops...", "Something went wrong!", "error");
            });
        }
        this.close();
      }
    },
  },
  mounted() {
    if (this.getAllTags.length === 0) {
      this.getTags().then((response) => {
        this.tags = response.data;
      });
    } else {
      this.tags = this.getAllTags;
    }
    if (this.getAllSubCategories.length === 0) {
      this.getSubCategories().then((response) => {
        this.subCategories = response;
      });
    } else {
      this.subCategories = this.getAllSubCategories;
    }
    if (this.getAllSubSubCategories.length === 0) {
      this.getSubSubCategories().then((response) => {
        this.subSubCategories = response;
        Object.assign(this.subsubcategories, this.subSubCategories);
      });
    } else {
      this.subSubCategories = this.getAllSubSubCategories;
      Object.assign(this.subsubcategories, this.subSubCategories);
    }
  },
};
</script>
<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0 !important;
}
</style>
