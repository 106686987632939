<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>Completed Orders Report
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
            ></v-text-field>
            <v-btn color="primary" class="ml-4" rounded @click="exportToExcel">Export to Excel</v-btn>
            
          </v-card-title>

          <v-data-table

              :headers="headers"
              :items="getPendingOrdersList"
              :search="search"
              item-key="id"
              class="elevation-0"
              single-expand
              show-expand
              :expanded.sync="expanded"
          >
            <template v-slot:item.created_at="{item}">
              {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col lg="4">
                    <v-card class="my-4 mx-4">
                      <v-card-text v-if="item.shipping">
                        <h3>Shipping Address</h3>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Name</p>
                          <p class="mb-1 font-bold ml-auto">{{ item.shipping.name }}</p>
                        </div>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Phone</p>
                          <p class="mb-1 font-bold ml-auto">{{ item.shipping.phone }}</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Province</p>
                          <p class="mb-1 font-bold ml-auto">{{ item.shipping.state }}</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">District</p>
                          <p class="mb-1 font-bold ml-auto">{{ item.shipping.district }}</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">City</p>
                          <p class="mb-1 font-bold ml-auto">{{ item.shipping.city }}</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Area</p>
                          <p class="mb-1 font-bold ml-auto">{{ item.shipping.area }}</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Street</p>
                          <p class="mb-1 font-bold ml-auto">{{ item.shipping.street_address }}</p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col lg="4">
                    <v-card class="my-4">
                      <v-card-text v-if="item.billing">
                        <h3>Billing Address</h3>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Name</p>
                          <p class="mb-1 font-bold ml-auto">{{ item.billing.name }}</p>
                        </div>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Phone</p>
                          <p class="mb-1 font-bold ml-auto">{{ item.billing.phone }}</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Country</p>
                          <p class="mb-1 font-bold ml-auto">{{ item.billing.country.name }}</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Province</p>
                          <p class="mb-1 font-bold ml-auto">{{ item.billing.state }}</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">District</p>
                          <p class="mb-1 font-bold ml-auto">{{ item.billing.district }}</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">City</p>
                          <p class="mb-1 font-bold ml-auto">{{ item.billing.city }}</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Area</p>
                          <p class="mb-1 font-bold ml-auto">{{ item.billing.area }}</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Street</p>
                          <p class="mb-1 font-bold ml-auto">{{ item.billing.street_address }}</p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col lg="4">
                    <v-card class="my-4">
                    </v-card>
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-left align-items-center" :id="item.id">
                <div class="my-2">
                  <v-btn color="error" small dark @click="dialog=true;orderItem=item.id">
                    <v-icon dark size="14" class="pr-2">mdi-eye</v-icon>
                    View Products
                  </v-btn>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="dialog" max-width="1000px">
          <v-card elevation="5">
            <v-card-title>Product Details
              <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
              ></v-text-field>
            </v-card-title>
      <v-data-table
              :headers="product_headers"
              :items="orderedProducts"
              :search="search"
            >
              <template v-slot:item.pname="{ item }">
                <a
                  v-if="item.product.category"
                  :href="
                    url(
                      'product/' +
                        item.product.category.slug +
                        '/' +
                        item.product.id
                    )
                  "
                  target="_blank"
                >
                  {{ item.product.name }}
                </a>
              </template>
              <template v-slot:item.quantity="{ item }">
                {{ item.qty }}
              </template>
              <template v-slot:item.total="{ item }">
                {{ item.buying_unit_price }}
              </template>
              <template v-slot:item.vname="{ item }">
                {{ item.product.vendor && item.product.vendor.name }}
              </template>
              <template v-slot:item.vphone="{ item }">
                {{ item.product.vendor && item.product.vendor.phone }}
              </template>

              <template v-slot:item.variations="{ item }">
                <p class="mb-0">
                  Color:
                  {{
                    item.variation.colors ? item.variation.colour.name : "----"
                  }}
                </p>
                <p class="mb-0">
                  Size:
                  {{ item.variation.size ? item.variation.size.name : "----" }}
                </p>
                <p class="mb-0">
                  Type: {{ item.variation.type ? item.variation.type : "----" }}
                </p>
                <p class="mb-0">
                  Weight:
                  {{ item.variation.weight ? item.variation.weight : "----" }}
                </p>
              </template>
            </v-data-table>

          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapState,mapGetters} from "vuex";

import {dateMixin} from "../../../mixin/helperMixin";
import {json2excel} from "js2excel";

export default {
  mixins: [dateMixin],
  data() {
    return {
      dialog: false,
      search: '',
      orderItem: [],
      expanded: [],
      status: ['Pending', 'Active', 'Packaging', 'Shipping', 'Delivered'],

      product_headers: [
        {text: "S.N.", value: "sn1"},
        {text: 'Product Name', value: 'pname'},
        {text: 'Variation', value: 'variations'},
        {text: 'Total Quantity', value: 'quantity'},
        {text: 'Total Price', value: 'total'},
        {text: 'Seller Name', value: 'vname'},
        {text: 'Seller Phone', value: 'vphone'},
        {text: 'Seller Address', value: 'seller_address'},
        {text: 'Status', value: 'status'},


      ],
      products: [{
        sn1: '1',
        product_name: 'Refrigerator 180L',
        quantity: '1',
        price: 'Rs. 30000',
        seller_name: 'CG',
        seller_phone: '014521522',
        seller_address: 'Shahid Marg, Satdobato, Lalitpur'
      }],
      headers: [
        {text: "S.N.", value: "sn"},
        {text: 'Order Code', value: 'order_code'},
        {
          text: 'Customer Name',
          align: 'start',
          sortable: false,
          value: 'customer_name',
        },
        {text: 'Phone', value: 'customer_phone'},
        {text: 'Coupons', value: 'coupons'},
        {text: 'Delivery Price', value: 'delivery_price'},
        {text: 'Total Price', value: 'total_price'},
        {text: 'Payment Method', value: 'payment_type'},
        {text: 'Ordered Date', value: 'ordered_date'},
        {text: 'Status', value: 'status'},
        {text: 'Actions', value: 'actions'},
      ],
    }
  },
  methods: {
    ...mapActions([
      'getAllOrders'
    ]),
     url(path) {
      if (process.env.APP_URL) {
        return process.env.APP_URL + path;
      } else {
        return "https://sajhadeal.com/" + path;
      }
    },

    exportToExcel() {
      // this will be export a excel and the file's name is user-info-data.xlsx
      // the default file's name is excel.xlsx
      let data = this.verifiedVendors;
      try {
        json2excel({
          data,
          name: 'verified-seller-report',
          // formateDate: 'yyyy/mm/dd'
        });
      } catch (e) {
        alert("Something Went Wrong. Try Again!");
      }
    }
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders,
    }),
     ...mapGetters({
  completedOrder: 'getCompletedOrders'
}),
    getPendingOrdersList() {

      return this.completedOrder.map((item, index) => {
          return {
            ...item,
            sn: index + 1,
            order_code: item.code,
            customer_name: item.user.name,
            customer_phone: item.user.phone,
            coupons: item.coupon?item.coupon.code : 'NA',
            delivery_price: item.delivery_option.price,
           total_price: 'Rs.'+item.total,
            payment_type: item.payment.method==='cash'? 'COD' : item.payment.method,
            ordered_date: item.created_at,
          }
      });
    },
    orderedProducts() {
      const orders = this.completedOrder.filter(
        (item) => item.id === this.orderItem
      )[0];
      if (orders) {
        return orders.order_detail.map((item, index) => ({
          ...item,
          sn: index + 1,
        }));
      } else {
        return [];
      }
    },

    getAddress(item) {
      return item.street_address ? item.street_address + ',' : '' + item.area + ',' + item.city
    },

    getAllItems(item) {
      return item.order.map((item, index) => ({
        sn: index + 1,
      }));
    },

    filterVariation(val) {
      const color = val.colour ? "Color: " + val.colour.name : "";
      const size = val.sizes ? "Size: " + val.sizes.code : "";
      const type = val.type && val.type !== 'null' ? "Type: " + val.type : "";
      const weight = val.weight && val.weight !== 'null' ? "Weight: " + val.weight : "";
      return color + " " + size + " " + type + " " + weight;
    },
  },

  mounted() {
    if (this.orders.length === 0) {
      this.getAllOrders();
    }
  },
};
</script>