<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1">
        <v-card>
          <v-card-title>Data Policy</v-card-title>
          <v-card-text>
            <vue-editor
              @selection-change="editItem(dataPolicy)"
              v-model="dataPolicy.content"
            />
            <v-btn
              color="primary"
              rounded
              class="my-4 px-6"
              :loading="loading"
              @click="update()"
              >Save &amp; Submit</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapGetters, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  components: { VueEditor },
  data() {
    return {
      temp: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      dataPolicy: "getDataPolicy",
    }),
  },
  methods: {
    ...mapActions(["updateLegalCredentials"]),
    editItem(item) {
      this.temp = Object.assign({}, item);
    },
    update() {
      this.loading = true;
      this.updateLegalCredentials(this.dataPolicy)
        .then(() => {
          this.loading = false;
          this.$store.commit("updateLCData", this.dataPolicy);
          Swal.fire("Success!", "Data Policy Updated Successfully!", "success");
        })
        .catch(() => {
          this.loading = false;
          Object.assign(this.dataPolicy, this.temp);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },
  },
};
</script>

<style></style>
