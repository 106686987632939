<template>
  <v-container>
      <v-row>
          <v-col cols="12">
              <v-card elevation="5">
                  <v-card-title>Closed Tickets
                    <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                ></v-text-field>
            </v-card-title>
                <v-data-table
                    :headers="headers"
                    :items="getSupport"
                    :search="search"
                >
                <template v-slot:item.subject="{ item }">
                        <v-dialog :id="'descriptionDialog' + item.id"  width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                              <v-icon size="14" class="pr-2">mdi-eye</v-icon> Subject
                            </v-btn>
                          </template>

                          <v-card>
                            <v-card-title
                              >Subject Details</v-card-title
                            >
                            <v-card-text class="py-2">
                              <p>{{item.subject}}</p>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                    </template>
                  <template v-slot:item.description="{item}">
                        <v-dialog :id="'descriptionDialog' + item.id"  width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                              <v-icon size="14" class="pr-2">mdi-eye</v-icon> Description
                            </v-btn>
                          </template>

                          <v-card>
                            <v-card-title
                              >Support Description</v-card-title
                            >
                            <v-card-text class="py-2">
                              <p>{{item.description}}</p>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                    </template>
                    <template v-slot:item.support_image="{ item }">
                        <v-dialog :id="'imageDialog' + item.id"  width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="primary" dark v-bind="attrs" v-on="on">
                              <v-icon size="14" class="pr-2">mdi-eye</v-icon> Image
                            </v-btn>
                          </template>

                          <v-card>
                            <v-card-title
                              >Support Image</v-card-title
                            >
                            <v-card-text class="py-2">
                              <img :src="item.image" alt="">
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                    </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    {{ formatDate(item.created_at) }}
                  </template>
              </v-data-table>
            </v-card>
            
          </v-col>
      </v-row>
  </v-container>
</template>

<script>

import {mapActions} from "vuex";
import {dateMixin} from "../../../mixin/helperMixin";

export default {
  mixins: [dateMixin],

  data() {
        return {
            dialog: false,
            search: '',
            closed_tickets: [
            ],
            headers: [
                {text: "S.N.", value: "sn"},
                { text: "Ticket Id", value: "ticket_id", align: 'start' },
                {text: "Customer Name", value: "customer_name"},
                {
                text: 'Category',
                value: 'category',
                },
                {
                text: 'Subject',
                value: 'subject',
                },
                {
                text: 'Description',
                value: 'description',
                },
                { text: 'Image', value: 'support_image' },
                { text: 'Support Type', value: 'support_type' },

                { text: 'Closed Date', value: 'created_at' },
            ],
        }
    },
  computed:{
    getSupport() {
      return this.closed_tickets.map((item, index) => ({
        ...item,
        sn: index + 1,
        ticket_id:item.code,
        category: item.category,
        subject: item.subject,
        support_type: item.type,
        description: item.description,
        is_closed: item.is_closed,
        is_resolved: item.is_resolved,
        customer_name:item.user&&item.user.name



        // banner_image:item.banner,
      }));
    },
  },
    methods: {
      ...mapActions([
        "getSupports",
      ]),

    },
  mounted() {
    if (!this.$store.state.supports.closedCase) {
      this.getSupports().then((response) => {
        console.log(response.support);
        this.closed_tickets = response.closedCase;


      });
    } else {
      this.closed_tickets = this.$store.state.supports.closedCase;

    }

  }

}
</script>

<style>

</style>