
import { Doughnut } from "vue-chartjs";


export default {
    extends: Doughnut,
    props: [ 'categoryCount','subCategoryCount','subSubCategoryCount'],
    data() {
        return {
          chartOptions: {
            hoverBorderWidth: 20
          },
          chartData: {
            hoverBackgroundColor: "red",
            hoverBorderWidth: 10,
            labels: ["Categories", "Sub Categories", "Sub Subcategories"],
            datasets: [
              {
                label: "Product Categories",
                backgroundColor: ["#41B883", "#E46651", "#00D8FF"],
                data: [this.categoryCount, this.subCategoryCount, this.subSubCategoryCount]
              }
            ]
          }
        }
      },
    mounted() {
        this.renderChart(this.chartData, {
        borderWidth: "10px",
        hoverBackgroundColor: "red",
        hoverBorderWidth: "10px"
        })
    },

}
