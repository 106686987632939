<template>
  <v-container>
      <v-row>
          <v-col cols="12">
              <v-card elevation="5">
                  <v-card-title>Delivery Report
                    <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                ></v-text-field>
                <v-btn color="primary" class="ml-4" rounded @click="exportToExcel">Export to Excel</v-btn>
            </v-card-title>
                <v-data-table
                    :headers="headers"
                    :items="delivery"
                    :search="search"
                >
              </v-data-table>
            </v-card>
            
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import {json2excel} from 'js2excel';

export default {
    Components: { json2excel },
    data() {
        return {
            dialog: false,
            search: '',
            delivery: [{sn: '1', order_id: '105252', customer_name: 'Madhav Belbase', product_name: 'CG TV 32Inch', quantity: '1', price: 'Rs. 30000', seller_name: 'CG', rider_name: 'Ram Sharma', status: 'Delivered', delivered_date: '15/05/2021' }],
            headers: [
                { text: "S.N.", value: "sn" },
                { text: 'Order Id', value: 'order_id' },
                {
                text: 'Customer Name',
                align: 'start',
                sortable: false,
                value: 'customer_name',
                },
                { text: 'Product Name', value: 'product_name' },
                { text: 'Quantity', value: 'quantity' },
                { text: 'Price', value: 'price' },
                { text: 'Seller Name', value: 'seller_name' },
                { text: 'Rider Name', value: 'rider_name' },
                { text: 'Status', value: 'status' },
                { text: 'Delivery Date', value: 'delivered_date' },
            ],
        }
    },
    methods: {
        exportToExcel() {
            // this will be export a excel and the file's name is user-info-data.xlsx
            // the default file's name is excel.xlsx
            let data = this.delivery;
            try {
                json2excel({
                    data,
                    name: 'delivery-report',
                    // formateDate: 'yyyy/mm/dd'
                });
            } catch (e) {
                alert("Something Went Wrong. Try Again!");
            }
        }
    },

}
</script>

<style>

</style>