<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>
            Contact Messages
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="getAllContactMessageList" :search="search">
            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                  small
                  @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "ContactMessage",
  data() {
    return {
      dialog: false,
      search: "",
      headers: [
        {text: "S.N.", value: "sn"},
        {text: "Name", value: "name"},
        {text: "Email", value: "email"},
        {text: "Phone", value: "phone"},
        {text: "Message", value: "description"},
        {text: "Delete", value: "actions"},
      ],
      deleteItemId: "",
    }
  },
  computed: {
    ...mapState(["contactMessages"]),
    getAllContactMessageList() {
      return this.contactMessages.map((item, index) => ({...item, sn: index + 1}));
    },
  },
  methods: {
    ...mapActions(["getAllContactMessages", "deleteContactMessage"]),
    deleteItem(item) {
      this.deleteItemId = item.id;
      this.dialog = true;
    },
    closeDelete() {
      this.deleteItemId = "";
      this.dialog = false;
    },
    deleteItemConfirm() {
      this.deleteContactMessage(this.deleteItemId);
      this.dialog = false;
    },
  },
  mounted() {
    if (this.contactMessages.length === 0) this.getAllContactMessages();
  }
}
</script>

<style scoped>

</style>