var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',[_vm._v("Order Delivery Details "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredDetails,"search":_vm.search},scopedSlots:_vm._u([{key:"item.product_name",fn:function(ref){
var item = ref.item;
return _vm._l((item.order_detail),function(name,index){return _c('h5',{key:name.product.id + name.product.name},[_vm._v(" "+_vm._s(name.product.name)+" "),(index !== item.order_detail.length - 1)?_c('v-divider'):_vm._e()],1)})}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return _vm._l((item.order_detail),function(name,index){return _c('h5',{key:name.product.id + name.product.pname},[_vm._v(" "+_vm._s(name.qty)+" "),(index !== item.order_detail.length - 1)?_c('v-divider'):_vm._e()],1)})}},{key:"item.customer_address",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getAddress(item.shipping))+" ")])]}},{key:"item.delivered",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left align-items-center"},[_c('div',{staticClass:"pb-4"},[_c('v-switch',{attrs:{"color":"success","hide-details":""},model:{value:(item.id),callback:function ($$v) {_vm.$set(item, "id", $$v)},expression:"item.id"}})],1)])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left align-items-center",attrs:{"id":item.id}},[_c('div',{staticClass:"my-2"},[(item.delivery===null)?_c('v-btn',{staticClass:"my-2",attrs:{"color":"primary","small":"","dark":""},on:{"click":function($event){return _vm.openDialog(item)}}},[_vm._v(" Assign Rider ")]):_c('v-btn',{staticClass:"my-2",attrs:{"color":"success","small":"","dark":""}},[_vm._v(" "+_vm._s(item.delivery.rider.name)+" ")])],1)])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Assign Driver ")]),_c('v-card-text',[_c('v-form',[_c('v-autocomplete',{attrs:{"items":_vm.riders,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"Select Rider"},model:{value:(_vm.editedItem.rider),callback:function ($$v) {_vm.$set(_vm.editedItem, "rider", $$v)},expression:"editedItem.rider"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.assignRider()}}},[_vm._v("Submit")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }