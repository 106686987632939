<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>Withdraw Request
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="newWithdrawRequests"
              :search="search"
          >
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-left align-items-center">
                <div class="my-2">
                  <v-btn color="primary my-1" fab x-small dark @click="acceptPayment(item.id)">
                    <v-icon dark>mdi-check-circle</v-icon>
                  </v-btn>

                  <v-dialog
                      v-model="dialog"
                      persistent
                      max-width="600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          color="error"
                          fab
                          x-small
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="dialog=true"
                      >
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Reject Withdraw Request</span>
                      </v-card-title>
                      <v-card-text>
                        <v-textarea v-model="rejectReason" label="Reject Reason"></v-textarea>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                        >
                          Close
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="rejectPayment(item.id)"
                            :disabled="rejectReason.length === 0"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import axios_api from "@/axios/axios_api";

export default {
  data() {
    return {
      dialog: false,
      search: '',
      headers: [
        {text: "S.N.", value: "sn"},
        {
          text: 'Vendor Name',
          align: 'start',
          sortable: false,
          value: 'vendorName',
        },
        {text: 'Request Amount', value: 'request_amount'},
        {text: 'Bank Name', value: 'bankName'},
        {text: 'Acc No.', value: 'bankAccountNumber'},
        {text: 'Branch', value: 'bankBranchName'},
        {text: 'Requested Date', value: 'requestedDate'},
        {text: 'Actions', value: 'actions'},
      ],
      rejectReason: "",
      newWithdrawRequests: [],
    }
  },

  methods: {
    acceptPayment(id) {
      let editedItem = this.withdrawRequests.filter(w => w.id === id)[0];
      let editedIndex = this.withdrawRequests.indexOf(editedItem);
      editedItem.status = 2;
      editedItem.reason = null;
      Object.assign(this.withdrawRequests[editedIndex], editedItem);

      this.newWithdrawRequests = this.getNewWithdrawRequests();
      let formData = new FormData();
      formData.append("status", "2");
      formData.append("_method", "PATCH");
      axios_api.post(`withdraw-request/${id}`, formData);
      this.resetData();
    },
    rejectPayment(id) {
      let editedItem = this.withdrawRequests.filter(w => w.id === id)[0];
      let editedIndex = this.withdrawRequests.indexOf(editedItem);
      editedItem.status = 0;
      editedItem.reason = this.rejectReason;
      Object.assign(this.withdrawRequests[editedIndex], editedItem);
      this.newWithdrawRequests = this.getNewWithdrawRequests();

      let formData = new FormData();
      formData.append("status", "0");
      formData.append("_method", "PATCH");
      formData.append("reason", this.rejectReason);
      axios_api.post(`withdraw-request/${id}`, formData);
      this.resetData();
    },
    resetData() {
      this.dialog = false;
      this.rejectReason = "";
    },
    ...mapGetters(["getNewWithdrawRequests"]),
  },
  computed: {
    ...mapState(["withdrawRequests"]),
  },
  mounted() {
    if (this.withdrawRequests.length === 0) {
      this.$store.dispatch("getWithdrawRequests")
          .then(() => {
            this.newWithdrawRequests = this.getNewWithdrawRequests();
          });
    } else {
      this.newWithdrawRequests = this.getNewWithdrawRequests();
    }
  }
}
</script>

<style>

</style>