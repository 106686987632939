<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="8" offset-lg="2">
        <v-card elevation="5">
          <v-card-title>Social Media Links</v-card-title>
          <v-card-text>
            <v-text-field
                v-model="socialMedia.fb"
                @click="editItem(socialMedia)"
                outlined
                dense
                label="Facebook Link"
                :rules="[rules.facebookLinkRules]"
            ></v-text-field>
            <v-text-field
                @click="editItem(socialMedia)"
                v-model="socialMedia.linkedin"
                outlined
                dense
                label="LinkedIn Link"
                :rules="[rules.linkidinLinkRules]"
            ></v-text-field>
            <v-text-field
                @click="editItem(socialMedia)"
                v-model="socialMedia.insta"
                outlined
                dense
                label="Instagram Link"
                :rules="[rules.instagramLinkRules]"
            ></v-text-field>
            <v-text-field
                @click="editItem(socialMedia)"
                v-model="socialMedia.twitter"
                outlined
                dense
                label="Twitter Link"
                :rules="[rules.twiterLinkRules]"
            ></v-text-field>
            <v-text-field
                @click="editItem(socialMedia)"
                v-model="socialMedia.youtube"
                outlined
                dense
                label="Youtube Link"
                :rules="[rules.youtubeLinkRules]"
            ></v-text-field>
            <v-btn rounded class="px-6" color="primary" @click="update()">Save &amp; Submit</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      rules: {
        facebookLinkRules: (value) => {
          //eslint-disable-next-line
          const pattern = /null|^\s*$|(^http(s)?:\/\/(www\.)?(facebook|fb)\.com\/[A-z0-9_\-\.]+\/?)/;
          return pattern.test(value) || "Invalid link. Enter Valid Facebook Link Only";
        },
        twiterLinkRules: (value) => {
          const pattern = /null|^\s*$|(^http(s)?:\/\/(.*\.)?twitter\.com\/[A-z0-9_]+\/?)/;
          return pattern.test(value) || "Invalid link. Enter Valid Twitter Link Only";
        },
        linkidinLinkRules: (value) => {
          const pattern = /null|^\s*$|(^http(s)?:\/\/([\w]+\.)?linkedin\.com\/?(in|company)\/[A-z0-9_-]+\/?)/;
          return pattern.test(value) || "Invalid link. Enter Valid LinkedIn Link Only";
        },
        instagramLinkRules: (value) => {
          const pattern = /null|^\s*$|(^https?:\/\/(www\.)?instagram\.com\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?))/;
          return pattern.test(value) || "Invalid link. Enter Valid Instagram Link Only";
        },
        youtubeLinkRules: (value) => {
          const pattern = /null|^\s*$|(^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/*)?)/;
          return pattern.test(value) || "Invalid link. Enter Valid Youtube Link Only";
        },
      },
      socialMedia: {},
      temp: {}
    }
  },
  computed: {
    ...mapGetters({
      getAllSocialMedia: "getSocialMedia",
    }),

  },
  methods: {
    ...mapActions(["getSocialMedia","updateSocialMedia"]),
    editItem(item){
      this.temp=Object.assign({}, item);
    },
    update() {
      this.updateSocialMedia(this.socialMedia).then(() => {
        this.$store.commit("setSocialMedia", this.socialMedia);
        Swal.fire("Success!", "Social Media Successfully!", "success");


      }).catch(() => {
        Swal.fire("Oops...", "Something went wrong!", "error");
        Object.assign(this.socialMedia,this.temp);

      });
    }


  },
  mounted() {
    if (this.getAllSocialMedia.fb === null) {

      this.getSocialMedia().then((response) => {
        this.socialMedia = response;


      });
    } else {
      this.socialMedia = this.getAllSocialMedia;
      console.log(this.socialMedia)


    }
  }
}
</script>

<style>
</style>