<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title
            >{{ type }} Orders
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
            ></v-text-field>
          </v-card-title>

          <v-data-table
            v-if="orders[0]"
            :headers="headers"
            :items="filteredOrders"
            :search="search"
            item-key="id"
            class="elevation-0"
            single-expand
            show-expand
            :expanded.sync="expanded"
          >
            <template v-slot:item.created_at="{ item }">
              {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col lg="4">
                    <v-card class="my-4 mx-4">
                      <v-card-text>
                        <h3>Shipping Address</h3>
                        <v-divider class="my-2"></v-divider>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Name</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.shipping && item.shipping.name }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Phone</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.shipping && item.shipping.phone }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Country</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{
                              item.shipping.country &&
                              item.shipping.country.name
                            }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Province</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.shipping && item.shipping.state }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">District</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.shipping.district }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">City</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.shipping.city }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Area</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.shipping.area }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Street</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.shipping.street_address }}
                          </p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col lg="4">
                    <v-card class="my-4">
                      <v-card-text>
                        <h3>Billing Address</h3>
                        <v-divider class="my-2"></v-divider>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Name</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.billing && item.billing.name }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Phone</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.billing.phone }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Country</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{
                              item.billing.country && item.billing.country.name
                            }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Province</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.billing.state }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">District</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.billing.district }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">City</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.billing.city }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Area</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.billing.area }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Street</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.billing.street_address }}
                          </p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-left align-items-center" :id="item.id">
                <div class="my-2">
                  <v-btn
                    color="error"
                    small
                    dark
                    @click="
                      dialog = true;
                      orderItem = item.id;
                    "
                  >
                    <v-icon dark size="14" class="pr-2">mdi-eye</v-icon>
                    View Products
                  </v-btn>
                </div>
              </div>
            </template>
            <template
              v-if="type === 'Active' || 'Pending'"
              v-slot:item.status="{ item }"
            >
              <v-select
                v-if="type === 'Active' && item.status === 'New'"
                v-model="item.status"
                :items="status"
                dense
                @change="updateOrder(item)"
              ></v-select>
              <v-select
                v-if="type === 'Pending'"
                v-model="item.status"
                :items="status"
                dense
                @change="updateOrder(item)"
              ></v-select>
              <p v-if="type === 'Active' && item.status !== 'New'">
                {{ item.status }}
              </p>
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="dialog" max-width="1000px">
          <v-card elevation="5">
            <v-card-title
              >Product Details
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="product_headers"
              :items="orderedProducts"
              :search="search"
            >
              <template v-slot:item.pname="{ item }">
                <a
                  v-if="item.product.category"
                  :href="
                    url(
                      'product/' +
                        item.product.category.slug +
                        '/' +
                        item.product.id
                    )
                  "
                  target="_blank"
                >
                  {{ item.product.name }}
                </a>
              </template>
              <template v-slot:item.quantity="{ item }">
                {{ item.qty }}
              </template>
              <template v-slot:item.total="{ item }">
                {{ item.buying_unit_price }}
              </template>
              <template v-slot:item.vname="{ item }">
                {{ item.product.vendor && item.product.vendor.business_name }}
              </template>
              <template v-slot:item.vphone="{ item }">
                {{ item.product.vendor && item.product.vendor.office_phone }}
              </template>

              <template v-slot:item.variations="{ item }">
                <p class="mb-0">
                  Color:
                  {{
                    item.variation.colors ? item.variation.colour.name : "----"
                  }}
                </p>
                <p class="mb-0">
                  Size:
                  {{ item.variation.size ? item.variation.sizes.code : "----" }}
                </p>
                <p class="mb-0">
                  Type: {{ item.variation.type ? item.variation.type : "----" }}
                </p>
                <p class="mb-0">
                  Weight:
                  {{ item.variation.weight ? item.variation.weight : "----" }}
                </p>
              </template>
            </v-data-table>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { dateMixin } from "../../mixin/helperMixin";
import { mapActions } from "vuex";

export default {
  mixins: [dateMixin],
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialog: false,
      search: "",
      orderItem: [],
      expanded: [],
      status: [
        "New",
        "Ready",
        "Picked Up",
        "Drop Off",
        "Delivered",
        "Cancelled",
      ],

      product_headers: [
        { text: "S.N.", value: "sn" },
        { text: "Product Name", value: "pname" },
        { text: "Variation", value: "variations" },
        { text: " Quantity", value: "quantity" },
        { text: " Price", value: "total" },
        { text: "Seller Name", value: "vname" },
        { text: "Seller Phone", value: "vphone" },
        { text: "Status", value: "status" },
      ],
    };
  },
  computed: {
    filteredOrders() {
      return this.orders.map((item, index) => ({
        ...item,
        sn: index + 1,
        order_code: item.code,
        customer_name: item.shipping && item.shipping.name,
        customer_phone: item.shipping && item.shipping.phone,
        coupons: item.coupon ? item.coupon.code : "NA",
        delivery_price: item.delivery_option.price,
        total_price: "Rs." + item.total,
        payment_type:
          item.payment.method === "cash" ? "COD" : item.payment.method,
        ordered_date: item.order && item.order.created_at,
      }));
    },
    orderedProducts() {
      const orders = this.orders.filter(
        (item) => item.id === this.orderItem
      )[0];
      if (orders) {
        return orders.order_detail.map((item, index) => ({
          ...item,
          sn: index + 1,
        }));
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions(["updateOrderStatus"]),
    url(path) {
      console.log(process.env.APP_URL);
      if (process.env.APP_URL) {
        return process.env.APP_URL + path;
      } else {
        return "https://sajhadeal.com/" + path;
      }
    },
    updateOrder(item) {
      let temp = this.orders.filter((value) => value.id === item.id)[0];
      this.editedIndex = this.orders.indexOf(temp);
      let data = { id: item.id, status: item.status };
      this.updateOrderStatus(data)
        .then((response) => {
          this.$store.commit("setStatus", response.data.data);

          // Object.assign(temp.response.data);
        })
        .catch((error) => {
          console.log(error.status);
          // alert(error);
        });
      this.dialog = false;
      this.product = {};
    },
  },
};
</script>

<style></style>
