<template>
  <v-row >
    <v-col v-show="!finish" cols="6">
      <v-btn color="primary" class="standard-border" block @click="updateStep">
        {{ finish ? "Save and Review" : "Save and Continue" }}
      </v-btn>
    </v-col>
    <v-col v-if="finish" cols="6" class="mx-auto">
      <v-btn
        :loading="loading"
        :disabled="!ready"
        color="secondary"
        class="standard-border"
        block
        @click="submitForm"
      >
        Finish and upload
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    finish: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    ready: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateStep() {
      this.$emit("updateStep", 1);
    },
    submitForm() {
      if (this.$route.params.id) {
        this.$emit("update");
      } else {
        this.$emit("submit");
      }
    },
  },
};
</script>
