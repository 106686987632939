<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1">
        <v-card v-if="this.deliveryCharges.normal" elevation="5" class="mb-3">
          <v-card-title>Normal Delivery Charge</v-card-title>
          <v-card-text>
            <v-row>
              <v-col lg="6" cols="12">
                <v-text-field
                  v-model="deliveryCharges.normal[0].price"
                  @change="editItem(deliveryCharges.normal[0])"
                  outlined
                  dense
                  label="Outside Valley Charge"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <v-text-field
                  v-model="deliveryCharges.normal[0].estimated_duration"
                  outlined
                  @change="editItem(deliveryCharges.normal[0])"
                  dense
                  label="Outside Valley Time"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <v-text-field
                  v-model="deliveryCharges.normal[2].price"
                  @change="editItem(deliveryCharges.normal[2])"
                  outlined
                  dense
                  label="International Charge"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <v-text-field
                  v-model="deliveryCharges.normal[2].estimated_duration"
                  @change="editItem(deliveryCharges.normal[2])"
                  outlined
                  dense
                  label="International Time"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <v-text-field
                  v-model="deliveryCharges.normal[1].price"
                  @change="editItem(deliveryCharges.normal[1])"
                  outlined
                  dense
                  label="Outside Ringroad Charge"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <v-text-field
                  v-model="deliveryCharges.normal[1].estimated_duration"
                  @change="editItem(deliveryCharges.normal[1])"
                  outlined
                  dense
                  label="Outside Ringroad Time"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <v-text-field
                  v-model="deliveryCharges.normal[3].price"
                  @change="editItem(deliveryCharges.normal[3])"
                  outlined
                  dense
                  label="Inside Ringroad Charge"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <v-text-field
                  v-model="deliveryCharges.normal[3].estimated_duration"
                  @change="editItem(deliveryCharges.normal[3])"
                  outlined
                  dense
                  label="Inside Ringroad Time"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn @click="updateDeliveryCharge('normal')" color="primary"
              >Submit</v-btn
            >
          </v-card-text>
        </v-card>
        <!-- <v-card v-if="this.deliveryCharges.express" elevation="5">
          <v-card-title>Express Delivery Charge</v-card-title>
          <v-card-text>
            <v-row>
              <v-col lg="6" cols="12">
                <v-text-field
                    v-model="deliveryCharges.express[0].price"
                    @change="editItem(deliveryCharges.express[0])"
                    outlined
                    dense
                    label="Inside RingRoad Charge"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <v-text-field
                    v-model="deliveryCharges.express[0].estimated_duration"
                    @change="editItem(deliveryCharges.express[0])"
                    outlined
                    dense
                    label="Inside RingRoad Time"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <v-text-field
                    v-model="deliveryCharges.express[1].price"
                    @change="editItem(deliveryCharges.express[1])"
                    outlined
                    dense
                    label="Outside RingRoad Charge"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <v-text-field
                    v-model="deliveryCharges.express[1].estimated_duration"
                    @change="editItem(deliveryCharges.express[1])"
                    outlined
                    dense
                    label="Outside RingRoad Time"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <v-text-field
                    v-model="deliveryCharges.express[2].price"
                    @change="editItem(deliveryCharges.express[2])"
                    outlined
                    dense
                    label="Outside Valley Charge"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <v-text-field
                    v-model="deliveryCharges.express[2].estimated_duration"
                    @change="editItem(deliveryCharges.express[2])"
                    outlined
                    dense
                    label="Outside Valley Time"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <v-text-field
                    v-model="deliveryCharges.express[3].price"
                    @change="editItem(deliveryCharges.express[3])"
                    outlined
                    dense
                    label="International Charge"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <v-text-field
                    v-model="deliveryCharges.express[3].estimated_duration"
                    @change="editItem(deliveryCharges.express[3])"
                    outlined
                    dense
                    label="International Time"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn @click="updateDeliveryCharge('express')" color="primary">Submit</v-btn>
          </v-card-text>
        </v-card> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      inside_ringroad_charge: null,
      inside_ringroad_time: "1 day",
      outside_ringroad_charge: "Rs 100",
      outside_ringroad_time: "1-2 days",
      outside_valley_charge: "Rs. 500",
      outside_valley_time: "3-5 days",
      international_charge: "Rs 1000",
      international_time: "10-15 days",
      deliveryCharges: [],
      editedItem: [],
    };
  },
  computed: {
    ...mapGetters({
      getAllDeliveryCharges: "getDeliveryCharges",
    }),
  },
  methods: {
    ...mapActions(["getDeliveryCharges", "updateDeliveryCharges"]),

    editItem(item) {
      this.editedItem.push(item);
    },

    updateDeliveryCharge(type) {
      if (this.editedItem.length !== 0) {
        if (type === "normal") {
          this.editedIndex = this.deliveryCharges.normal.indexOf(
            this.editedItem
          );
        } else {
          this.editedIndex = this.deliveryCharges.express.indexOf(
            this.editedItem
          );
        }

        this.updateDeliveryCharges(this.editedItem)
          .then(() => {
            this.$store.commit("setDeliveryCharge", this.deliveryCharges);
            this.editedItem = [];
            Swal.fire("Success!", "Charges Updated Successfully!", "success");
          })
          .catch(() => {
            Swal.fire("Oops...", "Something went wrong!", "error");
          });
      }
    },
  },
  mounted() {
    if (this.getAllDeliveryCharges.express === null) {
      this.getDeliveryCharges().then(() => {
        this.deliveryCharges = this.getAllDeliveryCharges;
      });
    } else {
      this.deliveryCharges = this.getAllDeliveryCharges;
    }
  },
};
</script>

<style></style>
