<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title
            >Verified Sellers
            <!-- <v-btn
              color="primary"
              small
              class="ml-6"
              :to="{ name: 'addSeller' }"
              >Add a Seller</v-btn
            > -->
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="verifiedVendors"
            :item-key="phone"
            class="elevation-0"
            :search="search"
            single-expand
            show-expand
            :expanded.sync="expanded"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col lg="4">
                    <v-card class="my-4 mx-4">
                      <v-card-text
                        v-if="item.details && item.details.business_address"
                      >
                        <h3>Business Address</h3>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Name</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.business_address.name }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Phone</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.business_address.phone }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Province</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.business_address.state }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">District</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.business_address.district }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">City</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.business_address.city }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Area</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.business_address.area }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Street</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.business_address.street_address }}
                          </p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col lg="4">
                    <v-card class="my-4">
                      <v-card-text
                        v-if="item.details && item.details.warehouse_address"
                      >
                        <h3>Warehouse Address</h3>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Name</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.warehouse_address.name }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Phone</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.warehouse_address.phone }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Province</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.warehouse_address.state }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">District</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.warehouse_address.district }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">City</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.warehouse_address.city }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Area</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.warehouse_address.area }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Street</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.warehouse_address.street_address }}
                          </p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col lg="4">
                    <v-card class="my-4">
                      <v-card-text
                        v-if="item.details && item.details.return_address"
                      >
                        <h3>Return Address</h3>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Name</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.return_address.name }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Phone</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.return_address.phone }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Province</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.return_address.state }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">District</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.return_address.district }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">City</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.return_address.city }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Area</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.return_address.area }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Street</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.return_address.street_address }}
                          </p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </td>
            </template>

            <template v-slot:item.logo="{ item }">
              <v-dialog :id="'dialog' + item.id" width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-if="item.details"
                    size="60"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <img
                      :src="item.details.logo"
                      alt="banner-image"
                      class="img-thumbnail my-2"
                      width="100"
                      height="60"
                    />
                  </v-avatar>
                </template>

                <v-card>
                  <v-card-title style="background-color: #163691; color: white"
                    >Shop Logo
                  </v-card-title>
                  <img :src="item.logo" width="100%" />
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.documents="{ item }">
              <v-dialog :id="'dialog' + item.id" width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-if="item.details"
                    size="60"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <img
                      :src="item.details && item.details.document"
                      alt="banner-image"
                      class="img-thumbnail my-2"
                      width="100"
                      height="60"
                    />
                  </v-avatar>
                </template>

                <v-card>
                  <v-card-title style="background-color: #163691; color: white"
                    >Shop Registration Document
                  </v-card-title>
                  <img
                    :src="item.details && item.details.document"
                    width="100%"
                  />
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.status="{ item }">
              <div class="d-flex justify-left align-items-center">
                <div class="pb-4">
                  <v-switch
                    @change="updateUserStatus(item)"
                    v-model="item.status"
                    color="success"
                    hide-details
                  ></v-switch>
                </div>
              </div>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                color="blue"
                class="my-1"
                small
                dark
                :to="{ path: `/seller/edit-seller/${item.id}` }"
              >
                Edit
              </v-btn>
              <v-btn color="error my-1" dark small @click="deleteItem(item)">
                Delete
              </v-btn>
              <div class="d-flex justify-left align-items-center">
                <v-btn
                  color="error my-1"
                  small
                  dark
                  @click="updateSeller(item, 'reject')"
                >
                  Reject
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-text class="pt-4 text-center">
                <v-icon color="error" size="50">mdi-close-circle</v-icon>
                <h2 class="font-bold py-4">
                  Are you sure want to delete this Item?
                </h2>
                <h4 class="font-bold text-left">
                  Please be aware that you cannot revert this item in future.
                </h4>
              </v-card-text>
              <v-card-actions>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  class="px-8"
                  rounded
                  dark
                  @click="deleteItemConfirm"
                  >OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      search: "",
      dialogDelete: false,
      selected: [],
      expanded: [],
      editedItem: {},
      editedIndex: -1,
      phone: null,
      headers: [
        { text: "S.N.", value: "sn" },
        {
          text: "Seller Name",
          align: "start",
          sortable: false,
          value: "seller_name",
        },
        { text: "Shop Logo", value: "logo" },
        { text: "Shop Name", value: "shop_name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Pan/Vat No", value: "pan_no" },
        { text: "Document", value: "documents" },
        { text: "Status", value: "status" },
        { text: "Request Date", value: "created_at" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    ...mapState({
      sellers: (state) => state.sellers,
    }),
    verifiedVendors() {
      // console.log(this.sellers.filter(item => item.details == null))
      const verified = this.sellers.filter(
        (item) => item.details.is_verified == 1
      );

      return verified.map((item, index) => ({
        ...item,
        sn: index + 1,
        seller_name: item.name,
        logo: item.details && item.details.logo,
        shop_name: item.details && item.details.business_name,
        email: item.email,
        phone: item.phone,
        pan_no: item.details && item.details.pan,
        documents: item.details && item.details.document,
        status: !!item.emailverified,
        created_at: moment(item.created_at).format("MM DD YYYY"),
      }));
    },
  },
  methods: {
    ...mapActions([
      "getAllSellers",
      "updateSellers",
      "updateCustomerStatus",
      "deleteSeller",
    ]),
    updateSeller(item, type) {
      let temp = this.sellers.filter((value) => value.id === item.id)[0];
      let temp1 = temp;
      let data = {};
      data.id = temp.id;
      this.editedIndex = this.sellers.indexOf(temp);
      if (type === "reject") {
        data.is_verified = 0;
        data.is_rejected = 1;
      }

      this.updateSellers(data)
        .then((response) => {
          this.$store.commit("updateSellers", response.data.data[0]);
          // this.sellers.splice(this.editedIndex, 1);
        })
        .catch(() => {
          Object.assign(this.sellers[this.editedIndex], temp1);
        });
    },
    updateUserStatus(item) {
      let temp = this.sellers.filter((customer) => customer.id === item.id)[0];
      this.editedIndex = this.sellers.indexOf(temp);
      this.updateCustomerStatus([item.id])
        .then((response) => {
          let value;
          if (response.data.data === null) {
            value = null;
          } else {
            value = response.data.data;
          }
          this.sellers[this.editedIndex].emailverified = value;
          this.$store.commit("setSellersList", this.sellers);
          this.editedIndex = -1;
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.editedIndex = -1;
        });
    },

    deleteItem(item) {
      this.dialogDelete = true;
      let temp = this.sellers.filter((seller) => seller.id === item.id)[0];
      this.editedIndex = this.sellers.indexOf(temp);
      this.editedItem = Object.assign({}, item);
    },

    deleteItemConfirm() {
      this.loading = true;
      this.deleteSeller(this.editedItem.id)
        .then(() => {
          this.sellers.splice(this.editedIndex, 1);
          this.$store.commit("setSellersList", this.sellers);
          this.loading = false;
          this.editedIndex = -1;
          Swal.fire("Success!", "Seller Deleted Successfully!", "success");
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.editedIndex = -1;
          this.loading = false;
        });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
  },
  mounted() {
    if (!this.sellers.length) {
      this.getAllSellers();
    }
  },
};
</script>

<style></style>
