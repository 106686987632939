<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="teal"
        ></v-progress-linear>

        <v-data-table
          :headers="headers"
          :items="getBrand"
          @pagination="paginate"
          :footer-props="{ 'items-per-page-options': [10, 15, 20] }"
          class="elevation-5 pt-2"
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>Brand Details</v-toolbar-title>

              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    small
                    class="ml-6"
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    >Create New Brand
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text class="pa-0">
                    <v-container>
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <v-form ref="form" v-model="valid">
                            <v-text-field
                              v-model="editedItem.name"
                              outlined
                              dense
                              label="Brand Name"
                              :rules="[rules.required]"
                            ></v-text-field>

                            <v-file-input
                              @change="imageSelect($event)"
                              v-model="retrieveFile"
                              label="Brand Image"
                              accept="image/*"
                              outlined
                              prepend-icon=""
                              dense
                              :rules="[rules.required]"
                            ></v-file-input>
                            <v-textarea
                              v-model="editedItem.description"
                              outlined
                              dense
                              label="Brand Description"
                            >
                            </v-textarea>
                          </v-form>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="px-6 my-2"
                      rounded
                      dark
                      @click="save"
                      :disabled="!valid"
                    >
                      Save &amp; Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                @keyup.enter="searchs"
                placeholder="Press Enter to search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
              ></v-text-field>
              <v-dialog v-model="dialogDelete" max-width="400px">
                <v-card>
                  <v-card-text class="pt-4 text-center">
                    <v-icon color="error" size="50">mdi-close-circle</v-icon>
                    <h2 class="font-bold py-4">
                      Are you sure want to delete this Item?
                    </h2>
                    <h4 class="font-bold text-left">
                      Please be aware that you cannot revert this item in
                      future.
                    </h4>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      class="px-8"
                      rounded
                      dark
                      @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.brand_image`]="{ item }">
            <v-dialog :id="'dialog' + item.id" width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar size="60" v-bind="attrs" v-on="on">
                  <img
                    :src="getImage(item.logo)"
                    alt="banner-image"
                    class="img-thumbnail my-2"
                    width="100"
                    height="60"
                  />
                </v-avatar>
              </template>

              <v-card>
                <v-card-title style="background-color: #163691; color: white"
                  >Brand Image
                </v-card-title>
                <img :src="getImage(item.logo)" width="100%" />
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ formatDate(item.created_at) }}
          </template>
          <template v-slot:[`item.is_featured`]="{ item }">
            <v-switch
              @change="updateIsFeatured(item)"
              v-model="item.is_featured"
            ></v-switch>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { dateMixin, imageMixin } from "../../../../mixin/helperMixin";
import Swal from "sweetalert2";

export default {
  mixins: [dateMixin, imageMixin],
  data: () => ({
    loading: false,
    valid: false,
    dialog: false,
    dialogDelete: false,
    rules: {
      required: (v) => !!v || "This field is required.",
    },
    search: "",
    brands: [],
    sbrand: [],
    headers: [
      { text: "S.N.", value: "sn" },
      {
        text: "Brand Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Brand Image", value: "brand_image" },
      { text: "Featured", value: "is_featured" },

      // { text: "Created Date", value: "created_at" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      logo: "",
      created_at: "",
    },
    defaultItem: {
      name: "",
      created_at: "",
    },
    required: [(v) => !!v || "This Field is required"],
  }),

  computed: {
    ...mapGetters({
      getAllBrands: "getBrands",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Add New Brand" : "Edit Brand Details";
    },
    getBrand() {
      return this.brands.map((item, index) => ({
        ...item,
        sn: index + 1,
        name: item.name,
        description: item.description,
      }));
    },

    retrieveFile() {
      if (this.editedItem.logo) {
        if (this.editedItem.logo instanceof Blob) {
          return this.editedItem.logo;
        }
        let ext = this.editedItem.logo.split(".").pop();
        const logos = new File(
          [this.getImage(this.editedItem.logo)],
          this.editedItem.name + "." + ext,
          {
            type: "image/*",
          }
        );
        return logos;
      } else {
        return "";
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    search(val) {
      if (val === "" || this.search.indexOf(" ") >= 0) {
        this.brands = [...this.sbrand];
      }
    },
  },

  methods: {
    ...mapActions([
      "getBrands",
      "storeBrand",
      "updateBrand",
      "deleteBrand",
      "updateFeaturedBrand",
    ]),

    imageSelect(event) {
      this.editedItem.logo = event;
    },
    updateIsFeatured(item) {
      let data = { id: item.id, featured: item.is_featured ? 1 : 0 };
      let temp = this.brands.filter((brand) => brand.id === item.id)[0];
      this.editedIndex = this.brands.indexOf(temp);
      let temp1 = Object.assign({}, this.brands[this.editedIndex], item);
      this.updateFeaturedBrand(data)
        .then(() => {
          Object.assign(this.brands[this.editedIndex], temp1);
          this.$store.commit("setBrands", this.brands);
          this.editedIndex = -1;
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.editedIndex = -1;
        });
    },

    getImage(path) {
      if (path instanceof Blob) return URL.createObjectURL(path);
      else return path;
    },

    editItem(item) {
      let temp = this.brands.filter((brand) => brand.id === item.id)[0];
      this.editedIndex = this.brands.indexOf(temp);
      this.editedItem = {
        id: item.id,
        name: item.name,
        description: item.description,
        logo: item.logo,
      };
      this.dialog = true;
    },
    searchs() {
      this.loading = true;
      if (this.search === "" || this.search.indexOf(" ") >= 0) {
        this.brands = [...this.sbrand];
        this.loading = false;
      } else {
        this.$store.dispatch("getBrandSearch", this.search).then((res) => {
          this.brands = res.data;
          this.loading = false;
        });
      }
    },

    deleteItem(item) {
      let temp = this.brands.filter((brand) => brand.id === item.id)[0];
      this.editedIndex = this.brands.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true;
      this.deleteBrand(this.editedItem.id)
        .then(() => {
          this.loading = false;
          let stateIndex = this.getAllBrands.indexOf(
            this.brands[this.editedIndex]
          );
          if (stateIndex >= 0) {
            this.brands.splice(this.editedIndex, 1);
            this.sbrand.splice(this.stateIndex, 1);
            this.$store.commit("setBrands", this.sbrand);
          } else {
            this.brands.splice(this.editedIndex, 1);
          }
          Swal.fire("Success!", "Brand Deleted Successfully!", "success");
          this.editedIndex = -1;
        })
        .catch(() => {
          this.loading = false;
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.editedIndex = -1;
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);

        // this.editedIndex = -1
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        let fd;
        fd = new FormData();
        if (typeof this.editedItem.logo === "object")
          fd.append("logo", this.editedItem.logo);

        fd.append("name", this.editedItem.name);

        fd.append("description", this.editedItem.description);
        this.loading = true;
        if (this.editedIndex > -1) {
          let temp = Object.assign(
            {},
            this.brands[this.editedIndex],
            this.editedItem
          );
          this.updateBrand({ id: this.editedItem.id, fd: fd })
            .then(() => {
              let stateIndex = this.getAllBrands.indexOf(
                this.brands[this.editedIndex]
              );
              Object.assign(this.brands[this.editedIndex], temp);
              if (stateIndex && stateIndex >= 0) {
                let newData = this.sbrand.filter(
                  (item) => item.id === temp.id
                )[0];
                let newIndex = this.sbrand.indexOf(newData);
                Object.assign(this.sbrand[newIndex], temp);
                this.$store.commit("setBrands", this.sbrand);
              }
              this.editedIndex = -1;
              this.loading = false;
              this.$refs.form.reset();
              Swal.fire("Success!", "Brand Updated Successfully!", "success");
            })
            .catch((error) => {
              this.loading = false;
              this.editedIndex = -1;
              Swal.fire("Oops...", "Something went wrong!", "error");
              console.log(error.message);
            });
        } else {
          this.storeBrand(fd)
            .then(() => {
              this.loading = false;
              Swal.fire("Success!", "Brand Added Successfully!", "success");
              this.$refs.form.reset();
              this.editedIndex = -1;
            })
            .catch(() => {
              this.loading = false;
              this.editedIndex = -1;
              Swal.fire("Oops...", "Something went wrong!", "error");
            });
        }

        this.close();
      }
    },
    paginate(e) {
      if (this.$store.state.pages.brands < e.page) {
        this.$store.commit("setBrandPage", e.page);
        this.getBrands(e.page).then(() => {
          this.brands = this.getAllBrands;
        });
      }
    },
  },
  mounted() {
    if (this.getAllBrands.length === 0) {
      this.getBrands().then((response) => {
        this.brands = response.data;
        this.sbrand = [...response.data];

        // console.log(response.status);
      });
    } else {
      this.brands = this.getAllBrands;
      this.sbrand = [...this.getAllBrands];
    }
  },
};
</script>
<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0 !important;
}
</style>
