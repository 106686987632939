<template>
  <div class="bordered-box">
    <h3 class="ml-3">Addresses</h3>
    <v-divider class="my-4"></v-divider>
    <div v-if="hide" class="text-center ">
      <v-icon size="64" color="green">mdi-check-circle</v-icon>
      <br />
      <p>
        <i
          >All data has been completed. Please proceed to
          <strong>FINISH AND UPLOAD</strong> to save.</i
        >
      </p>
    </div>
    <v-stepper v-else v-model="addressStep">
      <v-stepper-header>
        <div v-for="(item, index) in address_details" :key="index">
          <v-stepper-step :complete="addressStep > 1" :step="index + 1">
            {{ format(item) }}
          </v-stepper-step>
          <v-divider v-show="index < address_details.length"></v-divider>
        </div>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          v-for="(item, index) in address_details"
          :key="index"
          :step="index == 0 ? 1 : index == 1 ? 2 : 3"
        >
          <address-form
            :type="format(item)"
            :country="
              index == 0
                ? item.business_address.country
                : index == 1
                ? item.warehouse_address.country
                : item.return_address.country
            "
            :district="
              index == 0
                ? item.business_address.district
                : index == 1
                ? item.warehouse_address.district
                : item.return_address.district
            "
            :state="
              index == 0
                ? item.business_address.state
                : index == 1
                ? item.warehouse_address.state
                : item.return_address.state
            "
            :city="
              index == 0
                ? item.business_address.city
                : index == 1
                ? item.warehouse_address.city
                : item.return_address.city
            "
            :area="
              index == 0
                ? item.business_address.area
                : index == 1
                ? item.warehouse_address.area
                : item.return_address.area
            "
            :street_address="
              index == 0
                ? item.business_address.street_address
                : index == 1
                ? item.warehouse_address.street_address
                : item.return_address.street_address
            "
            :propname="name"
            :propphone="phone"
            :activateBusinessCopy="index > 0"
            :activateWarehouseCopy="index > 1"
            @storeaddress="storeaddress(index)"
          ></address-form>
          <div class="mt-4"></div>
        </v-stepper-content>

        <!-- <v-stepper-content step="2">
          <v-card class="mb-12" color="grey lighten-1" height="200px"></v-card>

          <v-btn color="primary" @click="addressStep = 3">
            Continue
          </v-btn>

          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-12" color="grey lighten-1" height="200px"></v-card>

          <v-btn color="primary" @click="addressStep = 1">
            Continue
          </v-btn>

          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content> -->
      </v-stepper-items>
    </v-stepper>
    <!-- <v-expansion-panels
      v-model="panel"
      inset
      focusable
      class="mb-4 standard-border"
    >
      <v-expansion-panel
        v-for="(item, index) in address_details"
        :key="index"
        class="standard-border mb-4"
      >
        <v-expansion-panel-header class="standard-border">
          {{ format(item) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <address-form
            v-if="index === 0"
            :type="format(item)"
            :country="address_details[index].business_address.country"
            :district="address_details[index].business_address.district"
            :state="address_details[index].business_address.state"
            :city="address_details[index].business_address.city"
            :area="address_details[index].business_address.area"
            :street_address="
              address_details[index].business_address.street_address
            "
            :propname="name"
            :propphone="phone"
            :activateBusinessCopy="index > 0"
            :activateWarehouseCopy="index > 1"
            @storeaddress="storeaddress"
          ></address-form>
          <address-form
            v-if="index === 1"
            :type="format(item)"
            :country="address_details[index].warehouse_address.country"
            :district="address_details[index].warehouse_address.district"
            :state="address_details[index].warehouse_address.state"
            :city="address_details[index].warehouse_address.city"
            :area="address_details[index].warehouse_address.area"
            :street_address="
              address_details[index].warehouse_address.street_address
            "
            :propname="name"
            :propphone="phone"
            :activateBusinessCopy="index > 0"
            :activateWarehouseCopy="index > 1"
            @storeaddress="storeaddress"
          ></address-form>
          <address-form
            v-if="index === 2"
            :type="format(item)"
            :country="address_details[index].return_address.country"
            :district="address_details[index].return_address.district"
            :state="address_details[index].return_address.state"
            :city="address_details[index].return_address.city"
            :area="address_details[index].return_address.area"
            :street_address="
              address_details[index].return_address.street_address
            "
            :propname="name"
            :propphone="phone"
            :activateBusinessCopy="index > 0"
            :activateWarehouseCopy="index > 1"
            @storeaddress="storeaddress"
          ></address-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->
    <div class="mt-5"></div>
    <stepper-buttons
      @updateStep="updateStep"
      :finish="true"
      :loading="loading"
      :ready="readytoSubmit"
      @submit="submit"
      @update="update"
    ></stepper-buttons>
  </div>
</template>
<script>
import StepperButtons from "./common/StepperButtons.vue";
import formatLabel from "./common/formatLabel";
import AddressForm from "./common/AddressForm.vue";

export default {
  mixins: [formatLabel],
  props: {
    name: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
    address_details: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    StepperButtons,

    AddressForm,
  },
  data() {
    return {
      panel: 0,
      addressStep: 1,
      readytoSubmit: false,
    };
  },
  methods: {
    updateStep(val) {
      this.$emit("updateStep", val);
    },
    submit() {
      this.$emit("submit");
    },
    update() {
      this.$emit("update");
    },
    format(val) {
      return this.formatLabel(val);
    },
    storeaddress(data) {
      if (data == 0) {
        this.addressStep += 1;
      } else if (data == 2) {
        this.hide = true;
        this.readytoSubmit = true;
      } else {
        this.addressStep += data;
      }
    },
  },
};
</script>
