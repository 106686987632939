<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>Verified Payments
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="verifiedWithdrawRequests"
              :search="search"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapGetters, mapState} from "vuex";

export default {
  data() {
    return {
      dialog: false,
      search: '',
      headers: [
        {text: "S.N.", value: "sn"},
        {
          text: 'Vendor Name',
          align: 'start',
          sortable: false,
          value: 'vendorName',
        },
        {text: 'Request Amount', value: 'request_amount'},
        {text: 'Bank Name', value: 'bankName'},
        {text: 'Acc No.', value: 'bankAccountNumber'},
        {text: 'Branch', value: 'bankBranchName'},
        {text: 'Requested Date', value: 'requestedDate'},
      ],
      verifiedWithdrawRequests: [],
    }
  },
  computed: {
    ...mapState(["withdrawRequests"]),
  },
  methods: {
    ...mapGetters(["getVerifiedWithdrawRequests"]),
  },
  mounted() {
    if (this.withdrawRequests.length === 0) {
      this.$store.dispatch("getWithdrawRequests")
          .then(() => {
            this.verifiedWithdrawRequests = this.getVerifiedWithdrawRequests();
          });
    } else {
      this.verifiedWithdrawRequests = this.getVerifiedWithdrawRequests();
    }
  },

}
</script>

<style>

</style>