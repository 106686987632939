<template>
  <div>
    <v-navigation-drawer
      left
      stateless
      v-model="drawer"
      :mini-variant="mini"
      app
      :style="mini ? 'width: 70px' : ''"
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img src="/sajha.jpg" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Saajha</v-list-item-title>
            <v-list-item-subtitle>Admin Dashboard</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense link>
        <v-list-item to="/dashboard" exact>
          <v-list-item-icon>
            <v-icon class="secondary-color">mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="secondary-color"
              >Dashboard
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group prepend-icon="mdi-archive">
          <template v-slot:activator>
            <v-list-item-title class="secondary-color"
              >Products
            </v-list-item-title>
          </template>
          <v-list-item to="/products/all-products" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-archive }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >All Products
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/products/brands" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-domain }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >All Brands
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/products/tags" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-tag }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Product Tags
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/products/categories" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-shape }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Categories
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/products/subcategories" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-shape-outline }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Sub Category
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/products/sub-subcategories" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-content-duplicate }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Sub SubCategory
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/products/reviews" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-message-draw }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Reviews
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/products/sizes" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-shape-outline }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Sizes
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group prepend-icon="mdi-flash">
          <template v-slot:activator>
            <v-list-item-title class="secondary-color"
              >Flash Deals
            </v-list-item-title>
          </template>
          <v-list-item to="/flashdeals/all-flashdeals" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-flash }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Flash Deal
            </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item to="/flash-deals/sellers-list" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-store }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Seller List
            </v-list-item-title>
          </v-list-item> -->
        </v-list-group>
        <v-list-group prepend-icon="mdi-store">
          <template v-slot:activator>
            <v-list-item-title class="secondary-color"
              >Sellers
            </v-list-item-title>
          </template>
          <v-list-item to="/seller/add-seller" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-store-plus }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Add New Seller
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/seller/pending-seller" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-store-minus }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Pending Seller
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/seller/verified-seller" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-storefront }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Verified Seller
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/seller/rejected-seller" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-store-remove }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Rejected Seller
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <!-- <v-list-group prepend-icon="mdi-briefcase">
          <template v-slot:activator>
            <v-list-item-title class="secondary-color"
              >Payments
            </v-list-item-title>
          </template>
          <v-list-item to="/payments/withdraw-request" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-briefcase-download }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Withdraw Request
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/payments/pending-payments" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-briefcase-minus }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Pending Payments
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/payments/verified-payments" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-briefcase-check }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Verified Payments
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/payments/rejected-payments" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-briefcase-remove }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Rejected Request
            </v-list-item-title>
          </v-list-item>
        </v-list-group> -->
        <v-list-group prepend-icon="mdi-account-group">
          <template v-slot:activator>
            <v-list-item-title class="secondary-color"
              >Customers
            </v-list-item-title>
          </template>
          <v-list-item to="/all-customers" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-account-multiple-check }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >All Users
              
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/all-buyers" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-account-star }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >All Buyers
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/contact-message" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-account-question }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Contact Message
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group prepend-icon="mdi-cart">
          <template v-slot:activator>
            <v-list-item-title class="secondary-color"
              >Orders
            </v-list-item-title>
          </template>
          <v-list-item to="/order/active-orders" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-cart-arrow-right }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Active Orders
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/order/pending-orders" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-cart-minus }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Pending Orders
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/order/completed-orders" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-cart-check }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Completed Orders
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/order/cancelled-orders" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-cart-remove }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Cancelled Orders
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
                <v-list-group prepend-icon="mdi-cart-arrow-down">
          <template v-slot:activator>
            <v-list-item-title class="secondary-color"
              >Custom Orders
            </v-list-item-title>
          </template>
          <v-list-item to="/custom-order/new-orders" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-cart-arrow-right }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >New Orders
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/custom-order/pending-orders" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-cart-minus }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Pending Orders
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/custom-order/completed-orders" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-cart-check }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Completed Orders
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/custom-order/cancelled-orders" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-cart-remove }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Cancelled Orders
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group prepend-icon="mdi-truck-delivery">
          <template v-slot:activator>
            <v-list-item-title class="secondary-color"
              >Delivery Options
            </v-list-item-title>
          </template>
          <v-list-item to="/pick-up-details" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-map-marker-left }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Pick Up Details
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/drop-off-details" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-map-marker-right }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Drop Off Details
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/delivery-price" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-credit-card-marker }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Delivery Charges
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <!-- <v-list-group prepend-icon="mdi-face-agent">
          <template v-slot:activator>
            <v-list-item-title class="secondary-color"
              >Support Tickets
            </v-list-item-title>
          </template>
          <v-list-item to="/support/new-tickets" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-card-plus }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >New Tickets
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/support/pending-tickets" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-card-minus }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Pending Tickets
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/support/resolved-tickets" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-card-bulleted }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Resolved Tickets
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/support/closed-tickets" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-card-remove }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Closed Tickets
            </v-list-item-title>
          </v-list-item>
        </v-list-group> -->
        <v-list-group prepend-icon="mdi-file-chart">
          <template v-slot:activator>
            <v-list-item-title class="secondary-color"
              >Reports
            </v-list-item-title>
          </template>
          <v-list-item to="/report/sales-report" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-file-move }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Sales Report
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/report/stocks-report" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-file-export }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Stocks Report
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/report/sellers-report" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-file-account }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Seller Report
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/report/orders-report" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-file-table }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Orders Report
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/report/categories-report" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-file-swap }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Category Report
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/report/wishlists-report" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-file-restore }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Wishlists Report
            </v-list-item-title>
          </v-list-item>
          <!--          <v-list-item to="/report/delivery-report" class="pl-6">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>{ mdi-file-clock }</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-title class="secondary-color"-->
          <!--              >Delivery Report</v-list-item-title-->
          <!--            >-->
          <!--          </v-list-item>-->
        </v-list-group>
        <v-list-group prepend-icon="mdi-account-multiple">
          <template v-slot:activator>
            <v-list-item-title class="secondary-color"
              >Staffs
            </v-list-item-title>
          </template>
          <v-list-item to="/all-staffs" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-account-edit }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >All Staffs
            </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item to="/staff-roles" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-account-key }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Staff Roles
            </v-list-item-title>
          </v-list-item> -->
        </v-list-group>
        <v-list-group prepend-icon="mdi-account-hard-hat">
          <template v-slot:activator>
            <v-list-item-title class="secondary-color"
              >Riders
            </v-list-item-title>
          </template>
          <v-list-item to="/all-riders" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-account-hard-hat }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >All Riders
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group prepend-icon="mdi-application-cog">
          <template v-slot:activator>
            <v-list-item-title class="secondary-color"
              >Front End Setting
            </v-list-item-title>
          </template>
          <v-list-item to="/homepage-banner" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-image-size-select-actual }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Banner Images
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/social-media-links" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-web }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Social Media Links
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/about-us" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-office-building-outline }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >About Us
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/faq" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-account-question }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color">FAQ </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item to="/user-support" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-desktop-classic }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >User Support
            </v-list-item-title>
          </v-list-item> -->
          <v-list-item to="/career" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-briefcase }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Career
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/job-applications" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-briefcase }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Job Applications
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/deal" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-handshake }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Deal Name
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group prepend-icon="mdi-clipboard">
          <template v-slot:activator>
            <v-list-item-title class="secondary-color"
              >Policy Pages
            </v-list-item-title>
          </template>
          <v-list-item to="/privacy-policy" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-clipboard-alert }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Privacy Policy
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/cookie-policy" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-cookie }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Cookie Policy
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/warranty-policy" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-notebook-check }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Warranty Policy
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/refund-policy" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-cash-refund }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Refund Policy
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/data-policy" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-database }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Data Policy
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/buyer-protection" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-human-male }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Buyer Protection
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/seller-policy" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-clipboard-account }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Seller Policy
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/return-policy" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-clipboard-arrow-left }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Return Policy
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/support-policy" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-clipboard-edit }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Support Policy
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/terms" class="pl-6">
            <v-list-item-icon>
              <v-icon>{ mdi-clipboard-list }</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="secondary-color"
              >Terms &amp; Condition
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item class="pl-5" to="/seo">
          <v-list-item-icon>
            <v-icon class="secondary-color">mdi-table-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="secondary-color"
              >SEO Setting
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/notifications">
          <v-list-item-icon>
            <v-icon class="secondary-color">mdi-bell</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="secondary-color"
              >Notification
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/coupons">
          <v-list-item-icon>
            <v-icon class="secondary-color">mdi-gift</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="secondary-color"
              >Coupons
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="#811b1b" dense dark>
      <v-app-bar-nav-icon
        @click.stop="menuOpen = !menuOpen"
        class="d-none d-md-block"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <!-- <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn> -->
      <v-menu offset-y close-on-click>
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            :content="notificationCount"
            :value="notificationCount"
            color="red"
            overlap
            v-if="show"
            class="mr-3"
          >
            <v-icon size="24" v-bind="attrs" v-on="on" @click="markAsRead">
              mdi-bell
            </v-icon>
          </v-badge>
          <v-badge
            :content="notificationCount"
            :value="0"
            color="red"
            overlap
            v-else
            class="mr-3"
          >
            <v-icon size="24" v-bind="attrs" v-on="on">
              mdi-bell
            </v-icon>
          </v-badge>
        </template>

        <v-list class="p-0" dense>
          <v-list-item v-for="item in notifications" :key="item.orderCode">
            <v-list-item-content>
              <v-list-item-title
                ><strong>{{ item.user }}</strong> has placed a new order with
                code
                <strong
                  ><router-link :to="item.orderCode.charAt(0)=='O'? 'order/active-orders' :'custom-order/new-orders'">
                    {{ item.orderCode }}
                  </router-link></strong
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-center see-all-notification">
                <router-link to="/notifications">See All</router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <div v-on="on" class="pl-3" style="cursor: pointer !important">
            <v-avatar size="28px">
              <img src="/sajha.jpg" alt="John" />
            </v-avatar>
            <span class="px-2">
              Admin
              <v-icon>mdi-chevron-down</v-icon>
            </span>
          </div>
        </template>

        <v-list class="p-0">
          <v-list-item link to="/account">
            <v-list-item-title>
              <v-icon class="pr-4" small>mdi-key</v-icon>
              Account
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-title @click="logout">
              <v-icon class="pr-4" small>mdi-logout</v-icon>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- -->
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer color="white" app>
      <p class="mb-0 text-center" style="width:100%">
        <v-icon size="24" class="pr-2">mdi-copyright </v-icon>
        {{ todayDate }} Saajha | All Rights Reserved
      </p>
    </v-footer>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    mini() {
      return this.mdAndDown || this.menuOpen;
    },
    todayDate() {
      return new Date().getFullYear();
    },
    ...mapState({
      //  notificationCount: (state) => state.notifications.length,
      notifications: (state) => state.notifications,
    }),

    notificationCount() {
      return this.notifications.length;
    },
  },
  data() {
    return {
      drawer: true,
      show: true,
      messages: this.notificationCount,
      menuOpen: false,
    };
  },

  methods: {
    logout() {
      localStorage.removeItem("a_token");
      this.$store.commit("cart/clearData");
      this.$store.commit("order/clearData");
      this.$store.commit("users/clearData");
      this.$store.commit("clearData");
      this.$router.push("/");
    },
    ...mapActions([
      "getCategories",
      "getAllOrders",
      "getSupports",
      "getAllSellers",
      "getLegalCredentials",
      "getUnreadNotification",
      "getAllCustomers",
      'getAllBuyers',
      "getCustomOrder"
    ]),

    markAsRead() {
      this.show = false;
      this.$store.dispatch("markAsRead");
    },
  },
  mounted() {
    this.getLegalCredentials();
    this.$store.dispatch("getDealName");
    this.$store.dispatch("getUnreadNotification");
    if (!this.$store.state.categories.length) {
      this.getCategories();
      this.getSupports();
      this.$store.dispatch("getCategoryReport");
    }
    if (!this.$store.state.orders.length) {
      this.getAllOrders();
      this.getAllSellers();
      this.getAllCustomers();
      this.getAllBuyers();
      this.getCustomOrder();
    }
  },
};
</script>
<style>
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>
<style lang="scss">
.secondary-color {
  color: $secondary-color !important;
}

.v-list-item {
  border-left: 6px solid transparent;
}

.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 20px !important;
}

.v-list-item--active {
  border-left: 6px solid $primary-color;

  .v-list-item__title,
  .v-icon {
    color: $primary-color !important;
  }
}

.see-all-notification a {
  text-decoration: none;
}
</style>
