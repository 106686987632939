<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>Pending Payments
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="pendingWithdrawRequests"
              :search="search"
          >
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-left align-items-center" :id="item.id">
                <div class="my-2">
                  <v-btn color="primary my-1" small dark @click="editItem(item.id)">
                    Upload Bill
                  </v-btn>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              Upload Bill
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-text-field
                    outlined
                    dense
                    label="Transfered Total Amount"
                    min="0"
                    type="number"
                    :rules="[v => !!v || 'Required']"
                ></v-text-field>
                <v-file-input
                    v-model="editedItem.receipt"
                    label="Upload Bill"
                    outlined
                    prepend-icon=""
                    dense
                    :rules="[ v => !!v || 'File is required', v => (v && v.size > 0) || 'File is required']"
                ></v-file-input>
                <v-btn color="primary" @click="verifyWithdrawRequest" :disabled="!valid">Submit</v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import axios_api from "@/axios/axios_api";
import {mapGetters, mapState} from "vuex";

export default {
  data() {
    return {
      valid: true,
      dialog: false,
      search: '',
      headers: [
        {text: "S.N.", value: "sn"},
        {
          text: 'Vendor Name',
          align: 'start',
          sortable: false,
          value: 'vendorName',
        },
        {text: 'Request Amount', value: 'request_amount'},
        {text: 'Bank Name', value: 'bankName'},
        {text: 'Acc No.', value: 'bankAccountNumber'},
        {text: 'Branch', value: 'bankBranchName'},
        {text: 'Requested Date', value: 'requestedDate'},
        {text: 'Actions', value: 'actions'},
      ],
      editedItem: {
        "id": "",
        "receipt": [],
        "totalAmount": "",
      },
      defaultItem: {
        "id": "",
        "receipt": [],
        "totalAmount": "",
      },
      pendingWithdrawRequests: [],
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    ...mapState(["withdrawRequests"]),
  },
  methods: {
    editItem(id) {
      this.dialog = true;
      this.editedItem.id = id;
    },
    verifyWithdrawRequest() {
      this.$refs.form.validate();

      let editedItem = this.withdrawRequests.filter(w => w.id === this.editedItem.id)[0];
      let editedIndex = this.withdrawRequests.indexOf(editedItem);
      editedItem.status = 3;
      editedItem.reason = null;
      Object.assign(this.withdrawRequests[editedIndex], editedItem);
      this.pendingWithdrawRequests = this.getPendingWithdrawRequests();

      this.dialog = false;
      let formData = new FormData();
      if (this.editedItem.receipt) {
        formData.append("receipt", this.editedItem.receipt);
        formData.append("status", "3");
        formData.append("_method", "PATCH");
        axios_api.post(`withdraw-request/${this.editedItem.id}`, formData);
      }
      this.close();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    ...mapGetters(["getPendingWithdrawRequests"]),
  },
  mounted() {
    if (this.withdrawRequests.length === 0) {
      this.$store.dispatch("getWithdrawRequests")
          .then(() => {
            this.pendingWithdrawRequests = this.getPendingWithdrawRequests();
          });
    } else {
      this.pendingWithdrawRequests = this.getPendingWithdrawRequests();
    }
  },

}
</script>

<style>

</style>