<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1">
        <v-card>
          <v-card-title>About Us</v-card-title>
          <v-card-text>
            <vue-editor @selection-change="editItem(aboutUs)" v-model="aboutUs.content"/>
            <v-btn color="primary" rounded class="my-4 px-6" @click="update()">Save &amp; Submit</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {VueEditor} from "vue2-editor";
import {mapGetters, mapActions} from "vuex";
import Swal from "sweetalert2";


export default {
  components: {VueEditor},
  data() {
    return {
      aboutUs:{},
      legalCredentials: [],
      temp:{},
    }
  },
  computed: {
    ...mapGetters({
      getContent: "getAboutUs",
    }),
  },
  methods: {
    ...mapActions(["getAboutUs","updateAboutUs"]),
    editItem(item){
      this.temp=Object.assign({}, item);
    },
    update() {
      this.updateAboutUs(this.aboutUs).then(() => {
        this.$store.commit("setAboutUs", this.aboutUs);
        Swal.fire('Success!', 'About US Updated Successfully!', 'success')

      }).catch(() => {
        Object.assign(this.content,this.temp);
        Swal.fire('Oops...', 'Something went wrong!', 'error')
      });
    }
  },
  mounted() {

    if (this.getContent.content == null) {
      this.getAboutUs().then((response) => {
        this.aboutUs = response;
        console.log(response.data);

      });
    } else {
      this.aboutUs = this.getContent;

    }
  }


}
</script>

<style>

</style>