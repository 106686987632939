<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="getAllFlashDeal"
          class="elevation-2 pt-2"
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>Flash Deals</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="900px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    rounded
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Create New Deals
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text class="pa-0">
                    <v-container>
                      <v-form v-model="valid">
                        <v-row>
                          <v-col cols="12" class="pb-0">
                            <v-text-field
                              v-model="editedItem.title"
                              outlined
                              dense
                              label="Flash Deal Title*"
                              hint="Hint: International Women's Day Deals"
                              :rules="[rule.required]"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="pb-0">
                            <v-file-input
                              @change="imageSelect($event)"
                              v-model="retrieveBanner"
                              label="Category Image*"
                              accept="image/*"
                              outlined
                              prepend-icon=""
                              required
                              dense
                            ></v-file-input>
                            <v-textarea
                              v-model="editedItem.description"
                              outlined
                              dense
                              rows="4"
                              row-height="30"
                              :rules="[rule.required]"
                              label="Flash Deal Short Description*"
                              required
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" class="pb-0">
                            <!-- <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.start_date"
                                  label="Pick Start Date"
                                  prepend-icon=""
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="[rule.required]"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.start_date"
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn color="primary" @click="modal = false">
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog> -->

                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="time"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.start_date"
                                  label="Pick Start Time*"
                                  prepend-icon=""
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="[rule.required]"
                                  required
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="modal"
                                v-model="editedItem.start_date"
                                full-width
                              >
                                <v-spacer></v-spacer>

                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn color="primary" @click="modal = false">
                                  OK
                                </v-btn>
                              </v-time-picker>
                            </v-dialog>
                          </v-col>
                          <v-col cols="12" class="pb-0">
                            <v-dialog
                              ref="dialog"
                              v-model="modal1"
                              :return-value.sync="time1"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.end_date"
                                  label="Pick End Time*"
                                  prepend-icon=""
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="[rule.required]"
                                  required
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="modal1"
                                v-model="editedItem.end_date"
                                full-width
                              >
                                <v-spacer></v-spacer>

                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal1 = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn color="primary" @click="modal1 = false">
                                  OK
                                </v-btn>
                              </v-time-picker>
                            </v-dialog>
                          </v-col>
                          <v-col cols="12">
                            <v-dialog
                              ref="dialog"
                              v-model="modal3"
                              :return-value.sync="date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.from"
                                  label="From When It starts*"
                                  prepend-icon=""
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="[rule.required]"
                                  required
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.from"
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal3 = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn color="primary" @click="modal3 = false">
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                          <v-col cols="12">
                            <p class="font-bold">Flash Deals Rules</p>
                            <vue-editor
                              v-model="editedItem.rules"
                              placeholder="Please write rules in unordered list"
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      dark
                      @click="save()"
                      class="px-6"
                      rounded
                      :disabled="!valid"
                    >
                      Save &amp; Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-text class="pt-4 text-center">
                    <v-icon color="error" size="50">mdi-close-circle</v-icon>
                    <h2 class="font-bold py-4">
                      Are you sure want to delete this Item?
                    </h2>
                    <h4 class="font-bold text-left">
                      Please be aware that you cannot revert this item in
                      future.
                    </h4>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      class="px-8"
                      rounded
                      dark
                      @click="deleteItemConfirm"
                      >OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.banner`]="{ item }">
            <v-dialog :id="'dialog' + item.id" width="600">
              <template v-slot:activator="{ on, attrs }">
                <img
                  :src="getImage(item.banner)"
                  alt="banner-image"
                  class="img-thumbnail my-2"
                  width="100"
                  height="60"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-card>
                <v-card-title style="background-color: #163691; color: white"
                  >Banner
                </v-card-title>
                <img :src="getImage(item.banner)" width="100%" />
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <v-dialog :id="'descriptionDialog' + item.id" width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                  <v-icon size="14" class="pr-2">mdi-eye</v-icon>
                  Description
                </v-btn>
              </template>

              <v-card>
                <v-card-title>Flash Deals Description </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="py-2">
                  <p>{{ item.description }}</p>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.rules`]="{ item }">
            <v-dialog :id="item.id" width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                  <v-icon size="14" class="pr-2">mdi-eye</v-icon>
                  Rules
                </v-btn>
              </template>

              <v-card>
                <v-card-title>Flash Deals Rules </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="py-2">
                  <p v-html="item.rules"></p>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  components: { VueEditor },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    time: null,
    time1: null,
    modal: false,
    valid: false,
    modal1: false,
    modal3: false,
    dialog: false,
    dialogDelete: false,
    rule: {
      required: (v) => !!v || "This field is required.",
    },
    rules: "",
    headers: [
      { text: "SN", value: "sn" },

      {
        text: "Flash Deal Title",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "Description", value: "description" },
      { text: "Banner", value: "banner" },
      { text: "Rules", value: "rules" },
      { text: "Start Time", value: "start_date" },
      { text: "End Time", value: "end_date" },
      { text: "From Date", value: "from" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      description: "",
      deal_rules: "",
      start_date: null,
      end_date: null,
      from: new Date().toISOString().substr(0, 10),
    },
    defaultItem: {
      name: "",
      description: "",
      deal_rules: "",
      start_date: "",
      from: "",
      end_date: "",
    },
  }),

  computed: {
    ...mapState({
      flashDeals: (state) => state.flashDeals,
    }),
    formTitle() {
      return this.editedIndex === -1
        ? "Add New FlashDeal"
        : "Edit FlashDeal Details";
    },
    retrieveBanner() {
      if (this.editedItem.banner) {
        if (this.editedItem.banner instanceof Blob) {
          return this.editedItem.banner;
        }
        let ext1 = this.editedItem.banner.split(".").pop();
        return new File(
          [this.editedItem.banner],
          this.editedItem.title + "Banner." + ext1,
          {
            type: "image/*",
          }
        );
      } else {
        return "";
      }
    },

    getAllFlashDeal() {
      return this.flashDeals.map((item, index) => ({
        ...item,
        sn: index + 1,
        title: item.title,
        description: item.description,
        rules: item.rules,
        start_date: item.start_date,
        end_date: item.end_date,
        banner: item.banner,
      }));
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions([
      "getFlashDeal",
      "storeFlashDeal",
      "updateFlashDeal",
      "deleteFlashDeal",
    ]),

    getImage(path) {
      if (path instanceof Blob) return URL.createObjectURL(path);
      else return path;
    },

    imageSelect(event) {
      this.editedItem.banner = event;
    },

    editItem(item) {
      let temp = this.flashDeals.filter(
        (flashDeal) => flashDeal.id === item.id
      )[0];
      this.editedIndex = this.flashDeals.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      let temp = this.flashDeals.filter(
        (flashDeal) => flashDeal.id === item.id
      )[0];
      this.editedIndex = this.flashDeals.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true;
      this.deleteFlashDeal(this.editedItem.id)
        .then(() => {
          this.flashDeals.splice(this.editedIndex, 1);
          this.$store.commit("setFlashDeals", this.flashDeals);
          this.loading = false;
          this.editedIndex = -1;
          Swal.fire("Success!", "FlashDeal Deleted Successfully!", "success");
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.editedIndex = -1;
          this.loading = false;
        });
      this.closeDelete();
    },
    updateIsFeatured(item) {
      item.featured = item.featured ? 1 : 0;
      let data = { id: item.id, featured: item.featured ? 1 : 0 };
      let temp = this.flashDeals.filter(
        (flashDeal) => flashDeal.id === item.id
      )[0];
      this.editedIndex = this.flashDeals.indexOf(temp);
      let temp1 = Object.assign({}, this.flashDeals[this.editedIndex], item);
      this.updateFeatured(data)
        .then(() => {
          Object.assign(this.flashDeals[this.editedIndex], temp1);
          this.$store.commit("setFlashDeals", this.flashDeals);
          this.editedIndex = -1;
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.editedIndex = -1;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = {};
        // this.editedIndex = -1
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    save() {
      let fd;
      fd = new FormData();
      fd.append("banner", this.editedItem.banner);
      fd.append("title", this.editedItem.title);
      fd.append("rules", this.editedItem.rules);
      fd.append("start_date", this.editedItem.start_date);
      fd.append("end_date", this.editedItem.end_date);
      fd.append("from", this.editedItem.from);
      fd.append("description", this.editedItem.description);

      this.loading = true;
      if (this.editedIndex > -1) {
        let temp = Object.assign(
          {},
          this.flashDeals[this.editedIndex],
          this.editedItem
        );

        this.updateFlashDeal({ id: this.editedItem.id, fd: fd })
          .then(() => {
            Object.assign(this.flashDeals[this.editedIndex], temp);
            this.$store.commit("setFlashDeals", this.flashDeals);
            this.editedIndex = -1;
            this.loading = false;
            this.$refs.form.reset();
            Swal.fire("Success!", "FlashDeal Updated Successfully!", "success");
          })
          .catch(() => {
            this.editedIndex = -1;
            this.loading = false;
            this.$refs.form.reset();
            Swal.fire("Oops...", "Something went wrong!", "error");
          });
      } else {
        this.storeFlashDeal(fd)
          .then(() => {
            this.loading = false;
            Swal.fire("Success!", "FlashDeal Added Successfully!", "success");
            this.$refs.form.reset();
            this.editedIndex = -1;
          })
          .catch(() => {
            this.loading = false;
            this.editedIndex = -1;
            this.$refs.form.reset();
            Swal.fire("Oops...", "Something went wrong!", "error");
          });

        // this.flashDeals.unshift(this.editedItem)
      }
      this.close();
    },
  },
  created() {
    if (!this.flashDeals.length) {
      this.getFlashDeal();
    }
  },
};
</script>
<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0 !important;
}
</style>
