import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: ["sdata", "options"],
  data: function() {
    return {
      datacollection: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Vendors",
            backgroundColor: "#ff2241",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
            data: this.sdata,
          },
        ],
      },
      baroptions: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 80,
        height: 300,
      },
    };
  },
  mounted() {
    // this.chartData is created in the mixin
    this.renderChart(this.datacollection, this.baroptions);
  },
};
