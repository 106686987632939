<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title
            >Buyers Details
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              @keyup.enter="searchs"
              append-icon="mdi-magnify"
              label="Search"
              single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="filtered.length > 0 || noItem ? filtered : getAllBuyersList"
            @pagination="paginate"
            :footer-props="{ 'items-per-page-options': [ 10, 15, 20] }"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
            <!-- <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-left align-items-center" :id="item.id">
                <div class="my-2">
                  <v-btn color="error" small dark @click="dialog = true">
                    <v-icon dark size="14" class="pr-2">mdi-eye</v-icon> View
                    Products
                  </v-btn>
                </div>
              </div>
            </template> -->
          </v-data-table>
        </v-card>
        <!-- <v-dialog v-model="dialog" max-width="900px">
          <v-card elevation="5">
            <v-card-title
              >Product Details
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="product_headers"
              :items="products"
              :search="search"
            >
            </v-data-table>
          </v-card>
        </v-dialog> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      noItem:false,
      filtered:[],
      search: "",
      product_headers: [
        { text: "S.N.", value: "sn1" },
        { text: "Product Name", value: "product_name" },
        { text: "Quantity", value: "quantity" },
        { text: "Price", value: "price" },
        { text: "Seller Name", value: "seller_name" },
      ],
      products: [
        {
          sn1: "1",
          product_name: "Refrigerator 180L",
          quantity: "1",
          price: "Rs. 30000",
          seller_name: "CG",
        },
      ],
      headers: [
        { text: "S.N.", value: "sn" },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Phone No", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Total Orders", value: "orders" },
        { text: "Total Price Spent", value: "total" },
        { text: "Join Date", value: "joinDate" },
      ],
    };
  },
  
  computed: {
    ...mapState({
      buyers: (state) => state.buyers,
    }),

    getAllBuyersList() {
      return this.buyers.map((item, index) => ({
        ...item,
        sn: index + 1,
        name: item.user && item.user.name,
        email: item.user.email,
        phone: item.user.phone,
        joinDate: moment(item.user.updated_at).format("DD MM YYYY"),
        total: "Rs." + item.total,
        products: item.products,
      }));
    },
  },
  methods: {
    ...mapActions(["getAllBuyers"]),
    paginate(e) {
      if (this.search === "" || this.search.indexOf(" ") >= 0) {
        if (this.$store.state.pages.buyers < e.page) {
          this.$store.commit("setBuyerPage", e.page);
          this.getAllBuyers(e.page);
        }
      }
    },
     searchs() {
      if (this.search === "") {
        this.noItem=false;
        this.filtered = [];
      } else {
        this.loading = true;
        this.$store
          .dispatch("getBuyerSearch", this.search)
          .then((res) => {
            this.loading = false;
            this.filtered = this.mapSearch(res.data);

            if (res.data.length === 0) {
              this.noItem = true;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    mapSearch(item){
       return item.map((item, index) => ({
        ...item,
        sn: index + 1,
        name: item.user && item.user.name,
        email: item.user.email,
        phone: item.user.phone,
        joinDate: moment(item.user.updated_at).format("DD MM YYYY"),
        total: "Rs." + item.total,
        products: item.products,
      }));
    }
  },
  mounted() {
    if (!this.buyers.length) {
      this.getAllBuyers();
    }
  },
};
</script>

<style></style>
