<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="teal"
        ></v-progress-linear>

        <v-data-table
            :headers="headers"
            :items="getSize"
            :search="search"
            class="elevation-5 pt-2"
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>Size Details</v-toolbar-title>

              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      small
                      class="ml-6"
                      v-bind="attrs"
                      v-on="on"
                      rounded
                  >Create New Size
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text class="pa-0">
                    <v-container>
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <v-form ref="form" v-model="valid">
                            <v-text-field
                                v-model="editedItem.code"
                                outlined
                                dense
                                label="Size Name"
                                :rules="[rules.required]"
                            ></v-text-field>

                          </v-form>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="primary" class="px-6 my-2" rounded dark @click="save" :disabled="!valid">
                      Save &amp; Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
              ></v-text-field>
              <v-dialog v-model="dialogDelete" max-width="400px">
                <v-card>
                  <v-card-text class="pt-4 text-center">
                    <v-icon color="error" size="50">mdi-close-circle</v-icon>
                    <h2 class="font-bold py-4">
                      Are you sure want to delete this Item?
                    </h2>
                    <h4 class="font-bold text-left">
                      Please be aware that you cannot revert this item in
                      future.
                    </h4>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        class="px-8"
                        rounded
                        dark
                        @click="deleteItemConfirm"
                    >OK
                    </v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ formatDate(item.created_at) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState} from "vuex";
import {dateMixin, imageMixin} from "../../../../mixin/helperMixin";
import Swal from "sweetalert2";

export default {
  mixins: [dateMixin, imageMixin],
  data: () => ({
    loading: false,
    valid: false,
    dialog: false,
    dialogDelete: false,
    rules: {
      required: (v) => !!v || "This field is required.",
    },
    search: "",
    brands: [],
    headers: [
      {text: "S.N.", value: "sn"},
      {
        text: "Size Code",
        align: "start",
        sortable: false,
        value: "code",
      },

      {text: "Created Date", value: "created_at"},
      {text: "Actions", value: "actions", sortable: false},
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      logo: "",
      created_at: "",
    },
    defaultItem: {
      name: "",
      created_at: "",
    },
    required: [(v) => !!v || "This Field is required"],
  }),

  computed: {
    ...mapState(["sizes"]),
    formTitle() {
      return this.editedIndex === -1 ? "Add New Size" : "Edit Size Details";
    },
    getSize() {
      return this.sizes.map((item, index) => ({
        ...item,
        sn: index + 1,
      }));
    },


  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions(["getSizes", "storeSize", "updateSize", "deleteSize"]),


    editItem(item) {
      let temp = this.sizes.filter((size) => size.id === item.id)[0];
      this.editedIndex = this.sizes.indexOf(temp);
      this.editedItem = {
        id: item.id,
        code: item.code,
      };
      this.dialog = true;
    },

    deleteItem(item) {
      let temp = this.sizes.filter((size) => size.id === item.id)[0];
      this.editedIndex = this.sizes.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true;
      this.deleteSize(this.editedItem.id)
          .then(() => {
            this.loading = false;
            this.$store.commit("setSizes", this.sizes);
            this.sizes.splice(this.editedIndex, 1);
            Swal.fire("Success!", "Size Deleted Successfully!", "success");
            this.editedIndex = -1;
          })
          .catch(() => {
            this.loading = false;
            Swal.fire("Oops...", "Something went wrong!", "error");
            this.editedIndex = -1;
          });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);

        // this.editedIndex = -1
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);

      });
    },

    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.editedIndex > -1) {
          let temp = Object.assign(
              {},
              this.sizes[this.editedIndex],
              this.editedItem
          );
          this.updateSize(this.editedItem)
              .then(() => {
                Object.assign(this.sizes[this.editedIndex], temp);
                this.$store.commit("setSizes", this.sizes);
                this.editedIndex = -1;
                this.loading = false;
                this.$refs.form.reset()
                Swal.fire("Success!", "Size Updated Successfully!", "success");
              })
              .catch(() => {
                this.loading = false;
                this.editedIndex = -1;
                Swal.fire("Oops...", "Something went wrong!", "error");
              });
        } else {
          this.storeSize(this.editedItem)
              .then(() => {
                this.loading = false;
                Swal.fire("Success!", "Size Added Successfully!", "success");
                this.$refs.form.reset()
                this.editedIndex = -1;
              })
              .catch(() => {
                this.loading = false;
                this.editedIndex = -1;
                Swal.fire("Oops...", "Something went wrong!", "error");
              });
        }

        this.close();
      }
    },
  },
 mounted() {
    if (this.sizes.length === 0) this.getSizes()

  },
};
</script>
<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0 !important;
}
</style>
