import Vue from "vue";
import Vuex from "vuex";
import axios_api from "../axios/axios_api";
// import * as vendor from "./vendor.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("a_token") || null,
    user: {},
    isAuthenticated: false,
    categories: [],
    supports: [],
    products: [],
    staffs: [],
    subCategories: [],
    subSubCategories: [],
    brands: [],
    tags: [],
    userSupport: [],
    reviews: [],
    sellers: [],
    customers: [],
    buyers: [],
    orders: [],
    customOrders: [],
    addVendor: {},
    aboutUs: {},
    faq: [],
    deliveryCharges: {
      express: null,
      normal: null,
    },
    legalCredentials: [],
    socialMedia: {
      fb: null,
      insta: null,
      twitter: null,
      linkedin: null,
      youtube: null,
    },
    terms: [],
    flashDeals: [],
    jobApplications: [],
    participationList: [],
    settings: [],
    coupons: [],
    roles: [],
    careers: [],
    banners: [],
    pickUp: [],
    withdrawRequests: [],
    wishLists: [],
    categoryReports: [],
    sizes: [],
    statesOfNepal: [
      {
        id: 1,
        name: "State 1",
      },
      {
        id: 2,
        name: "State 2",
      },
      {
        id: 3,
        name: "Bagmati State",
      },
      {
        id: 4,
        name: "Gandaki State",
      },
      {
        id: 5,
        name: "Lumbini State",
      },
      {
        id: 6,
        name: "Karnali State",
      },
      {
        id: 7,
        name: "Sudurpaschim State",
      },
    ],
    districtsOfNepal: [
      {
        state: "State 1",
        districts: [
          "Bhojpur",
          "Dhankuta",
          "Ilam",
          "Jhapa",
          "Khotang",
          "Morang",
          "Okhaldhunga",
          "Panchthar",
          "Sankhuwasabha",
          "Solukhumbu",
          "Sunsari",
          "Taplejung",
          "Terhathum",
          "Udayapur",
        ],
      },
      {
        state: "State 2",
        districts: [
          "Bara",
          "Dhanusa",
          "Mahottari",
          "Parsa",
          "Rautahat",
          "Saptari",
          "Sarlahi",
          "Siraha",
        ],
      },
      {
        state: "Bagmati State",
        districts: [
          "Bhaktapur",
          "Chitwan",
          "Dhading",
          "Dolakha",
          "Kathmandu",
          "Kavrepalanchok",
          "Lalitpur",
          "Makwanpur",
          "Nuwakot",
          "Ramechhap",
          "Rasuwa",
          "Sindhuli",
          "Sindhupalchok",
        ],
      },
      {
        state: "Gandaki State",
        districts: [
          "Baglung",
          "Gorkha",
          "Kaski",
          "Lamjung",
          "Manang",
          "Mustang",
          "Myagdi",
          "Nawalpur",
          "Parbat",
          "Syangja",
          "Tanahun",
        ],
      },
      {
        state: "Lumbini State",
        districts: [
          "Arghakhanchi",
          "Banke",
          "Bardiya",
          "Dang",
          "Eastern",
          "Gulmi",
          "Kapilvastu",
          "Palpa",
          "Parasi",
          "Pyuthan",
          "Rolpa",
          "Rupandehi",
        ],
      },
      {
        state: "Karnali State",
        districts: [
          "Dailekh",
          "Dolpa",
          "Humla",
          "Jajarkot",
          "Jumla",
          "Kalikot",
          "Mugu",
          "Salyan	",
          "Surkhet",
          "Western Rukum",
        ],
      },
      {
        state: "Sudurpaschim State",
        districts: [
          "Dailekh",
          "Dolpa",
          "Humla",
          "Jajarkot",
          "Jumla",
          "Kalikot",
          "Mugu",
          "Salyan	",
          "Surkhet",
          "Western Rukum",
        ],
      },
    ],
    contactMessages: [],
    deal: {},
    notifications: [],
    pages: {
      product: 0,
      customers: 0,
      buyers: 0,
      brands: 0,
      tags: 0,
    },
    brandsCount: 0,
    productCount: 0,
    tagsCount: 0,
    reviewsCount: 0,
  },
  getters: {
    getCategories(state) {
      return state.categories;
    },
    getProducts(state) {
      return state.products;
    },
    getDeliveryCharges(state) {
      return state.deliveryCharges;
    },
    getSocialMedia(state) {
      return state.socialMedia;
    },
    getAboutUs(state) {
      return state.aboutUs;
    },
    getFaq(state) {
      return state.faq;
    },
    getUserSupport(state) {
      return state.userSupport;
    },
    getReviews(state) {
      return state.reviews;
    },
    getLegalCredentials(state) {
      return state.legalCredentials;
    },
    getCustomerLists(state) {
      return state.customers;
    },
    getBuyerLists(state) {
      return state.buyers;
    },

    getPendingOrder(state) {
      let pending = state.orders.filter((item) => item.status === "Ready");
      let result = [];
      pending.map((obj) => {
        if (Object.prototype.hasOwnProperty.call(obj, "order_detail")) {
          if (obj.order_detail.length > 0) {
            obj.order_detail.map((singleOrder) => result.push(singleOrder));
          }
        }
      });
      return result;
    },
    // getSellerLists(state) {
    //   return state.sellers;
    // },
    getPartipationList(state) {
      return state.participationList;
    },

    getSubCategories(state) {
      return state.subCategories;
    },
    getSubSubCategories(state) {
      return state.subSubCategories;
    },
    getBrands(state) {
      return state.brands;
    },
    getSellersList(state) {
      return state.sellers;
    },
    getTags(state) {
      return state.tags;
    },
    getCareers(state) {
      return state.careers;
    },
    getBanners(state) {
      return state.banners;
    },
    getStaffs(state) {
      return state.staffs;
    },
    getCoupons(state) {
      return state.coupons;
    },
    getFlashDeals(state) {
      return state.flashDeals;
    },
    getJobApplications(state) {
      return state.jobApplications;
    },
    getSettings(state) {
      return state.settings;
    },
    getRoles(state) {
      return state.roles;
    },
    getPendingOrders(state) {
      return state.orders.filter(
        (item) =>
          item.status === "Ready" ||
          item.status === "Picking Up" ||
          item.status === "Picked Up" ||
          item.status === "Drop Off"
      );
    },
    getPickedUpOrders(state) {
      return state.orders.filter(
        (item) => item.status === "Picked Up" || item.status === "Drop Off"
      );
    },
    getActiveOrders(state) {
      return state.orders.filter(
        (item) =>
          item.status !== "Rejected" &&
          item.status !== "Delivered" &&
          item.status !== "Cancelled"
      );
    },
    getCancelledOrders(state) {
      return state.orders.filter((item) => item.status === "Cancelled");
    },
    getCompletedOrders(state) {
      return state.orders.filter((item) => item.status === "Delivered");
    },
    getPickUp(state) {
      return state.pickUp;
    },
    getRiders(state) {
      return state.staffs.filter(
        (item) => item.roles && item.roles.slug === "rider"
      );
    },
    getNewWithdrawRequests(state) {
      let requests = state.withdrawRequests.filter((req) => req.status === 1);
      return requests.map((r, index) => ({
        ...r,
        sn: index + 1,
      }));
    },
    getPendingWithdrawRequests(state) {
      let requests = state.withdrawRequests.filter((req) => req.status === 2);
      return requests.map((r, index) => ({
        ...r,
        sn: index + 1,
      }));
    },
    getVerifiedWithdrawRequests(state) {
      let requests = state.withdrawRequests.filter((req) => req.status === 3);
      return requests.map((r, index) => ({
        ...r,
        sn: index + 1,
      }));
    },
    getRejectedWithdrawRequests(state) {
      let requests = state.withdrawRequests.filter((req) => req.status === 0);
      return requests.map((r, index) => ({
        ...r,
        sn: index + 1,
      }));
    },
    getPrivacyPolicy(state) {
      if (state.legalCredentials.length) {
        return state.legalCredentials.filter(
          (item) => item.name === "privacy_policy"
        )[0];
      } else {
        return [];
      }
    },
    getCookiePolicy(state) {
      if (state.legalCredentials.length) {
        return state.legalCredentials.filter(
          (item) => item.name === "cookie_policy"
        )[0];
      } else {
        return [];
      }
    },
    getWarrantyPolicy(state) {
      if (state.legalCredentials.length) {
        return state.legalCredentials.filter(
          (item) => item.name === "warranty_policy"
        )[0];
      } else {
        return [];
      }
    },
    getRefundPolicy(state) {
      if (state.legalCredentials.length) {
        return state.legalCredentials.filter(
          (item) => item.name === "refund_policy"
        )[0];
      } else {
        return [];
      }
    },
    getDataPolicy(state) {
      if (state.legalCredentials.length) {
        return state.legalCredentials.filter(
          (item) => item.name === "data_policy"
        )[0];
      } else {
        return [];
      }
    },
    getBuyerProtection(state) {
      if (state.legalCredentials.length) {
        return state.legalCredentials.filter(
          (item) => item.name === "buyer_protetion"
        )[0];
      } else {
        return [];
      }
    },
    getSellerPolicy(state) {
      if (state.legalCredentials.length) {
        return state.legalCredentials.filter(
          (item) => item.name === "seller_policy"
        )[0];
      } else {
        return [];
      }
    },
    getReturnPolicy(state) {
      if (state.legalCredentials.length) {
        return state.legalCredentials.filter(
          (item) => item.name === "return_policy"
        )[0];
      } else {
        return [];
      }
    },
    getTerms(state) {
      if (state.legalCredentials.length) {
        return state.legalCredentials.filter(
          (item) => item.name === "terms"
        )[0];
      } else {
        return [];
      }
    },
    getSupportPolicy(state) {
      if (state.legalCredentials.length) {
        return state.legalCredentials.filter(
          (item) => item.name === "support_policy"
        )[0];
      } else {
        return [];
      }
    },
    getSizes(state) {
      return state.sizes;
    },

    getActiveCustomOrders(state) {
      return state.customOrders.filter(
        (item) =>
          item.status !== "Rejected" &&
          item.status !== "Delivered" &&
          item.status !== "Cancelled"
      );
    },
    getPendingCustomOrders(state) {
      return state.customOrders.filter(
        (item) =>
          item.status === "Processing"
      );
    },
    getCancelledCustomOrders(state) {
      return state.customOrders.filter((item) => item.status === "Cancelled");
    },
    getCompletedCustomOrders(state) {
      return state.customOrders.filter((item) => item.status === "Delivered");
    },
  },

  mutations: {
    setToken(state, payload) {
      localStorage.setItem("a_token", payload);
      state.token = payload;
    },
    setStatus(state, payload) {
      const item = state.orders.find((item) => item.id === payload.id);
      Object.assign(item, payload);
    },
    updateSellers(state, payload) {
      const item = state.sellers.find((item) => item.id === payload.id);
      Object.assign(item, payload);
    },

    updateOrdered(state, payload) {
      delete payload.billing_address;
      delete payload.shipping_address;
      const item = state.orders
        .filter((item) => item.id === payload.order_id)[0]
        .order_detail.filter((item) => item.id === payload.id)[0];
      Object.assign(item, payload);
    },

    updateDeliveredOrder(state, payload) {
      delete payload.billing_address;
      delete payload.shipping_address;

      const item = state.orders.filter((item) => item.id === payload.id)[0];
      Object.assign(item, payload);
    },

    setAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setPickUp(state, payload) {
      state.pickUp = payload;
    },

    setCategories(state, payload) {
      state.categories = payload;
    },
    setProducts(state, payload) {
      state.products = payload;
    },
    addProducts(state, payload) {
      let products = state.products;
      products = products.concat(payload);
      state.products = products;
    },
    setRoles(state, payload) {
      state.roles = payload;
    },
    setJobApplications(state, payload) {
      state.jobApplications = payload;
    },
    setCustomerLists(state, payload) {
      state.customers = payload;
    },
    setBuyerLists(state, payload) {
      state.buyers = payload;
    },
    setOrderLists(state, payload) {
      state.orders = payload;
    },
    setSellersList(state, payload) {
      state.sellers = payload;
    },
    setParticipationList(state, payload) {
      state.participationList = payload;
    },
    setSocialMedia(state, payload) {
      state.socialMedia = payload;
    },
    setSettings(state, payload) {
      state.settings = payload;
    },
    setAboutUs(state, payload) {
      state.aboutUs = payload;
    },
    setExpressDeliveryCharge(state, payload) {
      state.deliveryCharges.express = payload.filter(
        (c) => c.type === "Express"
      );
    },
    setNormalDeliveryCharge(state, payload) {
      state.deliveryCharges.normal = payload.filter((c) => c.type === "Normal");
    },

    setReviews(state, payload) {
      state.reviews = payload;
    },
    setLegalCredentials(state, payload) {
      state.legalCredentials = payload;
    },

    setTerms(state, payload) {
      state.legalCredentials.terms = payload;
    },

    addCategory(state, payload) {
      state.categories.unshift(payload);
    },
    setSubCategories(state, payload) {
      state.subCategories = payload;
    },
    addSubCategory(state, payload) {
      state.subCategories.unshift(payload);
    },
    setSubSubCategories(state, payload) {
      state.subSubCategories = payload;
    },
    addSubSubCategory(state, payload) {
      state.subSubCategories.unshift(payload);
    },
    setBrands(state, payload) {
      state.brands = payload;
    },
    addBrand(state, payload) {
      state.brands.unshift(payload);
    },
    setPaginatedBrands(state, payload) {
      let brands = state.brands;
      brands = brands.concat(payload);
      state.brands = brands;
    },
    setPaginatedTags(state, payload) {
      let tags = state.tags;
      tags = tags.concat(payload);
      state.tags = tags;
    },
    setPaginatedCustomers(state, payload) {
      let customers = state.customers;
      customers = customers.concat(payload);
      state.customers = customers;
    },
    setPaginatedBuyers(state, payload) {
      let buyers = state.buyers;
      buyers = buyers.concat(Object.values(payload));
      state.buyers = buyers;
    },
    setTags(state, payload) {
      state.tags = payload;
    },
    addTag(state, payload) {
      state.tags.unshift(payload);
    },
    addFaq(state, payload) {
      state.faq.unshift(payload);
    },
    setFaq(state, payload) {
      state.faq = payload;
    },
    getSupports(state) {
      return state.supports;
    },
    addUserSupport(state, payload) {
      state.userSupport.unshift(payload);
    },
    setUserSupport(state, payload) {
      state.userSupport = payload;
    },
    addCareer(state, payload) {
      state.careers.unshift(payload);
    },
    setCareer(state, payload) {
      state.careers = payload;
    },
    addBanner(state, payload) {
      state.banners.unshift(payload);
    },
    setBanner(state, payload) {
      state.banners = payload;
    },
    setSupports(state, payload) {
      state.supports = payload;
    },
    addFlashDeal(state, payload) {
      state.flashDeals.unshift(payload);
    },
    setFlashDeal(state, payload) {
      state.flashDeals = payload;
    },
    addCoupon(state, payload) {
      state.coupons.unshift(payload);
    },
    setCoupon(state, payload) {
      state.coupons = payload;
    },
    addStaff(state, payload) {
      state.staffs.unshift(payload);
    },
    setStaff(state, payload) {
      state.staffs = payload;
    },
    updateSupports(state, payload) {
      state.supports = payload;
    },
    setVendorDetails(state, payload) {
      if (payload.type === null) {
        state.addVendor.basicdetails = payload;
      } else if (payload.type === "business") {
        state.addVendor.business_address = payload;
      } else if (payload.type === "warehouse") {
        state.addVendor.warehouse_address = payload;
      } else {
        state.addVendor.return_address = payload;
      }
    },
    setVendor(state, payload) {
      const item = state.sellers.find((item) => item.id === payload.id);
      Object.assign(item, payload);
    },
    storeWithdrawRequests(state, payload) {
      state.withdrawRequests = payload;
    },
    storeWishLists(state, payload) {
      state.wishLists = payload;
    },
    storeCategoryReport(state, payload) {
      state.categoryReports = payload;
    },
    storeContactMessages(state, payload) {
      state.contactMessages = payload;
    },
    removeContactMessages(state, payload) {
      state.contactMessages = state.contactMessages.filter(
        (c) => c.id !== payload
      );
    },
    updateLCData(state, payload) {
      state.legalCredentials.forEach((item) => {
        if (item.id === payload.id) {
          item.content = payload.content;
        }
      });
    },
    addVendor(state, payload) {
      state.sellers.unshift(payload);
    },
    setSize(state, payload) {
      state.sizes = payload;
    },
    addSize(state, payload) {
      state.sizes.unshift(payload);
    },
    setDealName(state, payload) {
      state.deal = payload;
    },
    setNotification(state, payload) {
      state.notifications = payload;
    },
    setProductPage(state, payload) {
      state.pages.product = payload;
    },
    setBrandPage(state, payload) {
      state.pages.brands = payload;
    },
    setTagPage(state, payload) {
      state.pages.tags = payload;
    },
    setCustomerPage(state, payload) {
      state.pages.customers = payload;
    },
    setBuyerPage(state, payload) {
      state.pages.buyers = payload;
    },
    setProductsCount(state, payload) {
      state.productCount = payload;
    },
    setBrandsCount(state, payload) {
      state.brandsCount = payload;
    },
    setTagsCount(state, payload) {
      state.tagsCount = payload;
    },
    setReviewCount(state, payload) {
      state.reviewsCount = payload;
    },
    setCustomOrders(state, payload) {
      state.customOrders = payload;
    },
    setCustomStatus(state, payload) {
      const item = state.customOrders.find((item) => item.id === payload.id);
      Object.assign(item, payload);
    },
    addCustomOrder(state, payload) {
      state.customOrders.unshift(payload);
    },
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios_api
          .post("login", data)
          .then((response) => {
            if (response.data) {
              commit("setToken", response.data.token);
              commit("setAuthenticated", true);
              commit("setUser", response.data.user);
            }
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getUser({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("getUser")
            .then((res) => {
              commit("setUser", res.data);
              commit("setAuthenticated", true);
              resolve(res);
            })
            .catch((error) => reject(error));
        });
      } else {
        return {
          status: 204,
          message: "Unauthorized Access",
        };
      }
    },
    getProducts(context, page = 1) {
      return new Promise((resolve, reject) => {
        let url = "all-products";
        if (page > 1) {
          url = url + "?page=" + page;
        }
        axios_api
          .get(url)
          .then((response) => {
            if (page > 1) {
              if (response.data !== [])
                context.commit("addProducts", response.data);
            } else {
              context.commit("setProducts", response.data);
            }
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getProductsCount({ commit }) {
      return new Promise((resolve, reject) => {
        let url = "/products/count";
        axios_api
          .get(url)
          .then((response) => {
            commit("setProductsCount", response.data.count);
            resolve(response.data.count);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBrandsCount({ commit }) {
      return new Promise((resolve, reject) => {
        let url = "/brands-count";
        axios_api
          .get(url)
          .then((response) => {
            commit("setBrandsCount", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTagsCount({ commit }) {
      return new Promise((resolve, reject) => {
        let url = "/tags-count";
        axios_api
          .get(url)
          .then((response) => {
            commit("setTagsCount", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getReviewCount({ commit }) {
      return new Promise((resolve, reject) => {
        let url = "/review-count";
        axios_api
          .get(url)
          .then((response) => {
            commit("setReviewCount", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCategories({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("categories")
          .then((response) => {
            commit("setCategories", response.data[0]);
            commit("setSubCategories", response.data[1]);
            commit("setSubSubCategories", response.data[2]);
            // commit("setBrands", response.data[3]);
            // commit("setTags", response.data[4]);
            resolve(response.data[0]);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    storeCategory({ state, commit }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("categories", data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              commit("addCategory", response.data.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateCategory: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data.fd.append("_method", "PUT");
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/categories/${data.id}`, data.fd, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    updateFeatured({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/update/is-featured/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    updateFeaturedSubCategory({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/subcategory/is-featured/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    deleteCategory: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/categories/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getSubCategories({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("sub-categories")
          .then((response) => {
            commit("setSubCategories", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    storeSubCategory: function ({ state, commit }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("sub-categories", data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              commit("addSubCategory", response.data.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateSubCategory: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data.fd.append("_method", "PUT");
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/sub-categories/${data.id}`, data.fd)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    deleteSubCategory: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/sub-categories/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getSubSubCategories({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("sub-sub-categories")
          .then((response) => {
            commit("setSubSubCategories", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    storeSubSubCategory: function ({ state, commit }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("sub-sub-categories", data)
            .then((response) => {
              commit("addSubSubCategory", response.data.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateSubSubCategory: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data._method = "PUT";
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/sub-sub-categories/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    deleteSubSubCategory: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/sub-sub-categories/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    storeBrand: function ({ state, commit }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("brands", data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              commit("addBrand", response.data.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateBrand: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data.fd.append("_method", "PUT");
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/brands/${data.id}`, data.fd, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    deleteBrand: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/brands/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getBrands(context, page = 1) {
      let url = "brands";
      if (page > 1) {
        url = url + "?page=" + page;
      }
      return new Promise((resolve, reject) => {
        axios_api
          .get(url)
          .then((response) => {
            if (page > 1) {
              context.commit("setPaginatedBrands", response.data.data);
            } else {
              context.commit("setBrands", response.data.data);
            }
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTags(context, page = 1) {
      let url = "tags";
      if (page > 1) {
        url = url + "?page=" + page;
      }
      return new Promise((resolve, reject) => {
        axios_api
          .get(url)
          .then((response) => {
            if (page > 1) {
              context.commit("setPaginatedTags", response.data.data);
            } else {
              context.commit("setTags", response.data.data);
            }
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    storeTag: function ({ state, commit }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("tags", data)
            .then((response) => {
              commit("addTag", response.data.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateTag: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data._method = "PUT";
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/tags/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    deleteTag: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/tags/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getLegalCredentials({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("legal-credentials")
          .then((response) => {
            commit("setLegalCredentials", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateLegalCredentials: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data._method = "PUT";
        return new Promise((resolve, reject) => {
          const url = "/legal-credentials/" + data.id;
          axios_api
            .post(url, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getReviews({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("reviews")
          .then((response) => {
            commit("setReviews", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteReview: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/reviews/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getSocialMedia({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("social-media")
          .then((response) => {
            commit("setSocialMedia", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateSocialMedia: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data._method = "PUT";
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/social-media/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getSettings({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("settings")
          .then((response) => {
            commit("setSettings", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateSetting: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data.fd.append("_method", "PUT");
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/settings/${data.id}`, data.fd, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getAboutUs({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("about-us")
          .then((response) => {
            commit("setAboutUs", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateAboutUs: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data._method = "PUT";
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/about-us/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    storeFaq: function ({ state, commit }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("faq", data)
            .then((response) => {
              commit("addFaq", response.data.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateFaq: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        Object.assign(data, {
          _method: "PUT",
        });
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/faq/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    deleteFaq: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/faq/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getFaq({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("faq")
          .then((response) => {
            commit("setFaq", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    storeUserSupport: function ({ state, commit }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("user-support", data)
            .then((response) => {
              commit("addUserSupport", response.data.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateUserSupport: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        Object.assign(data, {
          _method: "PUT",
        });
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/user-support/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    deleteUserSupport: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/user-support/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getUserSupport({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("user-support")
            .then((response) => {
              commit("setUserSupport", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    storeCareer: function ({ state, commit }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("careers", data)
            .then((response) => {
              commit("addCareer", response.data.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateCareer: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        Object.assign(data, {
          _method: "PUT",
        });
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/careers/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    deleteCareer: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/careers/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getCareer({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("careers")
          .then((response) => {
            commit("setCareer", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    storeBanner: function ({ state, commit }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("banners", data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              commit("addBanner", response.data.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateBanner: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data.fd.append("_method", "PUT");
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/banners/${data.id}`, data.fd, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    deleteBanner: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/banners/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getBanner({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("banners")
          .then((response) => {
            commit("setBanner", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updatePublished: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/update/is-published/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    // get all vendors those who participate in flashdeals
    getAllPartipants({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("flashdeal-participants")
            .then((response) => {
              commit("setParticipationList", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    // get all vendor lists
    getAllSellers({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("vendor")
            .then((response) => {
              commit("setSellersList", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    deleteSeller: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/vendor/${id}`)
            .then((response) => {
              resolve(response)
            })
            .catch((error) => reject(error));
        });
      }
    },

    updateSellers: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post(`vendors/status`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    assignRiders: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post(`pickup/assign-rider`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    assignDeliveryRiders: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post(`delivery-option/assign-rider`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },

    // get all customers list
    getAllCustomers(context, page = 1) {
      if (context.state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
        return new Promise((resolve, reject) => {
          let url = "all-customers";
          if (page > 1) {
            url = url + "?page=" + page;
          }
          axios_api
            .get(url)
            .then((response) => {
              if (page > 1) {
                context.commit("setPaginatedCustomers", response.data);
              } else {
                context.commit("setCustomerLists", response.data);
              }
              resolve(response.data.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateCustomerStatus: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post(`users/update-status/${data}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    // get all customers but should bought an item atleast
    getAllBuyers(context, page = 1) {
      return new Promise((resolve, reject) => {
        let url = "all-buyers";
        if (page > 1) {
          url = url + "?page=" + page;
        }
        axios_api
          .get(url)
          .then((response) => {
            if (page > 1) {
              context.commit("setPaginatedBuyers", response.data.data);
            } else {
              context.commit("setBuyerLists", response.data.data);
            }
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // get all orders (new, pending, cancelled, delivered)
    getAllOrders({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("all-orders")
          .then((response) => {
            commit("setOrderLists", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateOrderStatus: function ({ state }, data) {
      if (state.token) {
        // data._method="PUT";
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post(`update/order`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    storeFlashDeal: function ({ state, commit }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("flash-deal", data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              commit("addFlashDeal", response.data.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateFlashDeal: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data.fd.append("_method", "PUT");
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/flash-deal/${data.id}`, data.fd, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    deleteFlashDeal: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/flash-deal/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getFlashDeal({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("flash-deal")
            .then((response) => {
              commit("setFlashDeal", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateParticipantStatus: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post(`update-staus/flashdeal-participants/`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    deleteJobApplication: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/job-applications/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getJobApplication({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("job-applications")
            .then((response) => {
              commit("setJobApplications", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    storeCoupon: function ({ state, commit }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          let fd = new FormData();
          fd.append("code", data.code);
          fd.append("description", data.description);
          fd.append("type", data.type);
          fd.append("discount", data.discount);
          fd.append("usage", data.usage);
          fd.append("start_date", data.start_date);
          fd.append("end_date", data.end_date);
          fd.append("title", data.title);
          fd.append("image", data.image);
          fd.append("totalAmount", data.totalAmount);
          axios_api
            .post("coupons", fd)
            .then((response) => {
              commit("addCoupon", response.data.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateCoupon: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        Object.assign(data, {
          _method: "PUT",
        });
        return new Promise((resolve, reject) => {
          let fd = new FormData();
          fd.append("code", data.code);
          fd.append("description", data.description);
          fd.append("type", data.type);
          fd.append("discount", data.discount);
          fd.append("usage", data.usage);
          fd.append("start_date", data.start_date);
          fd.append("end_date", data.end_date);
          fd.append("title", data.title);
          fd.append("image", data.image);
          fd.append("totalAmount", data.totalAmount);
          fd.append("_method", "PUT");

          axios_api
            .post(`/coupons/${data.id}`, fd)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    deleteCoupon: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/coupons/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getCoupon({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("coupons")
            .then((response) => {
              commit("setCoupon", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateStatus: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post(`coupons/update/status/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    storeStaff: function ({ state, commit }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("staffs", data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              commit("addStaff", response.data.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateStaff: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data.fd.append("_method", "PUT");
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/staffs/${data.id}`, data.fd, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    deleteStaff: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/staffs/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getStaffs({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("staffs")
            .then((response) => {
              commit("setStaff", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getRoles({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("roles")
            .then((response) => {
              commit("setRoles", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    generateUser({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/staffs/generate-user/${data}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    updateStatusUser({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/staffs/update-status/${data}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },

    getSupports({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("support")
            .then((response) => {
              commit("setSupports", response.data.support);
              resolve(response.data.support);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateSupport: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .put(`/support/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getDeliveryCharges(context) {
      if (context.state.user) {
        return new Promise((resolve, reject) => {
          axios_api
            .get("/delivery-charges")
            .then((response) => {
              context.commit("setNormalDeliveryCharge", response.data.data);
              context.commit("setExpressDeliveryCharge", response.data.data);

              resolve(response.data.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getItemsToPickUp(context) {
      if (context.state.user) {
        return new Promise((resolve, reject) => {
          axios_api
            .get("/pick-up/")
            .then((response) => {
              context.commit("setDeliveryCharge", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateDeliveryCharges: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;

        return new Promise((resolve, reject) => {
          axios_api
            .post(`/delivery-option/update`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    // Vendor
    storeVendor({ state }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        axios_api.defaults.headers.common["Content-Type"] =
          "multipart/form-data";
        return new Promise((resolve, reject) => {
          
          let formdata = new FormData();
          formdata.append(
            "basicdetails",
            JSON.stringify(state.addVendor.basicdetails)
          );
          formdata.append(
            "business_address",
            JSON.stringify(state.addVendor.business_address)
          );
          formdata.append(
            "warehouse_address",
            JSON.stringify(state.addVendor.warehouse_address)
          );
          formdata.append(
            "return_address",
            JSON.stringify(state.addVendor.return_address)
          );
          formdata.append("logo", state.addVendor.basicdetails.logo);
          formdata.append("document", state.addVendor.basicdetails.document);
          axios_api
            .post("vendor", formdata)
            .then((response) => {
              resolve(response.data.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateProductStatus({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("/change-status/product", data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getCounts() {
      return new Promise((resolve, reject) => {
        axios_api
          .get("get-count")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateFeaturedBrand({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post(`change-status/brands`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getPickups({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("tags")
          .then((response) => {
            commit("setPickUp", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateVendor({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        axios_api.defaults.headers.common["Content-Type"] =
          "multipart/form-data";
        return new Promise((resolve, reject) => {
          let formdata = new FormData();
          formdata.append(
            "basicdetails",
            JSON.stringify(state.addVendor.basicdetails)
          );
          formdata.append(
            "business_address",
            JSON.stringify(state.addVendor.business_address)
          );
          formdata.append(
            "warehouse_address",
            JSON.stringify(state.addVendor.warehouse_address)
          );
          formdata.append(
            "return_address",
            JSON.stringify(state.addVendor.return_address)
          );
          formdata.append("logo", state.addVendor.basicdetails.logo);
          formdata.append("document", state.addVendor.basicdetails.document);
          formdata.append("_method", "PUT");
          axios_api
            .post(`vendor/${data.id}`, formdata)
            .then((response) => {
              resolve(response.data.data[0]);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getWithdrawRequests: function (context) {
      axios_api.defaults.headers.common["Authorization"] =
        "Bearer " + context.state.token;
      axios_api.defaults.headers.common["Content-Type"] = "multipart/form-data";
      return new Promise((resolve, reject) => {
        axios_api
          .get("withdraw-request")
          .then((response) => {
            context.commit("storeWithdrawRequests", response.data.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getWishLists: function (context) {
      axios_api.defaults.headers.common["Authorization"] =
        "Bearer " + context.state.token;
      axios_api.defaults.headers.common["Content-Type"] = "multipart/form-data";
      return new Promise((resolve, reject) => {
        axios_api
          .get("allWishLists")
          .then((response) => {
            context.commit("storeWishLists", response.data.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCategoryReport: function (context) {
      axios_api.defaults.headers.common["Authorization"] =
        "Bearer " + context.state.token;
      axios_api.defaults.headers.common["Content-Type"] = "multipart/form-data";
      return new Promise((resolve, reject) => {
        axios_api
          .get("categoryReport")
          .then((response) => {
            context.commit("storeCategoryReport", response.data.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAllContactMessages(context) {
      axios_api.defaults.headers.common["Authorization"] =
        "Bearer " + context.state.token;
      axios_api.defaults.headers.common["Content-Type"] = "multipart/form-data";
      return new Promise((resolve, reject) => {
        axios_api
          .get("contact-us")
          .then((response) => {
            context.commit("storeContactMessages", response.data.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteContactMessage(context, payload) {
      axios_api.defaults.headers.common["Authorization"] =
        "Bearer " + context.state.token;
      axios_api.defaults.headers.common["Content-Type"] = "multipart/form-data";
      return new Promise((resolve, reject) => {
        axios_api
          .delete(`contact-us/${payload}`)
          .then((response) => {
            context.commit("removeContactMessages", payload);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getSizes({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("sizes")
          .then((response) => {
            commit("setSize", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    storeSize: function ({ state, commit }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("sizes", data)
            .then((response) => {
              commit("addSize", response.data.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateSize: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data._method = "PUT";
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/sizes/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    deleteSize: function ({ state }, id) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/sizes/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getDealName: function ({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("dealname")
            .then((response) => {
              commit("setDealName", response.data);
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      }
    },
    updateDealName: function ({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data._method = "PUT";
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/dealname/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getUnreadNotification({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("unread/notifications")
          .then((response) => {
            commit("setNotification", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAllNotification() {
      return new Promise((resolve, reject) => {
        axios_api
          .get("notifications/all")
          .then((response) => {
            // commit("setNotification", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    markAsRead() {
      return new Promise((resolve, reject) => {
        axios_api
          .get("notifications/read")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getBrandSearch(_, param) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("brand/search/" + param)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTagSearch(_, param) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("tags/search/" + param)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getProductSearch(_, param) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("product/search/" + param)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getProductVariation({ state }, param) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("get-variations/" + param)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => reject(error));
        });
      }
    },

    customOrder({ state, commit }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("custom-order", data)
            .then((response) => {
              commit("addCustomOrder", response.data);
              resolve(response.data);
            })
            .catch((error) => reject(error));
        });
      }
    },

    getCustomOrder({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("all/custom-order")
            .then((response) => {
              commit("setCustomOrders", response.data);
              resolve(response.data);
            })
            .catch((error) => reject(error));
        });
      }
    },

    updateCustomOrderStatus: function ({ state }, data) {
      if (state.token) {
        // data._method="PUT";
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post(`update/custom-order`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
  },
  // modules: {
  //   vendor,
  // },
});
