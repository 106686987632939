<template>
  <v-container fluid>
    <v-card elevation="5">
      <v-card-title>
        Products

        <!-- <div class="title-vertical-divider"></div> -->
        <!-- <v-btn
          color="rgb(255, 34, 65)"
          dark
          rounded
          class="mb-2"
        >
          Add Products
        </v-btn> -->
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          @keyup.enter="searchs"
          append-icon="mdi-magnify"
          label="Search"
          placeholder="Press Enter to search"
          single-line
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="filtered.length > 0 || noItem ? filtered : products"
        item-key="id"
        class="elevation-0"
        @pagination="paginate"
        :footer-props="{ 'items-per-page-options': [10, 15, 20] }"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.category`]="{ item }">
          {{ item.category && item.category.name }}
        </template>
        <template v-slot:[`item.subcategory`]="{ item }">
          {{ item.subcategory && item.subcategory.name }}
        </template>
        <template v-slot:[`item.brand`]="{ item }">
          {{ item.brand && item.brand.name }}
        </template>
        <template v-slot:[`item.description`]="{ item }">
          {{ item.details ? item.details.description : "No Content Found" }}
        </template>
        <template v-slot:[`item.top_picks`]="{ item }">
          <v-switch
            @change="updateStatus(item, 'top_picks')"
            v-model="item.top_picks"
          ></v-switch>
        </template>
        <template v-slot:[`item.hot_picks`]="{ item }">
          <v-switch
            @change="updateStatus(item, 'hot_picks')"
            v-model="item.hot_picks"
          ></v-switch>
        </template>
        <template v-slot:[`item.top_rank`]="{ item }">
          <v-switch
            @change="updateStatus(item, 'top_rank')"
            v-model="item.top_rank"
          ></v-switch>
        </template>
        <template v-slot:[`item.new_arrival`]="{ item }">
          <v-switch
            @change="updateStatus(item, 'new_arrival')"
            v-model="item.new_arrival"
          ></v-switch>
        </template>
        <template v-slot:[`item.weekly_deal`]="{ item }">
          <v-switch
            @change="updateStatus(item, 'weekly_deal')"
            v-model="item.weekly_deal"
          ></v-switch>
        </template>
        <template v-slot:[`item.just_for_you`]="{ item }">
          <v-switch
            @change="updateStatus(item, 'just_for_you')"
            v-model="item.just_for_you"
          ></v-switch>
        </template>
        <template v-slot:[`item.is_active`]="{ item }">
          <v-switch
            @change="updateStatus(item, 'is_active')"
            v-model="item.is_active"
          ></v-switch>
        </template>
        <template v-slot:[`item.flash_deal`]="{ item }">
          <v-dialog
            v-if="item.flash_deal !== 1"
            :id="'flashDialog' + item.id"
            width="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" small dark v-bind="attrs" v-on="on">
                <v-icon x-small class="pr-2">mdi-flash-outline</v-icon>
                Add
              </v-btn>
            </template>
            <v-card>
              <v-card-title> Participate </v-card-title>
              <v-card-text>
                <v-form>
                  <v-switch
                    label="Is Featured"
                    v-model="flashDeal.is_featured"
                  ></v-switch>
                  <v-btn
                    color="primary"
                    @click="updateStatus(item, 'flash_deal_on')"
                    >Submit
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-btn
            v-else
            color="error"
            @click="updateStatus(item, 'flash_deal_off')"
            small
            dark
          >
            <v-icon x-small class="pr-2">mdi-flash-off</v-icon>
            Remove
          </v-btn>
        </template>

        <!-- <template v-slot:expanded-item="{ item }">
          <td :colspan="15">
            <v-row v-if="item.details" style="width: 75vw" class="mx-auto my-4">
              <v-tabs
                v-model="tab"
                dark
                background-color="gray darken-5"
                show-arrows
              >
                <v-tabs-slider class="secondary-color"></v-tabs-slider>

                <v-tab
                  v-for="(item, index) in otherfeatures(item.id)"
                  :key="index"
                >
                  {{ tabheaders[index] }}
                </v-tab>
              </v-tabs>
              <v-tabs-items
                v-model="tab"
                style="box-shadow: 1px 14px 15px 4px #d2d2d2 !important; z-index:1"
              >
                <v-tab-item
                  v-for="(element, index) in otherfeatures(item.id)"
                  :key="index"
                >
                  <v-card width="100vw">
                    <v-card-text>
                      <v-row v-if="element">
                        <v-col
                          cols="4"
                          v-show="element[key]"
                          v-for="key in Object.keys(element)"
                          :key="key"
                        >
                          <p class="mb-0">
                            {{ formatKey(key) + " : " + element[key] }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <h4 class="font-bold">Nothing to Display</h4>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-row>
            <v-row v-else class="justify-center align-center ">
              <h4>No Content</h4>
            </v-row>
          </td>
        </template> -->
        <template v-slot:item.description="{ item }">
          <v-dialog :id="'descriptionDialog' + item.id" width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                <v-icon size="16" class="pr-2">mdi-eye</v-icon>
                Description
              </v-btn>
            </template>

            <v-card>
              <v-card-title>Product Description</v-card-title>
              <v-divider></v-divider>
              <v-card-text class="py-2">
                <p v-html="item.details.description"></p>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.featured_image="{ item }">
          <v-dialog :id="'imageDialog' + item.id" width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                <v-icon size="16" class="pr-2">mdi-eye</v-icon>
                Image
              </v-btn>
            </template>

            <v-card>
              <v-card-title>Featured Image</v-card-title>
              <v-divider></v-divider>
              <v-card-text class="py-2">
                <img :src="item.featuredImage" width="100%" :alt="item.name" />
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-left align-items-center">
            <div class="my-2">
              <v-btn
                color="primary"
                class="mr-2"
                fab
                x-small
                dark
                @click="edit(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                color="error"
                fab
                x-small
                dark
                @click="deleteData(item.id)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
      <v-dialog v-model="editDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Product Details</span>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="edited.name"
                label="Product Name"
              ></v-text-field>
              <v-btn color="primary">Submit</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";

import { slugToNameMixin } from "../../../mixin/helperMixin";
import Swal from "sweetalert2";

export default {
  mixins: [slugToNameMixin],
  data() {
    return {
      search: "",
      flashDeal: {},
      fId: null,
      discount_type: ["Flat Cash", "Percentage"],
      selected: [],
      loading: false,
      noItem: false,
      expanded: [],
      pages: [],
      dialog: false,
      flashDialog: false,
      totalProducts: 24,
      name: "",
      editDialog: false,
      descriptionDialog: false,
      dialogDelete: false,
      imageDialog: false,
      edited: [{ id: "", name: "" }],
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Category", value: "category" },
        { text: "Sub Category", value: "subcategory" },
        { text: "Brand", value: "brand" },
        { text: "SKU", value: "sku" },
        { text: "Description", value: "description" },
        { text: "Featured Image", value: "featured_image" },
        { text: "Flash Deal", value: "flash_deal" },
        { text: "Trending", value: "top_picks" },
        { text: "Hot Picks", value: "hot_picks" },
        { text: "Top Rank", value: "top_rank" },
        { text: "Top Selling", value: "new_arrival" },
        { text: "Weekly Deal", value: "weekly_deal" },
        { text: "Just For You", value: "just_for_you" },
        { text: "Activated", value: "is_active" },
      ],
      tab: null,
      tabheaders: [
        "General Features",
        "Camera Features",
        "Connectivity Features",
        "Display Features",
        "Power Supply Features",
        "Processor Features",
        "Storage Features",
        "Warranty Features",
        "Other Features",
      ],
      filtered: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.getProducts();
      },
      deep: true,
    },

    products(val) {
      this.filtered = [...val];
    },
    search(val) {
      if (val === "") {
        this.filtered = [...this.products];
      }
    },
  },
  computed: {
    ...mapState({
      products: (state) => state.products,
    }),
    // filteredProducts: {
    //   get: function() {
    //     return this.products.map((item) => {
    //       return {
    //         ...item,
    //         category: item.category && item.category.name,
    //         subcategory: item.category && item.subcategory.name,
    //         top_picks: item.top_picks,
    //         brand: item.brand && item.brand.name,
    //         description: item.details
    //           ? item.details.description
    //           : "No Content Found",
    //       };
    //     });
    //   },
    //   set: function(newVal) {
    //     this.products = newVal;
    //     return newVal.map((item) => {
    //       return {
    //         ...item,
    //         category: item.category && item.category.name,
    //         subcategory: item.category && item.subcategory.name,
    //         top_picks: item.top_picks,
    //         brand: item.brand && item.brand.name,
    //         description: item.details
    //           ? item.details.description
    //           : "No Content Found",
    //       };
    //     });
    //   },
    // },
  },
  methods: {
    ...mapActions(["getProducts", "updateProductStatus"]),
    // otherfeatures(id) {
    //   const product = this.products.filter((item) => item.id === id)[0];
    //   let arr = [];
    //   const details = product.details;
    //   arr.push(details.generalFeatures);
    //   arr.push(details.cameraFeatures);
    //   arr.push(details.connectivityFeatures);
    //   arr.push(details.displayFeatures);
    //   arr.push(details.powerSupplyFeatures);
    //   arr.push(details.processorFeatures);
    //   arr.push(details.storageFeatures);
    //   arr.push(details.warrantyFeatures);
    //   arr.push(details.otherFeatures);

    //   return arr;
    // },
    paginate(e) {
      if (this.search === "" || this.search.indexOf(" ") >= 0) {
        if (this.$store.state.pages.product < e.page) {
          this.$store.commit("setProductPage", e.page);
          this.getProducts(e.page);
        }
      }
    },
    formatKey(item) {
      return this.slugtonameupper(item);
    },
    flashdeal(item) {
      if (item.flash_deal) {
        this.flashDialog = true;
        this.fId = item;
      } else {
        this.flashDialog = false;
        this.fId = item;
        this.updateStatus(item, "flash_deal");
      }
    },
    searchs() {
      if (this.search === "") {
        this.filtered = [...this.products];
      } else {
        this.loading = true;
        this.$store
          .dispatch("getProductSearch", this.search)
          .then((res) => {
            this.loading = false;
            this.filtered = res;
            if (res.length === 0) {
              this.noItem = true;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    updateStatus(item, type) {
      let data = { id: item.id };
      if (type === "top_picks") {
        item.top_picks = item.top_picks ? 1 : 0;
        data.top_picks = item.top_picks;
      }
      if (type === "hot_picks") {
        item.hot_picks = item.hot_picks ? 1 : 0;
        data.hot_picks = item.hot_picks;
      }
      if (type === "top_rank") {
        item.top_rank = item.top_rank ? 1 : 0;
        data.top_rank = item.top_rank;
      }
      if (type === "new_arrival") {
        item.new_arrival = item.new_arrival ? 1 : 0;
        data.new_arrival = item.new_arrival;
      }
      if (type === "weekly_deal") {
        item.weekly_deal = item.weekly_deal ? 1 : 0;
        data.weekly_deal = item.weekly_deal;
      }
      if (type === "just_for_you") {
        item.just_for_you = item.just_for_you ? 1 : 0;
        data.just_for_you = item.just_for_you;
      }
      if (type === "is_active") {
        item.is_active = item.is_active ? 1 : 0;
        data.is_active = item.is_active;
      }
      if (type === "flash_deal_on") {
        item.flashdeal = 1;
        data.id = item.id;
        data.type = "flashdeal";
        data.flash_deal = 1;
        data.is_featured = this.flashDeal.is_featured ? 1 : 0;
      }
      if (type === "flash_deal_off") {
        item.flashdeal = 0;
        data.id = item.id;
        data.type = "flashdeal";
        data.flash_deal = 0;
      }
      let temp = this.products.filter((product) => product.id === item.id)[0];
      this.editedIndex = this.products.indexOf(temp);
      this.updateProductStatus(data)
        .then((response) => {
          if (this.editedIndex >= 0) {
            Object.assign(this.products[this.editedIndex], response.data.data);
            this.$store.commit("setProducts", this.products);
          } else {
            let temp1 = this.filtered.filter(
              (product) => product.id === response.data.data.id
            )[0];
            let index = this.filtered.indexOf(temp1);
            Object.assign(this.filtered[index], response.data.data);
          }
          this.editedIndex = -1;
          this.flashDeal = {};
        })
        .catch((err) => {
          if (err.response.status == 404) {
            Swal.fire("Oops...", err.response.data, "error");
          } else {
            Swal.fire("Oops...", "Something went wrong!", "error");
            this.editedIndex = -1;
          }
        });
      this.flashDialog = false;
    },
  },
  created() {
    if (!this.products.length) {
      this.getProducts();
    }
  },
};
</script>
<style lang="scss">
.title-vertical-divider {
  border-left: 2px solid rgba(112, 110, 110, 0.753);
  height: 40px;
  margin: 0 1rem;
}
</style>
