<template>
  <custom-ordered-item
    :headers="headers"
    :type="'Completed Custom'"
    :orders="activeOrders"
  ></custom-ordered-item>
</template>

<script>
import CustomOrderedItem from "../../../components/Order/CustomOrderedItem";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { CustomOrderedItem },
  data() {
    return {
      headers: [
        { text: "S.N.", value: "sn" },
        { text: "Order Code", value: "order_code" },
        {
          text: "Customer Name",
          align: "start",
          sortable: false,
          value: "customer_name",
        },
        { text: "Phone", value: "customer_phone" },
        { text: "Email", value: "email" },
        // { text: "Delivery Type", value: "delivery_type" },
        { text: "Delivery Price", value: "delivery_price" },
        { text: "Total Price", value: "total_price" },
        { text: "Payment Method", value: "payment_type" },
        { text: "Ordered Date", value: "created_at" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  methods: {
    ...mapActions(["getCustomOrder"]),
  },
  computed: {
    ...mapGetters({
      activeOrders: "getCompletedCustomOrders",
    }),
  },

  created() {
    if (this.$store.state.customOrders.length === 0) {
      this.getCustomOrder().then((response) => {
        this.orders = response;
      });
    }
  },
};
</script>

<style></style>
