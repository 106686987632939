<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="getsno"
          class="elevation-5 pt-2"
        >
          <template #[`item.image`]="{ item }">
            <v-dialog :id="'dialog' + item.id" width="600">
              <template v-slot:activator="{ on, attrs }">
                <img
                  :src="getImage(item.image)"
                  alt="banner-image"
                  class="img-thumbnail my-2"
                  width="100"
                  height="60"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-card>
                <v-card-title style="background-color: #163691; color: white"
                  >Banner Image
                </v-card-title>
                <img :src="getImage(item.image)" width="100%" />
              </v-card>
            </v-dialog>
          </template>
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>Coupon Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    rounded
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Create New Coupon
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text class="pa-0">
                    <v-container>
                      <v-row>
                        <v-col cols="12" lg="12" sm="12" md="12">
                          <p><small>* indicates required fields</small></p>
                          <v-form ref="form" v-model="valid">
                            <v-col cols="12" class="pb-0">
                              <v-text-field
                                v-model="editedItem.title"
                                outlined
                                dense
                                label="Coupon Code Title *"
                                hint="Eg: Dashian Offer, Gazzab Offer"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pb-0">
                              <v-text-field
                                v-model="editedItem.code"
                                outlined
                                dense
                                label="Coupon Code *"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pb-0">
                              <v-select
                                v-model="editedItem.type"
                                :items="discountTypes"
                                label="Choose Discount Type *"
                                outlined
                                dense
                                :rules="[rules.required]"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" class="pb-0">
                              <v-text-field
                                v-model="editedItem.discount"
                                outlined
                                dense
                                type="number"
                                min="1"
                                label="Discount % or Price *"
                                :rules="[
                                  rules.required,
                                  rules.onlyPositiveNumbers,
                                ]"
                              ></v-text-field>
                              <v-text-field
                                v-model="editedItem.usage"
                                outlined
                                dense
                                type="number"
                                min="1"
                                label="Maximum Usage Allowed *"
                                hint="Please enter the number of customers which are allowed to use this coupon. 100 means 100 customers will use this coupon code"
                                :rules="[
                                  rules.required,
                                  rules.onlyPositiveNumbers,
                                ]"
                              ></v-text-field>
                              <v-text-field
                                v-model="editedItem.totalAmount"
                                outlined
                                dense
                                type="number"
                                min="1000"
                                label="Minimum Shopping Amount"
                                hint="Minimum shopping amount for a customer to use this coupon"
                                :rules="[
                                  rules.required,
                                  rules.onlyPositiveNumbers,
                                ]"
                              ></v-text-field>
                              <v-file-input
                                @change="imageSelect($event)"
                                v-model="retrieveBanner"
                                label="Banner Image *"
                                accept="image/*"
                                outlined
                                prepend-icon=""
                                dense
                                :rules="[rules.required]"
                              ></v-file-input>
                            </v-col>

                            <v-col cols="12" class="pb-0">
                              <v-textarea
                                v-model="editedItem.description"
                                outlined
                                dense
                                label="Description *"
                                :rules="[rules.required]"
                              ></v-textarea>
                              <v-dialog
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="date"
                                persistent
                                width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedItem.start_date"
                                    label="Pick Start Date *"
                                    prepend-icon=""
                                    outlined
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[rules.required]"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="editedItem.start_date"
                                  scrollable
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="modal = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn color="primary" @click="modal = false">
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-dialog>
                            </v-col>
                            <v-col cols="12" class="pb-0">
                              <v-dialog
                                ref="dialog"
                                v-model="modal1"
                                :return-value.sync="date"
                                persistent
                                width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedItem.end_date"
                                    label="Pick End Date *"
                                    prepend-icon=""
                                    outlined
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[
                                      rules.required,
                                      () =>
                                        editedItem.start_date <
                                          editedItem.end_date ||
                                        'The end date must be greater than start date.',
                                    ]"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="editedItem.end_date"
                                  scrollable
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="modal1 = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    @click="modal1 = false"
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-dialog>
                            </v-col>
                          </v-form>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="px-6"
                      dark
                      rounded
                      :disabled="!valid"
                      @click="save"
                    >
                      Save &amp; Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-text class="pt-4 text-center">
                    <v-icon color="error" size="50">mdi-close-circle</v-icon>
                    <h2 class="font-bold py-4">
                      Are you sure want to delete this Item?
                    </h2>
                    <h4 class="font-bold text-left">
                      Please be aware that you cannot revert this item in
                      future.
                    </h4>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      class="px-8"
                      rounded
                      dark
                      @click="deleteItemConfirm"
                      >OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="d-flex justify-left align-items-center">
              <div class="pb-4">
                <v-switch
                  @click="updateCouponStatus(item)"
                  v-model="item.status"
                  color="success"
                  hide-details
                ></v-switch>
              </div>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
import { imageMixin } from "../../../mixin/helperMixin";

export default {
  mixins: [imageMixin],

  data: () => ({
    valid: false,
    date: new Date().toISOString().substr(0, 10),
    date1: new Date().toISOString().substr(0, 10),
    loading: false,
    modal: false,
    modal1: false,
    dialog: false,
    dialogDelete: false,
    coupons: [],
    rules: {
      required: (v) => !!v || "This field is required.",
      onlyPositiveNumbers: (v) =>
        /^[1-9]\d*$|^$/.test(v) || "Enter Only Positive Number",
    },
    discountTypes: ["Percentage", "Flat Cash"],
    headers: [
      { text: "SN", value: "sn" },

      {
        text: "Coupon Title",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "Coupon Code", value: "code" },
      { text: "Discount Type", value: "type" },
      { text: "Banner", value: "image" },
      { text: "Discount", value: "discount" },
      { text: "Usage Remaining", value: "usage" },
      { text: "Minumum Shopping", value: "totalAmount" },
      { text: "Start Date", value: "start_date" },
      { text: "End Date", value: "end_date" },
      { text: "Status", value: "status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
  }),

  computed: {
    ...mapGetters({
      getAllCoupons: "getCoupons",
    }),
    getsno() {
      return this.coupons.map((item, index) => ({
        ...item,
        sn: index + 1,
      }));
    },

    formTitle() {
      return this.editedIndex === -1 ? "Add New Coupon" : "Edit Coupon Details";
    },

    retrieveBanner() {
      if (this.editedItem.image) {
        if (this.editedItem.image instanceof Blob) {
          return this.editedItem.image;
        }
        let ext1 = this.editedItem.image.split(".").pop();
        return new File(
          [this.editedItem.image],
          this.editedItem.code + "Banner." + ext1,
          {
            type: "image/*",
          }
        );
      } else {
        return [];
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions([
      "getCoupon",
      "storeCoupon",
      "updateCoupon",
      "deleteCoupon",
      "updateStatus",
    ]),

    formatDate(value) {
      return moment(value).format("MMMM DD YYYY");
    },
    imageSelect(event) {
      this.editedItem.image = event;
    },

    editItem(item) {
      let temp = this.coupons.filter((coupon) => coupon.id === item.id)[0];
      this.editedIndex = this.coupons.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      let temp = this.coupons.filter((coupon) => coupon.id === item.id)[0];
      this.editedIndex = this.coupons.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true;
      this.deleteCoupon(this.editedItem.id)
        .then(() => {
          this.coupons.splice(this.editedIndex, 1);
          this.$store.commit("setCoupon", this.coupons);
          Swal.fire("Success!", "Coupon Deleted Successfully!", "success");
          this.editedIndex = -1;
          this.loading = false;
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.editedIndex = -1;
          this.loading = false;
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        // this.editedIndex = -1
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1;
      });
    },
    updateCouponStatus(item) {
      item.status = item.status ? 1 : 0;
      let data = { id: item.id, status: item.status ? 1 : 0 };
      let temp = this.coupons.filter((coupon) => coupon.id === item.id)[0];
      this.editedIndex = this.coupons.indexOf(temp);
      let temp1 = Object.assign({}, this.coupons[this.editedIndex], item);
      this.updateStatus(data)
        .then(() => {
          Object.assign(this.coupons[this.editedIndex], temp1);
          this.$store.commit("setCategories", this.coupons);
          this.editedIndex = -1;
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.editedIndex = -1;
        });
    },

    save() {
      this.loading = true;
      if (this.editedIndex > -1) {
        let temp = Object.assign(
          {},
          this.coupons[this.editedIndex],
          this.editedItem
        );

        this.updateCoupon(this.editedItem)
          .then(() => {
            Object.assign(this.coupons[this.editedIndex], temp);
            this.$store.commit("setCoupon", this.coupons);
            this.editedIndex = -1;
            this.loading = false;
            this.$refs.form.reset();
            this.editedItem = Object.assign({}, this.defaultItem);
            Swal.fire("Success!", "Coupon Updated Successfully!", "success");
          })
          .catch(() => {
            this.editedIndex = -1;
            this.loading = false;
            this.editedItem = Object.assign({}, this.defaultItem);
            Swal.fire("Oops...", "Something went wrong!", "error");
            this.$refs.form.reset();
          });
      } else {
        this.storeCoupon(this.editedItem)
          .then(() => {
            this.loading = false;
            Swal.fire("Success!", "Coupon Added Successfully!", "success");
            this.$refs.form.reset();
            this.editedItem = Object.assign({}, this.defaultItem);
          })
          .catch(() => {
            this.loading = false;
            this.editedIndex = -1;
            this.editedItem = Object.assign({}, this.defaultItem);

            Swal.fire("Oops...", "Something went wrong!", "error");
            this.$refs.form.reset();
          });
      }
      this.close();
    },
  },
  mounted() {
    if (this.getAllCoupons.length === 0) {
      this.getCoupon().then((response) => {
        this.coupons = response;
      });
    } else {
      this.coupons = this.getAllCoupons;
    }
  },
};
</script>
<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0 !important;
}
</style>
