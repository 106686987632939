<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="teal"
        ></v-progress-linear>
        <v-data-table
          :headers="headers"
          :items="getCategory"
          :search="search"
          class="elevation-5 pt-2"
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>Categories Details</v-toolbar-title>

              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    small
                    class="ml-6"
                    v-bind="attrs"
                    v-on="on"
                    rounded
                  >
                    Create New Category
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text class="pa-0">
                    <v-container>
                      <v-form ref="form" v-model="valid">
                        <v-row>
                          <v-col cols="12" class="pb-0">
                            <v-text-field
                              v-model="editedItem.name"
                              :rules="[rules.required]"
                              outlined
                              dense
                              label="Category Name"
                            ></v-text-field>
                            <v-text-field
                              v-model="editedItem.title"
                              outlined
                              dense
                              label="Category Title"
                              :rules="[rules.required]"
                            ></v-text-field>
                            <v-textarea
                              v-model="editedItem.description"
                              outlined
                              dense
                              label="Category Description"
                            >
                            </v-textarea>
                            <v-text-field
                              v-model="editedItem.order"
                              type="number"
                              min="1"
                              step="1"
                              outlined
                              dense
                              label="Order"
                              :rules="[rules.required, rules.min]"
                            ></v-text-field>
                            <v-file-input
                              @change="imageSelect($event, 'banner')"
                              v-model="retrieveBanner"
                              label="Category Image"
                              accept="image/*"
                              outlined
                              prepend-icon=""
                              dense
                              :rules="[rules.required]"
                            ></v-file-input>
                            <v-file-input
                              @change="imageSelect($event, 'icon')"
                              v-model="retrieveIcon"
                              label="Category Icon"
                              accept="image/*"
                              outlined
                              prepend-icon=""
                              dense
                              :rules="[rules.required]"
                            ></v-file-input>
                            <v-file-input
                              @change="imageSelect($event, 'mobile-icon')"
                              v-model="retrivemobileIcon"
                              label="Mobile Icon"
                              accept="image/*"
                              outlined
                              prepend-icon=""
                              dense
                              :rules="[rules.required]"
                            ></v-file-input>
                            <!-- <v-switch
                              v-model="editedItem.featured"
                              label="Featured"
                            ></v-switch> -->
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="px-6 my-2"
                      rounded
                      :disabled="!valid"
                      dark
                      @click="save"
                    >
                      Save &amp; Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
              ></v-text-field>
              <v-dialog v-model="dialogDelete" max-width="400px">
                <v-card>
                  <v-card-text class="pt-4 text-center">
                    <v-icon color="error" size="50">mdi-close-circle</v-icon>
                    <h2 class="font-bold py-4">
                      Are you sure want to delete this Item?
                    </h2>
                    <h4 class="font-bold text-left">
                      Please be aware that you cannot revert this item in
                      future.
                    </h4>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      class="px-8"
                      rounded
                      dark
                      @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template #[`item.category_image`]="{ item }">
            <v-dialog :id="'dialog' + item.id" width="600">
              <template v-slot:activator="{ on, attrs }">
                <img
                  :src="getImage(item.banner)"
                  alt="banner-image"
                  class="img-thumbnail my-2"
                  width="100"
                  height="60"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-card>
                <v-card-title style="background-color: #163691; color: white"
                  >Category Image
                </v-card-title>
                <img :src="getImage(item.banner)" width="100%" />
              </v-card>
            </v-dialog>
          </template>
          <template #[`item.category_icon`]="{ item }">
            <v-dialog :id="'dialog' + item.id" width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar size="60" v-bind="attrs" v-on="on">
                  <img
                    :src="getImage(item.icon)"
                    alt="banner-image"
                    class="img-thumbnail my-2"
                    width="100"
                    height="60"
                    v-bind="attrs"
                    v-on="on"
                  />
                </v-avatar>
              </template>
              <v-card>
                <v-card-title style="background-color: #163691; color: white"
                  >Category Icon
                </v-card-title>
                <img :src="getImage(item.icon)" width="100%" />
              </v-card>
            </v-dialog>
          </template>
          <template #[`item.mobileicon`]="{ item }">
            <v-dialog :id="'dialog' + item.id" width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar size="60" v-bind="attrs" v-on="on">
                  <img
                    :src="getImage(item.mobileicon)"
                    alt="banner-image"
                    class="img-thumbnail my-2"
                    width="100"
                    height="60"
                    v-bind="attrs"
                    v-on="on"
                  />
                </v-avatar>
              </template>
              <v-card>
                <v-card-title style="background-color: #163691; color: white"
                  >Mobile Icon
                </v-card-title>
                <img :src="getImage(item.mobileicon)" width="100%" />
              </v-card>
            </v-dialog>
          </template>
          <template #[`item.featured`]="{ item }">
            <div class="d-flex justify-left align-items-center">
              <div class="pb-4">
                <v-switch
                  @click="updateIsFeatured(item)"
                  v-model="item.featured"
                  color="success"
                  hide-details
                ></v-switch>
              </div>
            </div>
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ formatDate(item.created_at) }}
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { dateMixin, imageMixin } from "../../../../mixin/helperMixin";
import Swal from "sweetalert2";

export default {
  mixins: [dateMixin, imageMixin],
  data: () => ({
    dialog: false,
    rules: {
      required: (v) => !!v || "This field is required.",
      min: (v) => (v && v > 0) || "Order must be greater than 0.",
    },
    valid: false,
    switch1: true,
    dialogDelete: false,
    search: "",
    loading: false,
    itemName: "",
    banner: "",
    icon: "",
    categories: [],
    headers: [
      { text: "S.N.", value: "sn" },
      {
        text: "Category Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Category Image", value: "category_image" },
      { text: "Category Icon", value: "category_icon" },
      { text: "Mobile Icon", value: "mobileicon" },
      { text: "Publish Homepage", value: "featured" },
      // { text: "Created Date", value: "created_at" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    current: new File(["image"], "image.txt", {
      type: "text/plain",
    }),

    editedItem: {
      name: "",
      created_at: "",
    },
    defaultItem: {},
  }),

  computed: {
    ...mapGetters({
      getAllCategories: "getCategories",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? "Add New Category"
        : "Edit Category Details";
    },

    retrieveIcon() {
      if (this.editedItem.icon) {
        if (this.editedItem.icon instanceof Blob) {
          return this.editedItem.icon;
        }
        let ext = this.editedItem.icon.split(".").pop();
        return new File(
          [this.editedItem.icon],
          this.editedItem.name + "Icon." + ext,
          {
            type: "image/*",
          }
        );
      } else {
        return "";
      }
    },

    retrivemobileIcon() {
      if (this.editedItem.mobileicon) {
        if (this.editedItem.mobileicon instanceof Blob) {
          return this.editedItem.mobileicon;
        }
        let ext = this.editedItem.mobileicon.split(".").pop();
        return new File(
          [this.editedItem.mobileicon],
          this.editedItem.name + "mobileicon." + ext,
          {
            type: "image/*",
          }
        );
      } else {
        return "";
      }
    },

    retrieveBanner() {
      if (this.editedItem.banner) {
        if (this.editedItem.banner instanceof Blob) {
          return this.editedItem.banner;
        }
        let ext1 = this.editedItem.banner.split(".").pop();
        return new File(
          [this.editedItem.banner],
          this.editedItem.name + "Banner." + ext1,
          {
            type: "image/*",
          }
        );
      } else {
        return "";
      }
    },

    getCategory() {
      return this.categories.map((item, index) => ({
        ...item,
        sn: index + 1,
        name: item.name,
        category_image: item.banner,
        category_icon: item.icon,
        featured: item.featured,
      }));
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions([
      "getCategories",
      "storeCategory",
      "updateCategory",
      "updateFeatured",
      "deleteCategory",
    ]),

    imageSelect(event, type) {
      if (type === "banner") this.editedItem.banner = event;
      else if (type === "icon") this.editedItem.icon = event;
      else this.editedItem.mobileicon = event;
    },
    getFileName(value) {
      return new File(["image"], value + ".jpg", {
        type: "text/plain",
      });
    },

    editItem(item) {
      let temp = this.categories.filter(
        (category) => category.id === item.id
      )[0];
      this.editedIndex = this.categories.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      let temp = this.categories.filter(
        (category) => category.id === item.id
      )[0];
      this.editedIndex = this.categories.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true;
      this.deleteCategory(this.editedItem.id)
        .then(() => {
          this.categories.splice(this.editedIndex, 1);
          this.$store.commit("setCategories", this.categories);
          this.loading = false;
          Swal.fire("Success!", "Category Deleted Successfully!", "success");
          this.editedIndex = -1;
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.loading = false;
          this.editedIndex = -1;
        });
      this.closeDelete();
    },
    updateIsFeatured(item) {
      item.featured = item.featured ? 1 : 0;
      let data = { id: item.id, featured: item.featured ? 1 : 0 };
      let temp = this.categories.filter(
        (category) => category.id === item.id
      )[0];
      this.editedIndex = this.categories.indexOf(temp);
      let temp1 = Object.assign({}, this.categories[this.editedIndex], item);
      this.updateFeatured(data)
        .then(() => {
          Object.assign(this.categories[this.editedIndex], temp1);
          this.$store.commit("setCategories", this.categories);
          this.editedIndex = -1;
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.editedIndex = -1;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = {};
        // this.editedIndex = -1
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    save() {
      let fd;
      fd = new FormData();
      if (typeof this.editedItem.banner === "object")
        fd.append("banner", this.editedItem.banner);
      fd.append("icon", this.editedItem.icon);
      if (typeof this.editedItem.mobileicon === "object")
        fd.append("mobileicon", this.editedItem.mobileicon);
      if (typeof this.editedItem.icon === "object")
        fd.append("icon", this.editedItem.icon);
      fd.append("name", this.editedItem.name);
      fd.append("title", this.editedItem.title);
      fd.append("description", this.editedItem.description);
      fd.append("order", this.editedItem.order);
      fd.append("featured", this.editedItem.featured ? 1 : 0);

      this.loading = true;
      if (this.editedIndex > -1) {
        let temp = Object.assign(
          {},
          this.categories[this.editedIndex],
          this.editedItem
        );

        this.updateCategory({ id: this.editedItem.id, fd: fd })
          .then(() => {
            Object.assign(this.categories[this.editedIndex], temp);
            this.$store.commit("setCategories", this.categories);
            this.editedIndex = -1;
            this.loading = false;
            this.$refs.form.reset();
            Swal.fire("Success!", "Category Updated Successfully!", "success");
          })
          .catch(() => {
            this.editedIndex = -1;
            this.loading = false;
            Swal.fire("Oops...", "Something went wrong!", "error");
          });
      } else {
        this.storeCategory(fd)
          .then(() => {
            this.loading = false;
            Swal.fire("Success!", "Category Added Successfully!", "success");
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.editedIndex = -1;
          })
          .catch(() => {
            this.loading = false;
            this.editedIndex = -1;
            Swal.fire("Oops...", "Something went wrong!", "error");
          });

        // this.categories.unshift(this.editedItem)
      }
      this.close();
    },
  },
  mounted() {
    if (this.getAllCategories.length === 0) {
      this.getCategories().then((response) => {
        this.categories = response;
      });
    } else {
      this.categories = this.getAllCategories;
    }
  },
};
</script>
<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0 !important;
}
</style>
