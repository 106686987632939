<template>
  <v-container fluid>
    <v-card elevation="5">
      <v-card-title
        >FAQ
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="editedIndex=-1" color="primary" small class="ml-6 px-6" rounded v-bind="attrs" v-on="on"
              >Create New FAQ
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-container>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-form ref="form" v-model="valid">
                      <v-text-field
                        v-model="editedItem.question"
                        outlined
                        dense
                        label="Question"
                        :rules="[rules.required]"
                      ></v-text-field>

                      <v-textarea
                        v-model="editedItem.answer"
                        outlined
                        dense
                        label="Write Answer"
                        :rules="[rules.required]"
                      >
                      </v-textarea>
                      <v-autocomplete
                        :items="type"
                        v-model="editedItem.user_type"
                        outlined
                        dense
                        label="Select Type"
                        :rules="[rules.required]"
                      ></v-autocomplete>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="primary" class="px-6 my-4" :disabled="!valid" rounded dark @click="save"> Save &amp; Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="getFaqs" :search="search">
        <template v-slot:item.answer="{ item }">
          <v-dialog :id="'descriptionDialog' + item.id" width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                <v-icon size="14" class="pr-2">mdi-eye</v-icon>
                Description
              </v-btn>
            </template>

            <v-card>
              <v-card-title>Answer </v-card-title>
              <v-card-text class="py-2">
                <p>{{ item.answer }}</p>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          <v-dialog v-model="dialogDelete" max-width="400px">
            <v-card>
                <v-card-text class="pt-4 text-center">
                  <v-icon color="error" size="50">mdi-close-circle</v-icon>
                  <h2 class="font-bold py-4">Are you sure want to delete this Item?</h2>
                  <h4 class="font-bold text-left">Please be aware that you cannot revert this item in future.</h4> 
                </v-card-text>
                <v-card-actions>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn color="error" class="px-8" rounded dark @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                </v-card-actions>
              </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      search: "",
      valid: false,
      rules: {
        required: (v) => !!v || "This field is required.",
      },
      dialogDelete: false,
      editedItem: {
        question: "",
        answer: "",
        user_type: "",
      },
      editedIndex: -1,
      dialog: false,
      faq: [],
      type: ["Seller", "Customer"],
      headers: [
        { text: "S.N.", value: "sn", sortable: false },
        {
          text: "Question",
          align: "start",
          sortable: false,
          value: "question",
        },
        { text: "Answer", value: "answer", sortable: false },
        { text: "Type", value: "user_type" },

        { text: "Action", value: "action" },
      ],
    };
  },
  computed: {
    getFaqs() {
      return this.faq.map((item, index) => ({
        ...item,
        sn: index + 1,
        question: item.question,
        answer: item.answer,
        user_type: item.user_type,
      }));
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add New Faq" : "Edit  Faq";
    },

    ...mapGetters({
      getAllFaq: "getFaq",
    }),
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  methods: {
    ...mapActions(["getFaq", "deleteFaq", "storeFaq", "updateFaq"]),
    editItem(item) {
      let temp = this.faq.filter((value) => value.id === item.id)[0];
      this.editedIndex = this.faq.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      let temp = this.faq.filter((value) => value.id === item.id)[0];
      this.editedIndex = this.faq.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let temp = this.faq.filter((value) => value.id === this.editedItem.id)[0];
      this.faq.splice(this.editedIndex, 1);
      this.deleteFaq(this.editedItem.id)
        .then(() => {
          this.$store.commit("setFaq", this.faq);
          this.editedIndex = -1;
        })
        .catch(() => {
          Object.assign(this.faq[this.editedIndex], temp);
          this.editedIndex = -1;
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1;
      });
    },

    save() {
      this.loading = true;
      if (this.editedIndex > -1) {
        let temp = Object.assign(
          {},
          this.faq[this.editedIndex],
          this.editedItem
        );
        this.updateFaq(this.editedItem)
          .then((response) => {
            Object.assign(this.faq[this.editedIndex], temp);
            this.$store.commit("setFaq", this.faq);
            this.editedIndex = -1;
            this.loading = false;
            console.log(response.status);
            this.$refs.form.reset()
            Swal.fire("Success!", "Faq Updated Successfully!", "success");
          })
          .catch((error) => {
            this.loading = false;
            this.editedIndex = -1;
            Swal.fire("Oops...", "Something went wrong!", "error");
            console.log(error.message);
            this.$refs.form.reset()
          });
      } else {
       
        this.storeFaq(this.editedItem)
          .then(() => {
            this.loading = false;
            Swal.fire("Success!", "Faq Added Successfully!", "success");
            this.$refs.form.reset()
            this.editedIndex = -1;
          })
          .catch((err) => {
            this.loading = false;
            this.editedIndex = -1;
            if(err.response.data.status==='failed'){
              Swal.fire("Oops...", err.response.data.msg, "error");
            }
            else{
               Swal.fire("Oops...", "Something went wrong!", "error");
            }
            this.$refs.form.reset()
            console.log(err.response);
          });
      }

      this.close();
    },
  },
  mounted() {
    if (this.getAllFaq.length === 0) {
      this.getFaq().then((response) => {
        this.faq = response;
      });
    } else {
      this.faq = this.$store.state.faq;
    }
  },
};
</script>

<style>
</style>