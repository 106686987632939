<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>Rejected Payments
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="rejectedWithdrawRequests"
              :search="search"
          >
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-left align-items-center">
                <div class="my-2">
                  <v-btn color="primary my-1" small dark @click="restorePayment(item.id)">
                    Restore
                  </v-btn>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import axios_api from "@/axios/axios_api";

export default {
  data() {
    return {
      dialog: false,
      search: '',
      headers: [
        {text: "S.N.", value: "sn"},
        {
          text: 'Vendor Name',
          align: 'start',
          sortable: false,
          value: 'vendorName',
        },
        {text: 'Request Amount', value: 'request_amount'},
        {text: 'Reject Reason', value: 'reason'},
        {text: 'Bank Name', value: 'bankName'},
        {text: 'Acc No.', value: 'bankAccountNumber'},
        {text: 'Branch', value: 'bankBranchName'},
        {text: 'Requested Date', value: 'requestedDate'},
        {text: 'Actions', value: 'actions'},
      ],
      rejectedWithdrawRequests: [],
    }
  },
  computed: {
    ...mapState(["withdrawRequests"]),
  },
  mounted() {
    if (this.withdrawRequests.length === 0) {
      this.$store.dispatch("getWithdrawRequests")
          .then(() => {
            this.rejectedWithdrawRequests = this.getRejectedWithdrawRequests();
          });
    } else {
      this.rejectedWithdrawRequests = this.getRejectedWithdrawRequests();
    }
  },
  methods: {
    ...mapGetters(["getRejectedWithdrawRequests"]),
    restorePayment(id) {
      let editedItem = this.withdrawRequests.filter(w => w.id === id)[0];
      let editedIndex = this.withdrawRequests.indexOf(editedItem);
      editedItem.status = 1;
      editedItem.reason = null;
      Object.assign(this.withdrawRequests[editedIndex], editedItem);
      this.rejectedWithdrawRequests = this.getRejectedWithdrawRequests();
      let formData = new FormData();
      formData.append("_method", "PATCH");
      formData.append("status", "1");
      axios_api.post(`withdraw-request/${id}`, formData);
    }
  },

}
</script>

<style>

</style>