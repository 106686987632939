<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="teal"
        ></v-progress-linear>
        <v-card elevation="5">
          <v-data-table
            v-if="staffsOtherThanRider"
            :headers="headers"
            :items="getStaffList"
            sort-by="calories"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Staff Details</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>

                <v-dialog v-model="dialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2 px-6"
                      rounded
                      v-bind="attrs"
                      v-on="on"
                    >
                      Add New Staff
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title>
                      {{ formTitle }}
                    </v-card-title>
                    <v-card-text>
                      <p><small>* indicates required fields</small></p>
                      <v-form ref="form" v-model="valid">
                        <v-row>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field
                              v-model="editedItem.name"
                              outlined
                              dense
                              label="Full Name *"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field
                              v-model="editedItem.phone"
                              outlined
                              dense
                              label="Phone Number *"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field
                              v-model="editedItem.email"
                              outlined
                              dense
                              label="Email Address"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field
                              v-model="editedItem.designation"
                              outlined
                              dense
                              label="Designation"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.dob"
                                  label="Pick Birthdate *"
                                  prepend-icon=""
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.dob"
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn color="primary" @click="modal = false">
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <v-file-input
                              v-model="editedItem.photo"
                              label="Profile Image"
                              outlined
                              prepend-icon=""
                              dense
                            ></v-file-input>
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <v-file-input
                              v-model="editedItem.citizenship"
                              label="Citizenship Image *"
                              outlined
                              prepend-icon=""
                              dense
                              :rules="[rules.required]"
                            ></v-file-input>
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <v-select
                              :items="roles"
                              v-model="editedItem.role_id"
                              label="Select Role *"
                              item-text="name"
                              item-value="id"
                              dense
                              outlined
                              :rules="[rules.required]"
                            ></v-select>
                          </v-col>
                          <p
                            class="ml-3"
                            style="font-size: 1rem; font-weight: 500"
                          >
                            Temporary Address
                          </p>
                          <v-row>
                            <v-col lg="4" sm="6" cols="6">
                              <v-text-field
                                v-model="country"
                                dense
                                outlined
                                disabled
                                label="Country *"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col lg="4" sm="6" cols="6">
                              <v-autocomplete
                                v-model="editedItem.state"
                                item-text="name"
                                item-value="name"
                                :items="states"
                                dense
                                outlined
                                label="State *"
                                :rules="[rules.required]"
                              ></v-autocomplete>
                            </v-col>
                            <v-col lg="4" sm="6" cols="6">
                              <v-autocomplete
                                v-model="editedItem.district"
                                :items="filteredDistrict"
                                dense
                                outlined
                                label="District *"
                                :rules="[rules.required]"
                              ></v-autocomplete>
                            </v-col>
                            <v-col lg="4" sm="6" cols="6">
                              <v-text-field
                                v-model="editedItem.city"
                                dense
                                outlined
                                label="City *"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col lg="4" sm="6" cols="6">
                              <v-autocomplete
                                v-model="editedItem.area"
                                :items="areas"
                                dense
                                outlined
                                label="Area *"
                                :rules="[rules.required]"
                              ></v-autocomplete>
                            </v-col>
                            <v-col lg="4" sm="6" cols="6">
                              <v-text-field
                                v-model.lazy="editedItem.street_address"
                                label="Street Name"
                                outlined
                                dense
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-row>
                        <v-btn
                          color="primary"
                          rounded
                          class="px-6 mt-4"
                          @click="save()"
                          :disabled="!valid"
                          >Save and Submit
                        </v-btn>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="400px">
                  <v-card>
                    <v-card-text class="pt-4 text-center">
                      <v-icon color="error" size="50">mdi-close-circle</v-icon>
                      <h2 class="font-bold py-4">
                        Are you sure want to delete this Item?
                      </h2>
                      <h4 class="font-bold text-left">
                        Please be aware that you cannot revert this item in
                        future.
                      </h4>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancel
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        class="px-8"
                        rounded
                        dark
                        @click="deleteItemConfirm"
                        >OK
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item.profile_image="{ item }">
              <v-dialog :id="'dialog' + item.id" width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar size="60" v-bind="attrs" v-on="on">
                    <img
                      :src="getImage(item.photo)"
                      alt="banner-image"
                      class="img-thumbnail my-2"
                      width="100"
                      height="60"
                    />
                  </v-avatar>
                </template>

                <v-card>
                  <v-card-title style="background-color: #163691; color: white"
                    >Staff Profile Image
                  </v-card-title>
                  <img :src="getImage(item.photo)" width="100%" />
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:item.citizenship="{ item }">
              <v-dialog :id="'dialog' + item.id" width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar size="60" v-bind="attrs" v-on="on">
                    <img
                      :src="getImage(item.citizenship)"
                      alt="banner-image"
                      class="img-thumbnail my-2"
                      width="100"
                      height="60"
                    />
                  </v-avatar>
                </template>

                <v-card>
                  <v-card-title style="background-color: #163691; color: white"
                    >Staff Citizenship
                  </v-card-title>
                  <img :src="getImage(item.citizenship)" />
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:item.status="{ item }">
              <div class="d-flex justify-left align-items-center">
                <div class="my-2">
                  <v-switch
                    v-if="item.user_id"
                    @change="updateUserStatus(item)"
                    v-model="item.status"
                    color="success"
                    hide-details
                  ></v-switch>

                  <p v-else>NA</p>
                </div>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-left align-items-center">
                <!-- <v-btn
                  color="success"
                  v-if="item.user_id"
                  disabled
                  class="my-auto"
                  fab
                  x-small
                  dark
                  @click="generateLogin(item.id)"
                >
                  <v-icon dark>mdi-history</v-icon>
                </v-btn>
                <v-btn
                  color="success"
                  v-else
                  class="my-auto"
                  fab
                  x-small
                  dark
                  @click="generateLogin(item.id)"
                >
                  <v-icon dark>mdi-history</v-icon>
                </v-btn> -->
                <div class="my-2">
                  <v-btn
                    color="blue"
                    class="mb-2"
                    fab
                    x-small
                    dark
                    @click="editItem(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    fab
                    x-small
                    dark
                    @click="deleteItem(item)"
                  >
                    <v-icon dark>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { dateMixin, imageMixin } from "../../../mixin/helperMixin";

import Swal from "sweetalert2";

export default {
  mixins: [dateMixin, imageMixin],

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      rules: {
        required: (v) => !!v || "This field is required.",
      },
      valid: false,
      date: new Date().toISOString().substr(0, 10),
      editedIndex: -1,
      modal: false,
      loading: false,
      search: "",
      roles: [],
      status: "",
      staffs: [{}],
      headers: [
        { text: "S.N.", value: "sn" },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Profile Image", value: "profile_image" },
        { text: "Phone No", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Address", value: "address" },
        { text: "Roles", value: "role" },
        { text: "Status", value: "status" },
        { text: "Citizenship", value: "citizenship" },
        { text: "Joined Date", value: "created_at" },
        { text: "Actions", value: "actions" },
      ],
      // states: ["1", "2", "Bagmati", "Gandaki", "Lumbini", "Karnali", "Sudhur Paschim"],
      // cities: ["Lagankhel", "Sankhamul", "Baneswor", "Thimi"],
      // districts: ["Lalitpur", "Bhaktapur", "Kathmandu"],
      areas: [
        "Inside Ring Road",
        "Outside Ring Road",
        "Inside Valley",
        "Outside Valley",
      ],
      country: "Nepal",

      addressCopy: null,
      editedItem: {
        dob: null,
        temp_address: {
          street_address: "",
          city: "",
          area: "",
          district: "",
          state: "",
        },

        defaultItem: {
          name: "",
          email: "",
          phone: "",
          dob: null,
          temp_address: {
            street_address: "",
            city: "",
            area: "",
            district: "",
            state: "",
          },
        },
      },
    };
  },
  watch: {
    addressCopy() {
      if (this.addressCopy) {
        this.copyAddress();
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    ...mapGetters({
      getAllStaffs: "getStaffs",
      getAllRoles: "getRoles",
    }),
    ...mapState({
      states: (state) => state.statesOfNepal,
      districts: (state) => state.districtsOfNepal,
    }),

    userStatus(user) {
      return !!user.email_verified_at;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add New Staff" : "Edit Staff Details";
    },
    staffsOtherThanRider() {
      return this.staffs.filter(
        (item) => item.roles && item.roles.slug !== "rider"
      );
    },
    getStaffList() {
      return this.staffsOtherThanRider.map((item, index) => ({
        ...item,
        sn: index + 1,
        name: item.name,
        user_id: item.user_id,
        phone: item.phone,
        email: item.email,
        address:
          item.address && item.address.area
            ? item.address.area + "," + item.address.city
            : item.area + "," + item.city,
        role: item.roles && item.roles.name,
        state:
          item.address && item.address.state ? item.address.state : item.state,
        city: item.address && item.address.city ? item.address.city : item.city,
        district:
          item.address && item.address.district
            ? item.address.district
            : item.district,
        area: item.address && item.address.area ? item.address.area : item.area,
        street_address:
          item.address && item.address.street_address
            ? item.address.street_address
            : item.street_address,
        status: !!(item.user && item.user.email_verified_at),
      }));
    },
    filteredDistrict() {
      if (this.editedItem.state) {
        return this.districts.filter(
          (item) => item.state === this.editedItem.state
        )[0]["districts"];
      } else {
        return [];
      }
    },
  },

  methods: {
    ...mapActions([
      "getStaffs",
      "storeStaff",
      "updateStaff",
      "deleteStaff",
      "getRoles",
      "generateUser",
      "updateStatusUser",
    ]),

    updateUserStatus(item) {
      let temp = this.staffs.filter((staff) => staff.id === item.id)[0];
      this.editedIndex = this.staffs.indexOf(temp);
      this.updateStatusUser(item.id)
        .then((response) => {
          let value;
          console.log(typeof response.data);
          if (typeof response.data === "object") {
            value = null;
          } else {
            value = response.data;
          }
          this.staffs[this.editedIndex].user.email_verified_at = value;
          console.log(this.staffs[this.editedIndex].user);
          this.$store.commit("setStaff", this.staffs);
          this.editedIndex = -1;
          this.$refs.form.reset();
          console.log(response.status);
        })
        .catch((error) => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.editedIndex = -1;
          console.log(error.response.data);
        });
    },

    getRider() {
      return this.roles.filter((role) => role.name === "Rider")[0];
    },
    getImage(path) {
      if (path instanceof Blob) return URL.createObjectURL(path);
      else return path;
    },

    imageSelect(event, type) {
      if (type === "photo") this.editedItem.photo = event;
      else this.editedItem.citizenship = event;
    },

    editItem(item) {
      let temp = this.staffs.filter((staff) => staff.id === item.id)[0];
      this.editedIndex = this.staffs.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      // console.log(this.editedItem);
      this.dialog = true;
    },

    deleteItem(item) {
      let temp = this.staffs.filter((staff) => staff.id === item.id)[0];
      this.editedIndex = this.staffs.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      console.log(this.editedItem);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true;

      this.deleteStaff(this.editedItem.id)
        .then(() => {
          this.staffs.splice(this.editedIndex, 1);
          this.$store.commit("setStaff", this.staffs);
          this.loading = false;
          Swal.fire("Success!", "Staff Deleted Successfully!", "success");
          this.editedIndex = -1;
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.loading = false;
          this.editedIndex = -1;
        });
      this.closeDelete();
    },
    generateLogin(id) {
      this.generateUser(id)
        .then(() => {
          Swal.fire("Success!", "Login Generated Successfully!", "success");
          this.editedIndex = -1;
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.editedIndex = -1;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1;
      });
    },

    save() {
      let fd;
      fd = new FormData();
      fd.append("name", this.editedItem.name);
      fd.append("email", this.editedItem.email);
      fd.append("phone", this.editedItem.phone);
      fd.append("dob", this.editedItem.dob);
      fd.append("designation", this.editedItem.designation);
      fd.append("photo", this.editedItem.photo);
      fd.append("citizenship", this.editedItem.citizenship);
      fd.append("state", this.editedItem.state);
      fd.append("district", this.editedItem.district);
      fd.append("city", this.editedItem.city);
      fd.append("area", this.editedItem.area);
      fd.append("street_address", this.editedItem.street_address);

      if (typeof this.editedItem.role_id === "object")
        fd.append("role_id", this.editedItem.role_id.id);
      else fd.append("role_id", this.editedItem.role_id);

      this.loading = true;
      if (this.editedIndex > -1) {
        console.log(this.editedItem);
        let temp = Object.assign(
          {},
          this.staffs[this.editedIndex],
          this.editedItem
        );

        this.updateStaff({ id: this.editedItem.id, fd: fd })
          .then((response) => {
            Object.assign(this.staffs[this.editedIndex], temp);
            this.$store.commit("setStaff", this.staffs);
            this.editedIndex = -1;
            this.loading = false;
            this.$refs.form.reset();
            Swal.fire("Success!", "Staff Updated Successfully!", "success");
            console.log(response.status);
          })
          .catch((error) => {
            this.editedIndex = -1;
            this.loading = false;
            Swal.fire("Oops...", "Something went wrong!", "error");
            console.log(error.response.data);
          });
      } else {
        this.storeStaff(fd)
          .then(() => {
            this.loading = false;
            Swal.fire("Success!", "Staff Added Successfully!", "success");
            this.editedIndex = -1;
            this.$refs.form.reset();
          })
          .catch(() => {
            this.loading = false;
            this.editedIndex = -1;
            Swal.fire("Oops...", "Something went wrong!", "error");
          });

        // this.staffs.unshift(this.editedItem)
      }
      this.close();
    },
  },
  mounted() {
    if (this.getAllRoles.length === 0) {
      this.getRoles().then((response) => {
        this.roles = response;
      });
    } else {
      this.roles = this.getAllRoles;
    }
    if (this.getAllStaffs.length === 0) {
      this.getStaffs().then((response) => {
        this.staffs = response;
      });
    } else {
      this.staffs = this.getAllStaffs;
    }
  },
};
</script>

<style>
</style>