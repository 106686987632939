<template>
  <v-container fluid>
    <v-card elevation="5">
      <v-card-title
        >Customer Reviews
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="getReview" :search="search">
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
        <template v-slot:item.description="{ item }">
          <v-dialog :id="'descriptionDialog' + item.id" width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                <v-icon size="14" class="pr-2">mdi-eye</v-icon>
                Description
              </v-btn>
            </template>

            <v-card>
              <v-card-title>Review Description </v-card-title>
              <v-divider>s</v-divider>
              <v-card-text class="py-2">
                <p>{{ item.review }}</p>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
          <v-dialog v-model="dialogDelete" max-width="400px">
            <v-card>
              <v-card-text class="pt-4 text-center">
                <v-icon color="error" size="50">mdi-close-circle</v-icon>
                <h2 class="font-bold py-4">
                  Are you sure want to delete this Item?
                </h2>
                <h4 class="font-bold text-left">
                  Please be aware that you cannot revert this item in future.
                </h4>
              </v-card-text>
              <v-card-actions>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  class="px-8"
                  rounded
                  dark
                  @click="deleteItemConfirm"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { dateMixin, imageMixin } from "../../../../mixin/helperMixin";

export default {
  mixins: [dateMixin, imageMixin],

  data() {
    return {
      search: "",
      dialogDelete: false,
      headers: [
        { text: "S.N.", value: "sn" },
        {
          text: "Customer Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Product Name",
          sortable: false,
          value: "product_name",
        },
        { text: "Seller Name", value: "seller_name" },
        { text: "Rating", value: "rating" },
        { text: "Description", value: "description" },
        { text: "Date", value: "created_at" },
        { text: "Action", value: "action" },
      ],
    };
  },

  computed: {
    getReview() {
      // console.log(this.reviews)
      return this.reviews.map((item, index) => ({
        ...item,
        sn: index + 1,
        name: item.user,
        product_name: item.product.name,
        seller_name: item.product.vendor.business_name,
        reply: item.reply,

        // banner_image:item.banner,
      }));
    },

    ...mapState({
      reviews: (state) => state.reviews,
    }),
  },
  methods: {
    ...mapActions(["getReviews", "deleteReview"]),
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    deleteItem(item) {
      let temp = this.reviews.filter((review) => review.id === item.id)[0];
      this.editedIndex = this.reviews.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let temp = this.reviews.filter(
        (review) => review.id === this.editedItem.id
      )[0];
      this.deleteReview(this.editedItem.id)
        .then(() => {
          this.reviews.splice(this.editedIndex, 1);
          this.$store.commit("setReviews", this.reviews);
          this.editedIndex = -1;
        })
        .catch(() => {
          Object.assign(this.reviews[this.editedIndex], temp);
          this.editedIndex = -1;
        });
      this.closeDelete();
    },
  },
  created() {
    this.getReviews();
  },
};
</script>

<style></style>
