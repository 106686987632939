import moment from "moment";

export const dateMixin = {
  methods: {
    formatDate(value) {
      let date = new Date(value);
      return moment(date).format("MMMM DD YYYY");
    }
  }
};
export const imageMixin = {
  methods: {
    getImage(path) {
      if (path instanceof Blob) return URL.createObjectURL(path);
      else return path;
    }
  }
};
export const AddressMixin = {
  methods: {
    getAddress(item) {
      return item.street_address
        ? item.street_address + "," + item.area + "," + item.city + ","
        : "" + item.area + "," + item.city;
    }
  }
};
export const slugToNameMixin = {
  methods: {
    slugtonameupper(slug) {
      const name = slug.split("_");
      let finalName = "";
      name.forEach(element => {
        finalName = finalName + element.toUpperCase() + " ";
      });
      return finalName;
    }
  }
};
