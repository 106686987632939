<template>
  <div class="bordered-box">
    <h3 class="ml-3">Basic Details</h3>
    <small>[ * indicates required fields ]</small>
    <v-divider class="my-4"></v-divider>
    <v-form v-model="valid" ref="form">
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model.lazy="companyDetails.owner_name"
            outlined
            dense
            label="Owner Name*"
            @change="updateName"
            required
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="companyDetails.business_name"
            v-if="fillable === false"
            readonly
            outlined
            dense
            label="Business Name*"
            required
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            v-model="companyDetails.business_name"
            v-else
            outlined
            dense
            label="Business Name*"
            required
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-file-input
            v-model="companyDetails.logo"
            outlined
            prepend-icon=""
            dense
            label="Logo*"
            accept="image/*"
            @change="fileUpload($event, 'logo')"
            :rules="[rules.required, ...rules.imageRules]"
            required
          >
          </v-file-input>
        </v-col>
        <v-col cols="6">
          <v-file-input
            v-model="companyDetails.document"
            outlined
            prepend-icon=""
            dense
            label="Company Resistration Document"
            accept="image/*"
            @change="fileUpload($event, 'doc')"
            :rules="[...rules.imageRules]"
          >
          </v-file-input>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.lazy="companyDetails.phone"
            outlined
            dense
            label="Phone*"
            @change="updatePhone"
            :rules="[rules.required, rules.phone]"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="companyDetails.email"
            outlined
            dense
            label="Business Email*"
            required
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="companyDetails.title"
            outlined
            dense
            label="Meta Title*"
            required
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="companyDetails.description"
            outlined
            dense
            label="Description*"
            required
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-if="fillable === false"
            readonly
            v-model="companyDetails.pan"
            outlined
            dense
            required
            placeholder="Should be unique (Paste the phone no if dont have pan)"
            label="PAN"
          ></v-text-field>
          <v-text-field
            v-else
            v-model="companyDetails.pan"
            outlined
            dense
            placeholder="Should be unique (Paste the phone no if dont have pan)"
            required
            label="PAN*"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="companyDetails.office_phone"
            outlined
            dense
            label="Office Phone"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <stepper-buttons @updateStep="updateStep"></stepper-buttons>
  </div>
</template>
<script>
import StepperButtons from "./common/StepperButtons.vue";

export default {
  props: {
    companyDetails: {
      type: Object,
      default: () => {},
    },
    fillable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    StepperButtons,
  },
  data() {
    return {
      valid: false,
      rules: {
        required: (v) => !!v || "This field is required.",
        imageRules: [
          (value) =>
            !value ||
            value.size < 500000 ||
            "Image size should be less than 500 KB!",
        ],
        phone: (v) =>
          (v && v.length >= 10 && v.length <= 10) ||
          "Phone number must be of 10 characters only",
      },
      // companyDetails: {
      //   owner_name: "",
      //   business_name: "",
      //   logo: null,
      //   phone: "",
      //   title: "",
      //   description: "",
      //   email: "",
      //   pan: "",
      //   document: null,
      //   office_phone: "",
      // },
    };
  },
  methods: {
    updateStep() {
      if (this.$refs.form.validate()) {
        this.$emit("updateStep");
        const data = this.companyDetails;
        data.type = null;
        this.$store.commit("setVendorDetails", data);
      }
    },
    updateName() {
      this.$emit("updatename", this.companyDetails.owner_name);
    },
    updatePhone() {
      this.$emit("updatephone", this.companyDetails.phone);
    },
    fileUpload(file, type) {
      if (type === "logo") {
        this.logo = file;
      } else {
        this.document = file;
      }
    },
  },
};
</script>
