var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bordered-box"},[_c('h3',{staticClass:"ml-3"},[_vm._v("Addresses")]),_c('v-divider',{staticClass:"my-4"}),(_vm.hide)?_c('div',{staticClass:"text-center "},[_c('v-icon',{attrs:{"size":"64","color":"green"}},[_vm._v("mdi-check-circle")]),_c('br'),_vm._m(0)],1):_c('v-stepper',{model:{value:(_vm.addressStep),callback:function ($$v) {_vm.addressStep=$$v},expression:"addressStep"}},[_c('v-stepper-header',_vm._l((_vm.address_details),function(item,index){return _c('div',{key:index},[_c('v-stepper-step',{attrs:{"complete":_vm.addressStep > 1,"step":index + 1}},[_vm._v(" "+_vm._s(_vm.format(item))+" ")]),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(index < _vm.address_details.length),expression:"index < address_details.length"}]})],1)}),0),_c('v-stepper-items',_vm._l((_vm.address_details),function(item,index){return _c('v-stepper-content',{key:index,attrs:{"step":index == 0 ? 1 : index == 1 ? 2 : 3}},[_c('address-form',{attrs:{"type":_vm.format(item),"country":index == 0
              ? item.business_address.country
              : index == 1
              ? item.warehouse_address.country
              : item.return_address.country,"district":index == 0
              ? item.business_address.district
              : index == 1
              ? item.warehouse_address.district
              : item.return_address.district,"state":index == 0
              ? item.business_address.state
              : index == 1
              ? item.warehouse_address.state
              : item.return_address.state,"city":index == 0
              ? item.business_address.city
              : index == 1
              ? item.warehouse_address.city
              : item.return_address.city,"area":index == 0
              ? item.business_address.area
              : index == 1
              ? item.warehouse_address.area
              : item.return_address.area,"street_address":index == 0
              ? item.business_address.street_address
              : index == 1
              ? item.warehouse_address.street_address
              : item.return_address.street_address,"propname":_vm.name,"propphone":_vm.phone,"activateBusinessCopy":index > 0,"activateWarehouseCopy":index > 1},on:{"storeaddress":function($event){return _vm.storeaddress(index)}}}),_c('div',{staticClass:"mt-4"})],1)}),1)],1),_c('div',{staticClass:"mt-5"}),_c('stepper-buttons',{attrs:{"finish":true,"loading":_vm.loading,"ready":_vm.readytoSubmit},on:{"updateStep":_vm.updateStep,"submit":_vm.submit,"update":_vm.update}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('i',[_vm._v("All data has been completed. Please proceed to "),_c('strong',[_vm._v("FINISH AND UPLOAD")]),_vm._v(" to save.")])])}]

export { render, staticRenderFns }