<template>
  <v-container class="py-12">
    <!-- Products -->
    <v-row>
      <v-col lg="8" class="mt-4">
        <v-row>
          <v-col cols="12" lg="6">
            <v-card class="mx-auto" elevation="5">
              <v-card class="pa-4 dashboard-card-inner" max-width="100">
                <v-icon color="#fff" large>mdi-archive</v-icon>
              </v-card>
              <div class="px-4 pt-4 text-right">
                <h5>Total Products</h5>
                <h2>{{ productCount }}</h2>
              </div>
              <div class="text-left px-4 pb-4">
                <router-link
                  to="/products/all-products"
                  class="text-decoration-none"
                  style="font-size: 0.8rem"
                  >Manage Product
                  <v-icon small color="primary">mdi-chevron-right</v-icon>
                </router-link>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6">
            <v-card class="mx-auto" elevation="5">
              <v-card class="pa-4 dashboard-card-inner" max-width="100">
                <v-icon color="#fff" large>mdi-domain</v-icon>
              </v-card>
              <div class="px-4 pt-4 text-right">
                <h5>Total Brands</h5>
                <h2>{{ brandsCount }}</h2>
              </div>
              <div class="text-left px-4 pb-4">
                <router-link
                  to="/products/brands"
                  class="text-decoration-none"
                  style="font-size: 0.8rem"
                  >Manage Brands
                  <v-icon small color="primary">mdi-chevron-right</v-icon>
                </router-link>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6" class="mt-12">
            <v-card class="mx-auto" elevation="5">
              <v-card class="pa-4 dashboard-card-inner" max-width="100">
                <v-icon color="#fff" large>mdi-tag</v-icon>
              </v-card>
              <div class="px-4 pt-4 text-right">
                <h5>Product Tags</h5>
                <h2>{{ tagsCount }}</h2>
              </div>
              <div class="text-left px-4 pb-4">
                <router-link
                  to="/products/tags"
                  class="text-decoration-none"
                  style="font-size: 0.8rem"
                  >Manage Product Tags
                  <v-icon small color="primary">mdi-chevron-right</v-icon>
                </router-link>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6" class="mt-12">
            <v-card class="mx-auto" elevation="5">
              <v-card class="pa-4 dashboard-card-inner" max-width="100">
                <v-icon color="#fff" large>mdi-message-draw</v-icon>
              </v-card>
              <div class="px-4 pt-4 text-right">
                <h5>Total Reviews</h5>
                <h2>{{ reviewCount }}</h2>
              </div>
              <div class="text-left px-4 pb-4">
                <router-link
                  to="/products/reviews"
                  class="text-decoration-none"
                  style="font-size: 0.8rem"
                  >View Reviews
                  <v-icon small color="primary">mdi-chevron-right</v-icon>
                </router-link>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="4">
        <v-card class="pa-4" elevation="5">
          <pie-chart
            :categoryCount="categoryCount"
            :subCategoryCount="subCategoryCount"
            :subSubCategoryCount="subSubCategoryCount"
          ></pie-chart>
        </v-card>
      </v-col>
    </v-row>

    <!-- Sales Chart -->
    <v-row>
      <!-- <v-col cols="12">
        <v-card elevation="5">
          <sales-line-chart
            style="width:100%; position: relative; height: 300px !important"
          ></sales-line-chart>
        </v-card>
      </v-col> -->
      <v-col lg="12">
        <v-card class="pa-4" elevation="5">
          <line-chart
            :data="orderChart"
            style="width:100%; position: relative; height: 300px !important"
          ></line-chart>
        </v-card>
      </v-col>
    </v-row>

    <!-- Orders -->
    <v-row>
      <v-col lg="8" class="mt-4">
        <v-row>
          <v-col cols="12" lg="6">
            <v-card class="mx-auto" elevation="5">
              <v-card class="pa-4 dashboard-card-inner" max-width="100">
                <v-icon color="#fff" large>mdi-cart-arrow-right</v-icon>
              </v-card>
              <div class="px-4 pt-4 text-right">
                <h5>Total Orders</h5>
                <h2>{{ orderCount + customOrderCount }}</h2>
              </div>
              <div class="text-left px-4 pb-4">
                <router-link
                  to="/order/active-orders"
                  class="text-decoration-none"
                  style="font-size: 0.8rem"
                  >View Active Orders
                  <v-icon small color="primary">mdi-chevron-right</v-icon>
                </router-link>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6">
            <v-card class="mx-auto" elevation="5">
              <v-card class="pa-4 dashboard-card-inner" max-width="100">
                <v-icon color="#fff" large>mdi-cart-minus</v-icon>
              </v-card>
              <div class="px-4 pt-4 text-right">
                <h5>Pending Orders</h5>
                <h2>{{ pendingOrders + pendingCustomOrders }}</h2>
              </div>
              <div class="text-left px-4 pb-4">
                <router-link
                  to="/order/pending-orders"
                  class="text-decoration-none"
                  style="font-size: 0.8rem"
                  >View Pending Orders
                  <v-icon small color="primary">mdi-chevron-right</v-icon>
                </router-link>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6" class="mt-12">
            <v-card class="mx-auto" elevation="5">
              <v-card class="pa-4 dashboard-card-inner" max-width="100">
                <v-icon color="#fff" large>mdi-cart-check</v-icon>
              </v-card>
              <div class="px-4 pt-4 text-right">
                <h5>Completed Orders</h5>
                <h2>{{ completedOrders + completedCustomOrders }}</h2>
              </div>
              <div class="text-left px-4 pb-4">
                <router-link
                  to="/order/completed-orders"
                  class="text-decoration-none"
                  style="font-size: 0.8rem"
                  >View Completed Orders
                  <v-icon small color="primary">mdi-chevron-right</v-icon>
                </router-link>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6" class="mt-12">
            <v-card class="mx-auto" elevation="5">
              <v-card class="pa-4 dashboard-card-inner" max-width="100">
                <v-icon color="#fff" large>mdi-cart-remove</v-icon>
              </v-card>
              <div class="px-4 pt-4 text-right">
                <h5>Cancelled Orders</h5>
                <h2>{{ cancelledOrders + cancelledCustomOrders }}</h2>
              </div>
              <div class="text-left px-4 pb-4">
                <router-link
                  to="/order/cancelled-orders"
                  class="text-decoration-none"
                  style="font-size: 0.8rem"
                  >View Cancelled Orders
                  <v-icon small color="primary">mdi-chevron-right</v-icon>
                </router-link>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="4">
        <v-card class="pa-3" elevation="5">
          <customer-bar-chart
            :cdata="customerChart"
            style="width:100%; position: relative; height: 300px !important"
          ></customer-bar-chart>
        </v-card>
      </v-col>
    </v-row>

    <!-- Users -->
    <v-row class="my-5">
      <v-col lg="8">
        <v-card class="pa-3" elevation="5">
          <bar-chart
            :sdata="sellerChart"
            style="width:100%; position: relative;  height: 300px !important"
          ></bar-chart>
        </v-card>
      </v-col>

      <v-col lg="4" class="my-5">
        <v-col cols="12">
          <v-card class="mx-auto" elevation="5">
            <v-card class="pa-4 dashboard-card-inner" max-width="100">
              <v-icon color="#fff" large>mdi-store</v-icon>
            </v-card>
            <div class="px-4 pt-4 text-right">
              <h5>Total Vendors</h5>
              <h2>{{ sellerCount }}</h2>
            </div>
            <div class="text-left px-4 pb-4">
              <router-link
                to="/seller/pending-seller"
                class="text-decoration-none"
                style="font-size: 0.8rem"
                >Manage Vendors
                <v-icon small color="primary">mdi-chevron-right</v-icon>
              </router-link>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" class="mt-8">
          <v-card class="mx-auto" elevation="5">
            <v-card class="pa-4 dashboard-card-inner" max-width="100">
              <v-icon color="#fff" large>mdi-account-multiple</v-icon>
            </v-card>
            <div class="px-4 pt-4 text-right">
              <h5>Total Buyers</h5>
              <h2>{{ allBuyers }}</h2>
            </div>
            <div class="text-left px-4 pb-4">
              <router-link
                to="/all-customers"
                class="text-decoration-none"
                style="font-size: 0.8rem"
                >View Customers
                <v-icon small color="primary">mdi-chevron-right</v-icon>
              </router-link>
            </div>
          </v-card>
        </v-col>
      </v-col>
    </v-row>

    <!-- Payments -->
    <!-- <v-row>
      <v-col lg="12">
        <v-row>
          <v-col cols="12" lg="4">
            <v-card class="mx-auto" elevation="5">
              <v-card class="pa-4 dashboard-card-inner" max-width="100">
                <v-icon color="#fff" large>mdi-briefcase-download</v-icon>
              </v-card>
              <div class="px-4 pt-4 text-right">
                <h5>Withdraw Request</h5>
                <h2>Rs. 25000</h2>
              </div>
              <div class="text-left px-4 pb-4">
                <router-link
                  to="/payments/withdraw-request"
                  class="text-decoration-none"
                  style="font-size: 0.8rem"
                >
                  Manage Withdraw Request
                  <v-icon small color="primary">mdi-chevron-right</v-icon>
                </router-link>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4">
            <v-card class="mx-auto" elevation="5">
              <v-card class="pa-4 dashboard-card-inner" max-width="100">
                <v-icon color="#fff" large>mdi-briefcase-minus</v-icon>
              </v-card>
              <div class="px-4 pt-4 text-right">
                <h5>Pending Payments</h5>
                <h2>Rs. 18000</h2>
              </div>
              <div class="text-left px-4 pb-4">
                <router-link
                  to="/payments/pending-payments"
                  class="text-decoration-none"
                  style="font-size: 0.8rem"
                >
                  Manage Pending Payments
                  <v-icon small color="primary">mdi-chevron-right</v-icon>
                </router-link>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4">
            <v-card class="mx-auto" elevation="5">
              <v-card class="pa-4 dashboard-card-inner" max-width="100">
                <v-icon color="#fff" large>mdi-briefcase-check</v-icon>
              </v-card>
              <div class="px-4 pt-4 text-right">
                <h5>Verified Payments</h5>
                <h2>Rs. 110500</h2>
              </div>
              <div class="text-left px-4 pb-4">
                <router-link
                  to="/payments/verified-payments"
                  class="text-decoration-none"
                  style="font-size: 0.8rem"
                >
                  View Verified Payments
                  <v-icon small color="primary">mdi-chevron-right</v-icon>
                </router-link>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row> -->

    <!-- Categories Tabular Data -->
    <v-row class="mt-8">
      <v-col cols="12" lg="6">
        <v-card elevation="5">
          <v-card-title>Sales W.R.T. Categories</v-card-title>
          <v-simple-table fixed-header dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Category Name
                  </th>
                  <th class="text-left">
                    Total Sales
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in categoryReports" :key="item.id">
                  <td>{{ item.categories_name }}</td>
                  <td>{{ item.sales }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card elevation="5">
          <v-card-title>Stocks W.R.T. Categories</v-card-title>
          <v-simple-table fixed-header dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Category Name
                  </th>
                  <th class="text-left">
                    Total Products
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in categoryReports" :key="item.id">
                  <td>{{ item.categories_name }}</td>
                  <td>{{ item.total_products }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- Support Tickets -->
    <!-- <v-row class="mt-8">
      <v-col lg="8" class="mt-4">
        <v-row>
          <v-col cols="12" lg="6">
            <v-card class="mx-auto" elevation="5">
              <v-card class="pa-4 dashboard-card-inner" max-width="100">
                <v-icon color="#fff" large>mdi-card-plus</v-icon>
              </v-card>
              <div class="px-4 pt-4 text-right">
                <h5>New Tickets</h5>
                <h2>{{ newTicket }}</h2>
              </div>
              <div class="text-left px-4 pb-4">
                <router-link
                  to="/support/new-tickets"
                  class="text-decoration-none"
                  style="font-size: 0.8rem"
                  >Manage New Tickets
                  <v-icon small color="primary">mdi-chevron-right</v-icon>
                </router-link>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6">
            <v-card class="mx-auto" elevation="5">
              <v-card class="pa-4 dashboard-card-inner" max-width="100">
                <v-icon color="#fff" large>mdi-card-minus</v-icon>
              </v-card>
              <div class="px-4 pt-4 text-right">
                <h5>Pending Tickets</h5>
                <h2>{{ pendingTicket }}</h2>
              </div>
              <div class="text-left px-4 pb-4">
                <router-link
                  to="/support/pending-tickets"
                  class="text-decoration-none"
                  style="font-size: 0.8rem"
                  >Manage Pending Tickets
                  <v-icon small color="primary">mdi-chevron-right</v-icon>
                </router-link>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6" class="mt-12">
            <v-card class="mx-auto" elevation="5">
              <v-card class="pa-4 dashboard-card-inner" max-width="100">
                <v-icon color="#fff" large>mdi-card-bulleted</v-icon>
              </v-card>
              <div class="px-4 pt-4 text-right">
                <h5>Resolved Tickets</h5>
                <h2>{{ resolvedTicket }}</h2>
              </div>
              <div class="text-left px-4 pb-4">
                <router-link
                  to="/support/resolved-tickets"
                  class="text-decoration-none"
                  style="font-size: 0.8rem"
                >
                  Manage Resolved Tickets
                  <v-icon small color="primary">mdi-chevron-right</v-icon>
                </router-link>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6" class="mt-12">
            <v-card class="mx-auto" elevation="5">
              <v-card class="pa-4 dashboard-card-inner" max-width="100">
                <v-icon color="#fff" large>mdi-card-remove</v-icon>
              </v-card>
              <div class="px-4 pt-4 text-right">
                <h5>Closed Tickets</h5>
                <h2>{{ closedTicket }}</h2>
              </div>
              <div class="text-left px-4 pb-4">
                <router-link
                  to="/support/closed-tickets"
                  class="text-decoration-none"
                  style="font-size: 0.8rem"
                  >View All Tickets
                  <v-icon small color="primary">mdi-chevron-right</v-icon>
                </router-link>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="4">
        <v-card class="pa-4" elevation="5">
          <multiple-line-chart
            :data="pendingChart"
            :data1="resolvedChart"
            :data2="closedChart"
            style="width:100%; position: relative; height: 300px !important"
          ></multiple-line-chart>
        </v-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import PieChart from "../../components/layouts/charts/PieChart";
import BarChart from "../../components/layouts/charts/BarChart";
import CustomerBarChart from "../../components/layouts/charts/CustomerBarChart";
import LineChart from "../../components/layouts/charts/LineChart";
// import SalesLineChart from "../../components/layouts/charts/SalesLineChart";
// import MultipleLineChart from "../../components/layouts/charts/MultipleLineChart";
import { mapState } from "vuex";
import moment from "moment";

export default {
  components: {
    PieChart,
    BarChart,
    CustomerBarChart,
    LineChart,
    // SalesLineChart,
    // MultipleLineChart,
  },
  data() {
    return {
      count: {
        categoryCount: 0,
        subCategoryCount: 0,
        subSubCategoryCount: 0,
        brandCount: 0,
        tagsCount: 0,
        reviewCount: 0,
        productCount: 0,
        orderCount: 0,
        completedOrders: 0,
        pendingOrders: 0,
        newTicket: 0,
        pendingTicket: 0,
        resolvedTicket: 0,
        closedTicket: 0,
      },
      sales: [
        {
          category_name: "Electronic Devices & Accessories",
          sales: 159,
        },
        {
          category_name: "Home & Kitchen Appliances",
          sales: 43,
        },
        {
          category_name: "Makeup & Beauty",
          sales: 82,
        },
        {
          category_name: "Watches & Accessories",
          sales: 113,
        },
        {
          category_name: "Home Furniture & Lifestyle",
          sales: 29,
        },
        {
          category_name: "Liquor and Beverages",
          sales: 204,
        },
        {
          category_name: "Books, Music & More",
          sales: 96,
        },
        {
          category_name: "Bakery Items",
          sales: 42,
        },
        {
          category_name: "Kirana Items",
          sales: 71,
        },
        {
          category_name: "Mens Fashion",
          sales: 113,
        },
        {
          category_name: "Womens Fashion",
          sales: 293,
        },
        {
          category_name: "Baby, Kids & Fashion	",
          sales: 55,
        },
      ],
    };
  },

  created() {
    if (!this.productCount) {
      this.$store.dispatch("getProductsCount");
    }
    if (!this.brandsCount) {
      this.$store.dispatch("getBrandsCount");
    }
    if (!this.tagsCount) {
      this.$store.dispatch("getTagsCount");
    }
    if (!this.reviewCount) {
      this.$store.dispatch("getReviewCount");
    }
  },
  computed: {
    ...mapState({
      categoryCount: (state) => state.categories.length,
      categoryReports: (state) => state.categoryReports,
      customerCount: (state) => state.buyers.length,
      customer: (state) => state.customers,
      subCategoryCount: (state) => state.subCategories.length,
      subSubCategoryCount: (state) => state.subSubCategories.length,
      brandsCount: (state) => state.brandsCount,
      tagsCount: (state) => state.tagsCount,
      reviewCount: (state) => state.reviewsCount,
      productCount: (state) => state.productCount,
      orderCount: (state) => state.orders.length,
      orders: (state) => state.orders,
      sellers: (state) => state.sellers,
      sellerCount: (state) => state.sellers.length,
      completedOrders: (state) =>
        state.orders.filter((order) => order.status === "Delivered").length,
      cancelledOrders: (state) =>
        state.orders.filter((order) => order.status === "Cancelled").length,
      pendingOrders: (state) =>
        state.orders.filter(
        (item) =>
          item.status === "Ready" ||
          item.status === "Picking Up" ||
          item.status === "Picked Up" ||
          item.status === "Drop Off"
      ).length,
      newTicket: (state) =>
        state.supports.newCase ? state.supports.newCase.length : 0,
      resolvedTicket: (state) =>
        state.supports.resolvedCase ? state.supports.resolvedCase.length : 0,
      closedTicket: (state) =>
        state.supports.closedCase ? state.supports.closedCase.length : 0,
      pendingTicket: (state) =>
        state.supports.pendingCase ? state.supports.pendingCase.length : 0,
      resolvedTicketData: (state) =>
        state.supports.pendingCase ? state.supports.resolvedCase : [],
      closedTicketData: (state) =>
        state.supports.pendingCase ? state.supports.closedCase : [],
      pendingTicketData: (state) =>
        state.supports.pendingCase ? state.supports.pendingCase : [],
      customOrderCount: (state) => state.customOrders.length,
      customOrders: (state) => state.customOrders,
      pendingCustomOrders:(state)=>state.customOrders.filter((item)=>item.status === "Processing").length,
        completedCustomOrders: (state) =>
        state.customOrders.filter((order) => order.status === "Delivered").length,
      cancelledCustomOrders: (state) =>
        state.customOrders.filter((order) => order.status === "Cancelled").length,
    }),
    resolvedChart() {
      let array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.resolvedTicketData.forEach((item) => {
        array[moment(item.updated_at).format("M") - 1] =
          parseInt(array[moment(item.updated_at).format("M") - 1]) + 1;
      });
      return array;
    },
    closedChart() {
      let array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.closedTicketData.forEach((item) => {
        array[moment(item.updated_at).format("M") - 1] =
          parseInt(array[moment(item.updated_at).format("M") - 1]) + 1;
      });
      return array;
    },
    pendingChart() {
      let array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.pendingTicketData.forEach((item) => {
        array[moment(item.updated_at).format("M") - 1] =
          parseInt(array[moment(item.updated_at).format("M") - 1]) + 1;
      });
      return array;
    },

    orderChart() {
      let array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let allOrders=[...this.orders,...this.customOrders];
      allOrders.forEach((item) => {
        array[moment(item.updated_at).format("M") - 1] =
          parseInt(array[moment(item.updated_at).format("M") - 1]) + 1;
      });
      return array;
    },

    sellerChart() {
      let array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.sellers.forEach((item) => {
        array[moment(item.created_at).format("M") - 1] =
          parseInt(array[moment(item.created_at).format("M") - 1]) + 1;
      });

      return array;
    },
    customerChart() {
      let array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.customer.forEach((item) => {
        array[moment(item.created_at).format("M") - 1] =
          parseInt(array[moment(item.created_at).format("M") - 1]) + 1;
      });

      return array;
    },
    loading() {
      if (
        this.categoryCount &&
        this.subCategoryCount &&
        this.subSubCategoryCount &&
        this.brandCount &&
        this.tagsCount &&
        this.reviewCount &&
        this.pendingTicket
      ) {
        return false;
      } else {
        return true;
      }
    },
   allBuyers(){
     const customBuyers = this.customOrders.length
     return this.customerCount + customBuyers
   }
  },
};
</script>

<style lang="scss">
.dashboard-card-inner {
  background: rgb(255, 34, 65) !important;
  top: -1.5rem;
  left: 1rem;
  position: absolute;
}
</style>
