<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="teal"
        ></v-progress-linear>
        <v-card elevation="5">
          <v-card-title
            >Homepage Banner Image
            <v-btn
              rounded
              color="primary"
              class="ml-4"
              dark
              @click="dialog = true"
              >Add Banner Image</v-btn
            >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
            ></v-text-field>
            <v-dialog v-model="dialogDelete" max-width="400px">
              <v-card>
                <v-card-text class="pt-4 text-center">
                  <v-icon color="error" size="50">mdi-close-circle</v-icon>
                  <h2 class="font-bold py-4">
                    Are you sure want to delete this Item?
                  </h2>
                  <h4 class="font-bold text-left">
                    Please be aware that you cannot revert this item in future.
                  </h4>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    class="px-8"
                    rounded
                    dark
                    @click="deleteItemConfirm"
                    >OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-data-table :headers="headers" :items="getBanners" :search="search">
            <template v-slot:item.image="{ item }">
              <v-dialog :id="'dialog' + item.id" width="600">
                <template v-slot:activator="{ on, attrs }">
                  <img
                    :src="getImage(item.image)"
                    alt="banner-image"
                    class="img-thumbnail my-2"
                    width="100"
                    height="60"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>

                <v-card>
                  <v-card-title style="background-color: #163691; color: white"
                    >Homepage Banner Image
                  </v-card-title>
                  <img :src="getImage(item.image)" width="100%" />
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.is_published="{ item }">
              <div class="d-flex justify-left align-items-center">
                <div class="pb-4">
                  <v-switch
                    v-model="item.is_published"
                    @click="updateIsPublished(item)"
                    color="success"
                    hide-details
                  ></v-switch>
                </div>
              </div>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-left align-items-center">
                <div class="my-2">
                  <v-btn
                    color="primary mr-2"
                    fab
                    x-small
                    dark
                    @click="editItem(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    color="error"
                    fab
                    x-small
                    dark
                    @click="deleteItem(item)"
                  >
                    <v-icon dark>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              Add Banner Image
            </v-card-title>
            <v-card-text>
              <p><small>* indicates required fields</small></p>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  v-model="editedItem.name"
                  outlined
                  dense
                  label="Banner Name *"
                  hint="Homepage Slider Banner or Featured Category Banner"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-autocomplete
                  :items="type"
                  v-model="editedItem.type"
                  outlined
                  dense
                  label="Select Type *"
                  :rules="[rules.required]"
                ></v-autocomplete>
                <v-text-field
                  v-model="editedItem.order"
                  type="number"
                  outlined
                  dense
                  label="Order"
                  step="1"
                  min="1"
                  :rules="[rules.required, rules.onlyPositiveNumbers]"
                  hint="Banner will be visible according to order number i.e. 1 is for display first and so on..."
                ></v-text-field>
                <v-file-input
                  @change="imageSelect($event)"
                  v-model="retrieveFile"
                  label="Banner Image *"
                  outlined
                  prepend-icon=""
                  dense
                  :rules="[rules.required]"
                ></v-file-input>
                <v-autocomplete
                  :items="subcategory"
                  v-model="editedItem.sub_cat_id"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  label="Select SubCategory"
                ></v-autocomplete>
                <v-text-field
                  v-model="editedItem.url"
                  outlined
                  dense
                  label="Url if present"
                  hint="Use relative url from website. e.g. /product/home-kitchen-appliances/020d0591-a61c-486a-84ee-396a040e4522"
                ></v-text-field>
                <v-btn
                  color="primary"
                  rounded
                  :disabled="!valid"
                  class="px-6"
                  @click="save()"
                  >Save &amp; Submit
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import { dateMixin, imageMixin } from "../../../mixin/helperMixin";

export default {
  mixins: [dateMixin, imageMixin],

  data() {
    return {
      dialog: false,
      subcategory: null,
      dialogDelete: false,
      valid: false,
      banners: [],
      loading: false,
      search: "",
      rules: {
        required: (v) => !!v || "This field is required.",
        onlyPositiveNumbers: (v) =>
          /^[1-9]\d*$|^$/.test(v) || "Enter Only Positive Number",
      },
      banner_image_details: [
        { sn: "1", link: "https://sajhadeal.com", created_at: "04/05/2023" },
      ],
      headers: [
        { text: "S.N.", value: "sn" },
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        { text: "Order", value: "order" },
        {
          text: "Image",
          align: "start",
          sortable: false,
          value: "image",
        },
        { text: "Sub Category", value: "subCategory" },
        { text: "Published", value: "is_published" },
        { text: "Published Date", value: "created_at" },
        { text: "Actions", value: "actions" },
      ],
      editedItem: {},
      // type: ["Slider", "Ad Banner", "Featured Banner"]
      type: ["Slider", "Featured Banner"],
    };
  },

  computed: {
    ...mapGetters({
      getAllBanners: "getBanners",
      getAllSubCategories: "getSubCategories",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Add New Banner" : "Edit Banner Details";
    },
    getBanners() {
      return this.banners.map((item, index) => ({
        ...item,
        sn: index + 1,
        name: item.name,
        subCategory: item.sub_category && item.sub_category.name,
        type: item.type,
        order: item.order,
        is_published: item.is_published,
      }));
    },

    retrieveFile() {
      if (this.editedItem.image) {
        if (this.editedItem.image instanceof Blob) {
          return this.editedItem.image;
        }
        let ext = this.editedItem.image.split(".").pop();
        const images = new File(
          [this.getImage(this.editedItem.image)],
          this.editedItem.name + "." + ext,
          {
            type: "image/*",
          }
        );
        return images;
      } else {
        return "";
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions([
      "getBanner",
      "storeBanner",
      "updateBanner",
      "updatePublished",
      "deleteBanner",
      "getSubCategories",
    ]),

    imageSelect(event) {
      this.editedItem.image = event;
    },
    getImage(path) {
      if (path instanceof Blob) return URL.createObjectURL(path);
      else return path;
    },

    editItem(item) {
      let temp = this.banners.filter((banner) => banner.id === item.id)[0];
      this.editedIndex = this.banners.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      let temp = this.banners.filter((banner) => banner.id === item.id)[0];
      this.editedIndex = this.banners.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true;
      this.deleteBanner(this.editedItem.id)
        .then(() => {
          this.loading = false;
          this.$store.commit("setBanners", this.banners);
          this.banners.splice(this.editedIndex, 1);
          this.editedIndex = -1;
          Swal.fire("Success!", "Banner Deleted Successfully!", "success");
        })
        .catch(() => {
          this.loading = false;
          this.editedIndex = -1;
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);

        // this.editedIndex = -1
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1;
      });
    },
    updateIsPublished(item) {
      item.is_published = item.is_published ? 1 : 0;
      let data = { id: item.id, is_published: item.is_published ? 1 : 0 };
      let temp = this.banners.filter((banner) => banner.id === item.id)[0];
      this.editedIndex = this.banners.indexOf(temp);
      let temp1 = Object.assign({}, this.banners[this.editedIndex], item);
      this.updatePublished(data)
        .then(() => {
          Object.assign(this.banners[this.editedIndex], temp1);
          this.$store.commit("setBanner", this.banners);
          this.editedIndex = -1;
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.editedIndex = -1;
        });
    },

    save() {
      let fd;
      fd = new FormData();

      fd.append("image", this.editedItem.image);
      fd.append("name", this.editedItem.name);
      fd.append("url", this.editedItem.url);
      fd.append("order", this.editedItem.order);
      fd.append("type", this.editedItem.type);
      fd.append("sub_cat_id", this.editedItem.sub_cat_id);
      fd.append("is_published", this.editedItem.is_published ? 1 : 0);

      this.loading = true;
      if (this.editedIndex > -1) {
        // let temp = Object.assign(
        //   //   {},
        //   this.banners[this.editedIndex],
        //   this.editedItem
        // );
        // let temp1 = this.subcategory.filter(
        //   subcat => subcat.id === this.editedItem.sub_cat_id
        // )[0];

        this.updateBanner({ id: this.editedItem.id, fd: fd })
          .then(() => {
            // Object.assign(this.banners[this.editedIndex].sub_category, temp1);
            // Object.assign(this.banners[this.editedIndex], temp);
            // this.$store.commit("setBanners", this.banners);
            this.editedIndex = -1;
            this.loading = false;
            this.$refs.form.reset();
            Swal.fire("Success!", "Banner Updated Successfully!", "success");
          })
          .catch(() => {
            this.loading = false;
            this.editedIndex = -1;
            this.$refs.form.reset();
            Swal.fire("Oops...", "Something went wrong!", "error");
          });
      } else {
        this.storeBanner(fd)
          .then(() => {
            this.loading = false;
            Swal.fire("Success!", "Banner Added Successfully!", "success");
            this.editedIndex = -1;
            this.$refs.form.reset();
          })
          .catch(() => {
            this.loading = false;
            this.editedIndex = -1;
            this.$refs.form.reset();
            Swal.fire("Oops...", "Something went wrong!", "error");
          });
      }

      this.close();
    },
  },
  mounted() {
    if (this.getAllBanners.length === 0) {
      this.getBanner().then((response) => {
        this.banners = response;
      });
    } else {
      this.banners = this.getAllBanners;
    }
    if (this.getAllSubCategories.length === 0) {
      this.getSubCategories().then((response) => {
        this.subcategory = response;
      });
    } else {
      this.subcategory = this.getAllSubCategories;
    }
  },
};
</script>

<style></style>
