var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',[_vm._v("All Customer Details "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","placeholder":"Press Enter to search","single-line":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchs($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filtered.length > 0 || _vm.noItem ? _vm.filtered : _vm.getAllCustomersList,"footer-props":{ 'items-per-page-options': [10, 15, 20] },"loading":_vm.loading,"loading-text":"Loading... Please wait"},on:{"pagination":_vm.paginate},scopedSlots:_vm._u([{key:"item.profile_photo",fn:function(ref){
var item = ref.item;
return [(item.profile_photo !== null)?_c('v-dialog',{attrs:{"id":'dialog' + item.id,"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"60"}},'v-avatar',attrs,false),on),[_c('img',{staticClass:"img-thumbnail my-2",attrs:{"src":item.profile_photo,"alt":"banner-image","width":"100","height":"60"},on:{"error":function($event){item.profile_photo = "/img/sajha.jpg"}}})])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticStyle:{"background-color":"#163691","color":"white"}},[_vm._v("User Profile Image")]),_c('img',{attrs:{"src":item.profile_photo,"width":"100%"}})],1)],1):_c('p',[_vm._v("NA")])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getWalletBalance(item))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left align-items-center"},[_c('div',{staticClass:"pb-4"},[_c('v-switch',{attrs:{"color":"success","hide-details":""},on:{"change":function($event){return _vm.updateUserStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1)])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }