<template>
  <v-container>
    <v-card>
      <v-card-title>Add a Seller</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-stepper v-model="step" vertical flat>
          <v-stepper-step aria-sort="" :complete="step > 1" :step="1">
            Basic Information
            <small>Feel free to add basic details of your Company</small>
          </v-stepper-step>
          <v-stepper-content :step="1">
            <general-details
              :fillable="fillable"
              :company-details="companyDetails"
              @updateStep="updateStep"
              @updatename="updateName"
              @updatephone="updatePhone"
            ></general-details>
          </v-stepper-content>

          <v-stepper-step aria-sort="" :complete="step > 2" :step="2">
            Add the Addresses
            <small>We collect your addresses for convienience</small>
          </v-stepper-step>
          <v-stepper-content :step="2">
            <address-details
              :address_details="address_details"
              @updateStep="updateStep"
              :name="name"
              :phone="phone"
              :loading="loading"
              @submit="submit"
            ></address-details>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import Swal from "sweetalert2";

import AddressDetails from "../../../components/seller/add/AddressDetails.vue";
import GeneralDetails from "../../../components/seller/add/GeneralDetails.vue";

export default {
  components: { GeneralDetails, AddressDetails },
  data() {
    return {
      step: 1,
      name: "",
      fillable: true,
      phone: "",
      loading: false,
      companyDetails: {
        owner_name: "",
        business_name: "",
        logo: null,
        phone: "",
        title: "",
        description: "",
        email: "",
        pan: "",
        document: null,
        office_phone: "",
      },
      address_details: [
        {
          business_address: {
            street_address: null,
            district: null,
            area: null,
            city: null,
            state: null,
            country: "Nepal",
          },
        },
        {
          warehouse_address: {
            street_address: null,
            district: null,
            area: null,
            city: null,
            state: null,
            country: "Nepal",
          },
        },
        {
          return_address: {
            street_address: null,
            district: null,
            area: null,
            city: null,
            state: null,
            country: "Nepal",
          },
        },
      ],
    };
  },
  methods: {
    updateStep(val) {
      if (val === 1) {
        this.step = 1;
      } else {
        this.step += 1;
      }
    },
    submit() {
      this.loading = true;
      this.$store
        .dispatch("storeVendor")
        .then((res) => {
          if (res.status === 402) {
            Swal.fire("Error", res.message, "error");
            this.loading = false;
          } else {
            this.loading = false;
            const data =
              "Email: " + res.email + " | " + "Password: " + res.password;
            Swal.fire({
              title: "Success",
              text: data,
              icon: "success",
              showCancelButton: true,
              confirmButtonColor: "#ff2241",
              confirmButtonText: "Okay",
            }).then((result) => {
              this.$store.commit("addVendor", res.detail[0]);
              if (result.isConfirmed) {
                this.$router.push("/seller/verified-seller");
              }
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateName(val) {
      this.name = val;
    },
    updatePhone(val) {
      this.phone = val;
    },
  },
};
</script>
