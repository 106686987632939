<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>Stocks Report
            <div class="pt-4 px-4">
              <v-autocomplete
                  v-model="selectedVendor"
                  :items="vendors"
                  item-text="details.business_name"
                  item-value="details.id"
                  outlined
                  dense
                  label="Filter According to Sellers"
              ></v-autocomplete>
            </div>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
            ></v-text-field>
            <v-btn color="primary" class="ml-4" rounded @click="exportToExcel">Export to Excel</v-btn>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="formattedStocks"
              :search="search"
          >
            <template v-slot:item.remainings="{ item }">
              <p>{{ item.stocks - item.sales}}</p>
            </template>
          </v-data-table>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {json2excel} from 'js2excel';
import axios_api from "@/axios/axios_api";

export default {
    Components: { json2excel },
    data() {
        return {
            dialog: false,
            search: '',
            stocks: [],
            headers: [
                { text: "S.N.", value: "sn" },
                {
                text: 'Product Name',
                align: 'start',
                sortable: false,
                value: 'name',
                },
                { text: 'Seller Name', value: 'business_name' },
                { text: 'Total Stocks', value: 'stocks' },
                { text: 'No. of Sale', value: 'sales' },
                { text: 'Total Remainings', value: 'remainings' },
            ],
          formattedStocks: [],
          vendors: [],
          selectedVendor: "",
          filteredStocks: [],
        }
    },
  mounted() {
    axios_api.get("stocksReport")
        .then(response => {
          this.stocks = response.data.data;
        });
    axios_api.get("vendor")
        .then(response => {
          this.vendors = response.data;
        });
  },
  watch: {
    stocks(val) {
      this.filteredStocks = val;
    },
    selectedVendor(val) {
     
      if (val)
        this.filteredStocks = this.stocks.filter(s => (s.business_name === val));
      else
        this.filteredStocks = this.stocks;
    },
    filteredStocks(val) {
      this.formattedStocks = val.map((v, index) => ({...v, sn: index + 1}));
    },
  },
    methods: {
        exportToExcel() {
            // this will be export a excel and the file's name is user-info-data.xlsx
            // the default file's name is excel.xlsx
            let data = this.stocks;
            try {
                json2excel({
                    data,
                    name: 'stocks-report',
                    // formateDate: 'yyyy/mm/dd'
                });
            } catch (e) {
                alert("Something Went Wrong. Try Again!");
            }
        }
    },

}
</script>

<style>

</style>