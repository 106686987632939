<template>
  <ordered-item :headers="headers" :type="'Cancelled'" :orders="cancelledOrders"></ordered-item>
</template>

<script>
import OrderedItem from "../../../components/Order/OrderedItem";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {OrderedItem},
  data() {
    return {
      headers: [
        {text: "S.N.", value: "sn"},
        {text: 'Order Code', value: 'order_code'},
        {
          text: 'Customer Name',
          align: 'start',
          sortable: false,
          value: 'customer_name',
        },
        {text: 'Phone', value: 'customer_phone'},
        {text: 'Coupons', value: 'coupons'},
        {text: 'Delivery Price', value: 'delivery_price'},
        {text: 'Total Price', value: 'total_price'},
        {text: 'Payment Method', value: 'payment_type'},
        {text: 'Ordered Date', value: 'created_at'},
        {text: 'Actions', value: 'actions'},
      ],
    }
  },
  methods: {
    ...mapActions([
      'getAllOrders'
    ]),
  },
  computed: {
    ...mapGetters({
      cancelledOrders: "getCancelledOrders",
    }),
  },


  mounted() {
    if (this.$store.state.orders.length === 0) {
      this.getAllOrders().then((response) => {
        this.orders = response;

      });
    }
  }


};
</script>

<style></style>