var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',[_vm._v(" Products "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","placeholder":"Press Enter to search","single-line":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchs($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filtered.length > 0 || _vm.noItem ? _vm.filtered : _vm.products,"item-key":"id","footer-props":{ 'items-per-page-options': [10, 15, 20] },"loading":_vm.loading,"loading-text":"Loading... Please wait"},on:{"pagination":_vm.paginate},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.category && item.category.name)+" ")]}},{key:"item.subcategory",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subcategory && item.subcategory.name)+" ")]}},{key:"item.brand",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.brand && item.brand.name)+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.details ? item.details.description : "No Content Found")+" ")]}},{key:"item.top_picks",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.updateStatus(item, 'top_picks')}},model:{value:(item.top_picks),callback:function ($$v) {_vm.$set(item, "top_picks", $$v)},expression:"item.top_picks"}})]}},{key:"item.hot_picks",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.updateStatus(item, 'hot_picks')}},model:{value:(item.hot_picks),callback:function ($$v) {_vm.$set(item, "hot_picks", $$v)},expression:"item.hot_picks"}})]}},{key:"item.top_rank",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.updateStatus(item, 'top_rank')}},model:{value:(item.top_rank),callback:function ($$v) {_vm.$set(item, "top_rank", $$v)},expression:"item.top_rank"}})]}},{key:"item.new_arrival",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.updateStatus(item, 'new_arrival')}},model:{value:(item.new_arrival),callback:function ($$v) {_vm.$set(item, "new_arrival", $$v)},expression:"item.new_arrival"}})]}},{key:"item.weekly_deal",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.updateStatus(item, 'weekly_deal')}},model:{value:(item.weekly_deal),callback:function ($$v) {_vm.$set(item, "weekly_deal", $$v)},expression:"item.weekly_deal"}})]}},{key:"item.just_for_you",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.updateStatus(item, 'just_for_you')}},model:{value:(item.just_for_you),callback:function ($$v) {_vm.$set(item, "just_for_you", $$v)},expression:"item.just_for_you"}})]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.updateStatus(item, 'is_active')}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})]}},{key:"item.flash_deal",fn:function(ref){
var item = ref.item;
return [(item.flash_deal !== 1)?_c('v-dialog',{attrs:{"id":'flashDialog' + item.id,"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",attrs:{"x-small":""}},[_vm._v("mdi-flash-outline")]),_vm._v(" Add ")],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v(" Participate ")]),_c('v-card-text',[_c('v-form',[_c('v-switch',{attrs:{"label":"Is Featured"},model:{value:(_vm.flashDeal.is_featured),callback:function ($$v) {_vm.$set(_vm.flashDeal, "is_featured", $$v)},expression:"flashDeal.is_featured"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateStatus(item, 'flash_deal_on')}}},[_vm._v("Submit ")])],1)],1)],1)],1):_c('v-btn',{attrs:{"color":"error","small":"","dark":""},on:{"click":function($event){return _vm.updateStatus(item, 'flash_deal_off')}}},[_c('v-icon',{staticClass:"pr-2",attrs:{"x-small":""}},[_vm._v("mdi-flash-off")]),_vm._v(" Remove ")],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"id":'descriptionDialog' + item.id,"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",attrs:{"size":"16"}},[_vm._v("mdi-eye")]),_vm._v(" Description ")],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Product Description")]),_c('v-divider'),_c('v-card-text',{staticClass:"py-2"},[_c('p',{domProps:{"innerHTML":_vm._s(item.details.description)}})])],1)],1)]}},{key:"item.featured_image",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"id":'imageDialog' + item.id,"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",attrs:{"size":"16"}},[_vm._v("mdi-eye")]),_vm._v(" Image ")],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Featured Image")]),_c('v-divider'),_c('v-card-text',{staticClass:"py-2"},[_c('img',{attrs:{"src":item.featuredImage,"width":"100%","alt":item.name}})])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left align-items-center"},[_c('div',{staticClass:"my-2"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"color":"error","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.deleteData(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Edit Product Details")])]),_c('v-card-text',[_c('v-form',[_c('v-text-field',{attrs:{"label":"Product Name"},model:{value:(_vm.edited.name),callback:function ($$v) {_vm.$set(_vm.edited, "name", $$v)},expression:"edited.name"}}),_c('v-btn',{attrs:{"color":"primary"}},[_vm._v("Submit")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }