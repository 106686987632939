<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="8" offset-lg="2">
        <v-card elevation="5">
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="teal"
          ></v-progress-linear>

          <v-card-title>SEO Settings</v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-text-field
                v-model="getSetting.name"
                outlined
                dense
                label="Website Title"
              ></v-text-field>
              <v-text-field
                v-model="getSetting.meta_title"
                outlined
                dense
                label="Meta Title"
              ></v-text-field>
              <v-text-field
                v-model="getSetting.sitemap_link"
                outlined
                dense
                label="SiteMap Link"
              ></v-text-field>
              <v-text-field
                v-model="getSetting.email"
                outlined
                dense
                label="Email"
              ></v-text-field>
              <v-text-field
                v-model="getSetting.address"
                outlined
                dense
                label="Address"
              ></v-text-field>
              <v-text-field
                v-model="getSetting.phone"
                outlined
                dense
                label="Phone Number"
              ></v-text-field>
              <v-combobox
                multiple
                v-model="getSetting.keywords"
                @change="editItem(getSetting)"
                label="Keywords"
                append-icon
                small-chips
                outlined
                dense
                deletable-chips
                class="keywords-input"
                :search-input.sync="search"
                @keyup.tab="updateKeywords"
              >
              </v-combobox>
              <v-chip
                v-for="keyword in getSetting.keywords"
                :key="keyword.id"
                color="success"
                text-color="white"
                class="mr-2 mb-4"
              >
                {{ keyword }}
              </v-chip>
              <v-combobox
                multiple
                v-model="getSetting.canonical"
                label="canonical"
                append-icon
                small-chips
                outlined
                dense
                deletable-chips
                class="canonical-input"
                :search-input.sync="search"
                @keyup.tab="updateKeywords"
              >
              </v-combobox>
              <v-chip
                v-for="canonical in getSetting.canonical"
                :key="canonical.id"
                color="success"
                text-color="white"
                class="mr-2 mb-2"
              >
                {{ canonical }}
              </v-chip>
              <v-file-input
                v-model="getSetting.logo"
                label="Logo"
                outlined
                prepend-icon=""
                dense
              ></v-file-input>
              <v-file-input
                v-model="getSetting.thumbnail_image"
                label="Thumbnail Image"
                outlined
                prepend-icon=""
                dense
              ></v-file-input>
              <v-file-input
                v-model="getSetting.favicon"
                label="Favicon"
                outlined
                prepend-icon=""
                dense
              ></v-file-input>

              <v-textarea
                v-model="getSetting.meta_desc"
                auto-grow
                label="Site Description"
                rows="4"
                row-height="30"
                outlined
                dense
                class="mt-4"
              ></v-textarea>
            </v-form>
            <v-btn
              color="primary"
              rounded
              class="px-6"
              @click="update()"
              :disabled="!valid"
              >Save &amp; Submit</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      loading: false,
      rules: {
        required: (v) => !!v || "This field is required.",
      },
      valid: false,
      title: "Saajha | Best Online Shopping Experience",
      author: "Saajha",
      description:
        "Saajha.",
      sitemap: "https://www.Saajha.com/",
      keywords: [],
      canonicals: [],

      items: [],
      search: "", //sync search
      settings: {},
      temp: {},
    };
  },
  methods: {
    ...mapActions(["getSettings", "updateSetting"]),
    updateTags() {
      this.$nextTick(() => {
        this.keywords.push(...this.search.split(","));
        this.$nextTick(() => {
          this.search = "";
        });
      });
    },
    editItem(item) {
      this.editedItem = Object.assign({ id: this.settings.id }, item);
    },
    update() {
      this.loading = true;
      let fd;
      fd = new FormData();
      fd.append("favicon", this.getSetting.favicon);
      fd.append("thumbnail_image", this.getSetting.thumbnail_image);
      fd.append("logo", this.getSetting.logo);
      fd.append("canonical", JSON.stringify(this.getSetting.canonical));
      fd.append("keywords", JSON.stringify(this.getSetting.keywords));

      if (this.getSetting.name) fd.append("name", this.getSetting.name);
      if (this.getSetting.meta_title)
        fd.append("meta_title", this.getSetting.meta_title);
      if (this.getSetting.meta_desc)
        fd.append("meta_desc", this.getSetting.meta_desc);
      if (this.getSetting.email) fd.append("email", this.getSetting.email);
      if (this.getSetting.address)
        fd.append("address", this.getSetting.address);
      if (this.getSetting.phone) fd.append("phone", this.getSetting.phone);
      if (this.getSetting.sitemap_link) fd.append("sitemap_link", this.getSetting.sitemap_link);

      this.updateSetting({ id: this.settings.id, fd: fd })
        .then(() => {
          this.$store.commit("setSettings", this.getSetting);
          this.loading = false;
          Swal.fire("Success!", "Setting Updated Successfully!", "success");
        })
        .catch(() => {
          this.loading = false;
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },
  },
  computed: {
    ...mapGetters({
      getAllSetting: "getSettings",
    }),
    getSetting() {
      return {
        logo: this.settings.logo,
        sitemap_link: this.settings.sitemap_link,
        meta_title: this.settings.meta_title,
        canonical: this.canonicals,
        keywords: this.keywords,
        meta_desc: this.settings.meta_desc,
        thumbnail_image: this.settings.thumbnail_image,
        favicon: this.settings.favicon,
        address: this.settings.address,
        email: this.settings.email,
        phone: this.settings.phone,
        name: this.settings.name,
      };
    },
  },
  mounted() {
    if (this.getAllSetting.length === 0) {
      this.getSettings().then((response) => {
        this.settings = response;
        this.canonicals = JSON.parse(response.canonical);
        this.keywords = JSON.parse(response.keywords);
      });
    } else {
      this.settings = this.getAllSetting;
    }
  },
};
</script>

<style>
.v-select.v-input--dense .v-chip {
  margin: 4px 4px;
}
</style>
