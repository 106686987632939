var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',[_vm._v("Customer Reviews "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getReview,"search":_vm.search},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"id":'descriptionDialog' + item.id,"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",attrs:{"size":"14"}},[_vm._v("mdi-eye")]),_vm._v(" Description ")],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Review Description ")]),_c('v-divider',[_vm._v("s")]),_c('v-card-text',{staticClass:"py-2"},[_c('p',[_vm._v(_vm._s(item.review))])])],1)],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-4 text-center"},[_c('v-icon',{attrs:{"color":"error","size":"50"}},[_vm._v("mdi-close-circle")]),_c('h2',{staticClass:"font-bold py-4"},[_vm._v(" Are you sure want to delete this Item? ")]),_c('h4',{staticClass:"font-bold text-left"},[_vm._v(" Please be aware that you cannot revert this item in future. ")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{staticClass:"px-8",attrs:{"color":"error","rounded":"","dark":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")])],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }