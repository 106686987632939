<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title
            >Order Delivery Details
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="filteredDetails"
            :search="search"
          >
            <template v-slot:item.product_name="{ item }">
              <h5 v-for="(name, index) in item.order_detail" :key="name.product.id + name.product.name">
                {{ name.product.name }}
                <v-divider v-if="index !== item.order_detail.length - 1"></v-divider>
              </h5>
            </template>

            <template v-slot:item.quantity="{ item }">
              <h5 v-for="(name, index) in item.order_detail" :key="name.product.id + name.product.pname">
                {{ name.qty }}
                <v-divider v-if="index !== item.order_detail.length - 1"></v-divider>
              </h5>
            </template>
            <template v-slot:item.customer_address="{ item }">
              <p class="mb-0">
                {{
                  getAddress(item.shipping)
                }}
              </p>

            </template>
            <template v-slot:item.delivered="{ item }">
              <div class="d-flex justify-left align-items-center">
                <div class="pb-4">
                  <v-switch
                    v-model="item.id"
                    color="success"
                    hide-details
                  ></v-switch>
                </div>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-left align-items-center" :id="item.id">
                <div class="my-2">
                  <v-btn
                    color="primary"
                    v-if="item.delivery===null"
                    class="my-2"
                    small
                    dark
                    @click="openDialog(item)"
                  >
                    Assign Rider
                  </v-btn>
                  <v-btn
                      v-else
                      color="success"
                      class="my-2"
                      small
                      dark

                  >
                    {{item.delivery.rider.name}}
                  </v-btn>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              Assign Driver
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-autocomplete

                    :items="riders"
                    v-model="editedItem.rider"
                    item-text="name"
                    item-value="id"
                  outlined
                  dense
                  label="Select Rider"
                ></v-autocomplete>
                <v-btn color="primary" @click="assignRider()">Submit</v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import moment from "moment";
import {AddressMixin} from "../../../mixin/helperMixin";

export default {
  mixins:[AddressMixin],
  data() {
    return {
      dialog: false,
      editedItem:{},
      editedIndex:-1,
      search: "",
      // riders: ["Ram Sharma", "Shyam Sharma", "Hari Sharma"],
      headers: [
        { text: "S.N.", value: "sn" },
        { text: "Order Id", value: "order_id" },
        {
          text: "Customer Name",
          align: "start",
          sortable: false,
          value: "customer_name",
        },
        { text: "Phone", value: "phone" },
        { text: "Address", value: "customer_address" },
        { text: "Product Name", value: "product_name" },

        { text: "Quantity", value: "quantity" },
        { text: "Total Price", value: "total" },

        { text: "Ordered Date", value: "ordered_date" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders,
    }),
    ...mapGetters({
      pending: "getPickedUpOrders",
      riders:"getRiders",
    }),
    filteredDetails() {
      console.log(this.pending[0])
      return this.pending.map((item, index) => ({
        ...item,
        sn: index + 1,
        order_id: item.code,
        customer_name: item.user&&item.user.name,
        phone: item.user&&item.user.phone,
        total: item.total,
        ordered_date: moment(item.created_at).format("DD MM YYYY"),
      }));
    },
  },
  methods: {
    ...mapActions(["getAllOrders","assignRiders","getStaffs","assignDeliveryRiders"]),
    submit() {
      console.log("Submit");
    },
    assignRider(){
      let data={id:this.editedItem.id,status:'Drop Off',rider_id:this.editedItem.rider}

      this.assignDeliveryRiders(data).then((res)=>{

        this.$store.commit("updateDeliveredOrder", res.data);

      }).catch(()=>{

      });
      this.dialog=false;

      console.log(this.editedItem);
    },
    openDialog(item){
      let temp = this.pending.filter(value => value.id === value.id)[0]
      this.editedIndex = this.pending.indexOf(temp)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    }
  },
  created() {
    if (!this.pending.length) {
      this.getAllOrders();
    }
    if(!this.riders.length){
      this.getStaffs();
    }
  },
};
</script>

<style></style>
