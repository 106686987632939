<template>
  <v-card flat>
    <v-card-title class="justify-space-between"
      >{{ type }} Details
    </v-card-title>
    <v-divider class="m-0"></v-divider>
    <v-card-text>
      <v-form ref="form">
        <div class="d-flex justify-space-between align-center">
          <div v-if="activateBusinessCopy">
            <v-checkbox
              v-model="businesscopy"
              dense
              class="checkbox"
              label="Copy Business Address Here"
            >
            </v-checkbox>
          </div>
          <div v-if="activateWarehouseCopy">
            <v-checkbox
              v-model="warehousecopy"
              dense
              class="checkbox"
              label="Copy Warehouse Address Here"
            >
            </v-checkbox>
          </div>
        </div>

        <p class="ml-3 " style="font-size: 1rem; font-weight: 500">Contact</p>
        <v-row>
          <v-col lg="6">
            <v-text-field
              v-model.lazy="name"
              label="Legal Full Name*"
              outlined
              dense
              required
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col lg="6">
            <v-text-field
              v-model.lazy="phone"
              label="Phone Number*"
              outlined
              dense
              required
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <p class="ml-3" style="font-size: 1rem; font-weight: 500">Address</p>
        <v-row>
          <v-col lg="4" sm="6" cols="6">
            <v-text-field
              v-model="country"
              dense
              outlined
              disabled
              label="Country*"
              required
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col lg="4" sm="6" cols="6">
            <v-autocomplete
              v-model="state"
              :items="states"
              item-text="name"
              item-value="name"
              dense
              outlined
              label="State*"
              required
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col lg="4" sm="6" cols="6">
            <v-autocomplete
              v-model="district"
              :items="filteredDistrict"
              dense
              outlined
              label="District*"
              required
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col lg="4" sm="6" cols="6">
            <v-text-field
              v-model="city"
              dense
              outlined
              label="City*"
              required
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col lg="4" sm="6" cols="6">
            <v-autocomplete
              v-model="area"
              :items="areas"
              dense
              outlined
              label="Area*"
              required
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col lg="4" sm="6" cols="6">
            <v-text-field
              v-model.lazy="street_address"
              label="Address*"
              outlined
              dense
              required
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-row style="float: right">
        <v-btn color="secondary" @click="saveAddress">Save {{ type }}</v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    activateBusinessCopy: {
      type: Boolean,
      default: false,
    },
    activateWarehouseCopy: {
      type: Boolean,
      default: false,
    },
    propname: {
      type: String,
      default: "",
    },
    propphone: {
      type: String,
      default: "",
    },
    street_address: {
      type: String,
      default: "",
    },
    district: {
      type: String,
      default: "",
    },

    city: {
      type: String,
      default: "",
    },
    area: {
      type: String,
      default: "",
    },
    state: {
      type: String,
      default: "",
    },
    country: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rules: {
        required: (v) => !!v || "This field is required.",
      },
      // cities: ["Lagankhel", "Sankhamul", "Baneswor", "Thimi"],
      areas: [
        "Inside Ring Road",
        "Outside Ring Road",
        "Inside Valley",
        "Outside Valley",
        "Outside Country",
      ],
      name: null,
      phone: null,
      // street_address: null,
      // city: null,
      // area: null,
      // district: null,
      // state: null,
      businesscopy: null,
      warehousecopy: null,
      radioGroup: 1,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      business_address: (state) => state.addVendor.business_address,
      warehouse_address: (state) => state.addVendor.warehouse_address,
      states: (state) => state.statesOfNepal,
      districts: (state) => state.districtsOfNepal,
    }),
    filteredDistrict() {
      if (this.state) {
        return this.districts.filter((item) => item.state === this.state)[0][
          "districts"
        ];
      } else {
        return [];
      }
    },
  },
  watch: {
    businesscopy() {
      if (this.businesscopy) {
        this.warehousecopy = null;
        this.copyAddress("businesscopy");
      } else {
        this.resetForm();
      }
    },
    warehousecopy() {
      if (this.warehousecopy) {
        this.businesscopy = null;
        this.copyAddress("warehousecopy");
      } else {
        this.resetForm();
      }
    },
    propname() {
      this.assignNamePhone();
    },
    propphone() {
      this.assignNamePhone();
    },
    pdistrict() {
      this.district = this.pdistrict;
    },
    pstate() {
      this.state = this.pstate;
    },
    pcity() {
      this.city = this.pcity;
    },
    parea() {
      this.area = this.parea;
    },
    pstreet_address() {
      this.street_address = this.pstreet_address;
    },
  },
  methods: {
    resetForm() {
      this.name = "";
      this.phone = "";
      this.street_address = "";
      this.city = "";
      this.area = "";
      this.district = "";
      this.state = "";
    },
    copyAddress(temp = false) {
      const parent = this.setParent(temp);
      this.name = parent.name;
      this.phone = parent.phone;
      this.street_address = parent.street_address;
      this.city = parent.city;
      this.area = parent.area;
      this.district = parent.district;
      this.state = parent.state;
    },
    setParent(temp) {
      if (temp === "businesscopy") {
        return this.business_address;
      } else {
        return this.warehouse_address;
      }
    },
    saveAddress() {
      if (this.$refs.form.validate()) {
        let data = {
          name: this.name,
          phone: this.phone,
          street_address: this.street_address,
          city: this.city,
          area: this.area,
          district: this.district,
          state: this.state,
        };
        if (this.type === " Business Address") {
          data.type = "business";
          this.$store.commit("setVendorDetails", data, "business_address");
        } else if (this.type === " Warehouse Address") {
          data.type = "warehouse";
          this.$store.commit("setVendorDetails", data, "warehouse_address");
        } else {
          data.type = "return";
          this.$store.commit("setVendorDetails", data, "return_address");
        }
        this.$emit("storeaddress");
      }
    },
    assignNamePhone() {
      this.name = this.propname;
      this.phone = this.propphone;
    },
  },
  mounted() {
    this.assignNamePhone();
  },
};
</script>
<style lang="scss">
.checkbox {
  .v-label {
    margin-bottom: 0px !important;
  }
}
</style>
