<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1">
        <v-card>
          <v-card-title>Deal Name</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="deal.name"
              label="Deal Name"
              outlined
            ></v-text-field>
            <v-btn color="primary" rounded class="my-4 px-6" @click="update()"
              >Save &amp; Submit</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      deal: {},
      temp: {},
    };
  },

  created() {
    this.deal = this.$store.state.deal;
  },
  methods: {
    ...mapActions(["updateDealName"]),
    editItem(item) {
      this.temp = Object.assign({}, item);
    },
    update() {
      this.updateDealName(this.deal)
        .then(() => {
          this.$store.commit("setDealName", this.deal);
          Swal.fire("Success!", "Deal Name Updated Successfully!", "success");
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },
  },
};
</script>

<style></style>
