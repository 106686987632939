<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title
            >{{ type }} Orders
            <v-dialog v-model="addDialog" max-width="650px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  rounded
                  color="primary"
                  class="ml-4 px-6"
                  dark
                  >Add New Order
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  New Order
                </v-card-title>
                <v-card-text>
                  <v-form ref="form" v-model="valid">
                    <p><small>* indicates required field</small></p>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" class="pb-0">
                        <v-text-field
                          v-model="editedItem.name"
                          outlined
                          dense
                          label="Full Name *"
                          required
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" class="pb-0">
                        <v-text-field
                          v-model="editedItem.phone"
                          outlined
                          dense
                          label="Phone Number *"
                          required
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12" class="pb-0">
                        <v-text-field
                          v-model="editedItem.email"
                          outlined
                          dense
                          label="Email Address"
                        ></v-text-field>
                      </v-col>
                      <v-card-title>
                        Shipping Address
                      </v-card-title>
                      <v-row>
                        <v-col lg="6" sm="6" cols="12" class="pb-0">
                          <v-autocomplete
                            v-model="editedItem.state"
                            :items="states"
                            item-text="name"
                            item-value="name"
                            dense
                            outlined
                            required
                            label="State *"
                            :rules="[rules.required]"
                          ></v-autocomplete>
                        </v-col>
                        <v-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                          <v-autocomplete
                            v-model="editedItem.district"
                            :items="filteredDistrict"
                            @change="setArea"
                            dense
                            outlined
                            required
                            label="District *"
                            :rules="[rules.required]"
                          ></v-autocomplete>
                        </v-col>

                        <v-col lg="6" sm="6" md="6" cols="6" class="pb-0">
                          <v-autocomplete
                            v-model="editedItem.area"
                            :items="filteredArea"
                            dense
                            outlined
                            required
                            label="Area *"
                            :rules="[rules.required]"
                          ></v-autocomplete>
                        </v-col>
                        <v-col lg="6" md="6" sm="6" cols="6" class="pb-0">
                          <v-text-field
                            v-model.lazy="editedItem.city"
                            dense
                            outlined
                            required
                            :label="'City *'"
                            :rules="[rules.required]"

                          ></v-text-field>
                        </v-col>
                        <v-col lg="12" sm="12" md="12" cols="12" class="pb-0">
                          <v-text-field
                            v-model.lazy="editedItem.street_address"
                            label="Street Name or Near Landmark"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-card-title>
                        Ordered Item/Product Details
                      </v-card-title>
                      <v-row
                        v-for="(item, index) in orderedProduct"
                        :key="index"
                      >
                        <v-col lg="10" md="10" sm="10" cols="10">
                          <v-text-field
                            @keyup.enter="submitUrl(index)"
                            v-model.lazy="orderedProduct[index].productUrl"
                            label="Product URL from Live Website*"
                            hint="Enter Product URL and Press Enter"
                            outlined
                            required
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col lg="2" md="2" sm="2" cols="2">
                          <div
                            v-if="count"
                            class="d-flex flex-column justify-center align-items-center"
                          >
                            <v-btn
                              small
                              icon
                              color="error"
                              @click="deleteOrderedProduct(index)"
                              ><v-icon small>mdi-delete</v-icon></v-btn
                            >
                            <v-btn
                              class="success mt-2"
                              align-end
                              x-small
                              @click="addOrderedProduct()"
                            >
                              Add</v-btn
                            >
                          </div>
                        </v-col>
                        <v-col lg="12" md="12" sm="12" cols="12">
                          <li
                            v-if="
                              product.length &&
                                product[index] &&
                                product[index].vendor
                            "
                          >
                            Product Name:{{ product[index].name }}
                            <!-- <v-btn color="red" style="color:white;" align-end x-small @click="deleteOrderedProduct(index)">Delete</v-btn> -->
                          </li>
                          <li
                            v-if="
                              product.length &&
                                product[index] &&
                                product[index].vendor
                            "
                          >
                            Seller Name:{{
                              product[index].vendor.business_name
                            }}
                          </li>
                        </v-col>

                        <v-col lg="4" sm="4" md="4" cols="4">
                          <div>
                            <div
                              v-if="
                                colorPatches.length &&
                                  colorPatches[index] &&
                                  colorPatches[index].length
                              "
                              class="product-color"
                            >
                              <p class="font-bold mb-2">Color</p>
                              <div class="product-color-btn d-flex">
                                <v-btn-toggle
                                  v-model="colorToggle"
                                  group
                                  dense
                                  class="flex-wrap"
                                >
                                  <v-btn
                                    v-for="color in colorPatches[index]"
                                    :key="color.name"
                                    small
                                    :value="color.id"
                                    @click="updateVariation(color, 'colors')"
                                    >{{ color.name }}
                                    <div
                                      class="dot ml-1"
                                      :style="{ backgroundColor: color.code }"
                                    >
                                      ''
                                    </div>
                                  </v-btn>
                                </v-btn-toggle>
                              </div>
                            </div>
                            <div
                              v-if="
                                sizes.length &&
                                  sizes[index] &&
                                  sizes[index].length
                              "
                              class="product-size"
                            >
                              <p class="font-bold mb-2">Size</p>
                              <div class="product-size-btn d-flex">
                                <v-btn-toggle
                                  v-model="sizeToggle"
                                  group
                                  dense
                                  class="flex-wrap"
                                >
                                  <v-btn
                                    v-for="size in sizes[index]"
                                    :key="size.code"
                                    small
                                    :value="size.id"
                                    @click="updateVariation(size, 'sizes')"
                                    >{{ size.code }}
                                  </v-btn>
                                </v-btn-toggle>
                              </div>
                            </div>
                            <div
                              v-if="
                                types.length &&
                                  types[index] &&
                                  types[index].length
                              "
                              class="product-size"
                            >
                              <p class="font-bold mb-2">Type</p>
                              <div class="product-size-btn d-flex">
                                <v-btn-toggle
                                  v-model="typeToggle"
                                  group
                                  dense
                                  class="flex-wrap"
                                >
                                  <v-btn
                                    v-for="type in types[index]"
                                    :key="type"
                                    small
                                    :value="type"
                                    @click="updateVariation(type, 'type')"
                                    >{{ type }}
                                  </v-btn>
                                </v-btn-toggle>
                              </div>
                            </div>
                            <div
                              v-if="
                                weights.length &&
                                  weights[index] &&
                                  weights[index].length
                              "
                              class="product-size"
                            >
                              <p class="font-bold mb-2">Weight</p>
                              <div class="product-size-btn d-flex">
                                <v-btn-toggle
                                  v-model="weightToggle"
                                  group
                                  dense
                                  class="flex-wrap"
                                >
                                  <v-btn
                                    v-for="weight in weights[index]"
                                    :key="weight"
                                    small
                                    :value="weight"
                                    @click="updateVariation(weight, 'weight')"
                                    >{{ weight }}
                                  </v-btn>
                                </v-btn-toggle>
                              </div>
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          v-if="product && product[index]"
                          lg="4"
                          sm="4"
                          md="4"
                          cols="4"
                        >
                          <div class="product-quantity">
                            <p class="font-bold mb-2">Quantity</p>
                            <div
                              v-if="activeVariation"
                              class="products-count product-update"
                            >
                              <button
                                type="button"
                                :disabled="quantity[index] == 1"
                                @click="reduceQty()"
                              >
                                <v-icon small color="black">mdi-minus</v-icon>
                              </button>
                              <input
                                id="nov"
                                type="number"
                                :v-model="quantity[index]"
                                :value="quantity[index]"
                                max="15"
                                min="1"
                              />
                              <button
                                type="button"
                                :disabled="
                                  quantity[index] >= 15 ||
                                    quantity[index] >=
                                      activeVariation[index].stocks
                                "
                                @click="addQty()"
                              >
                                <v-icon small color="black">mdi-plus</v-icon>
                              </button>
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          v-if="product && product[index]"
                          lg="4"
                          sm="4"
                          md="4"
                          cols="4"
                        >
                          <p class="font-bold mb-2">Selling Price</p>

                          <v-text-field
                            label="Selling Price"
                            v-model="activeVariation[index].selling_price"
                            outlined
                            dense
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col lg="6" sm="6" md="4" cols="6">
                          <v-text-field
                            v-model="editedItem.deliveryCharge"
                            outlined
                            dense
                            :rules="[rules.required]"
                            label="Delivery Charge *"
                          ></v-text-field>
                        </v-col>

                        <v-col lg="6" sm="6" md="4" cols="6">
                          <v-autocomplete
                            label="Payment Method *"
                            v-model="editedItem.payment_method"
                            outlined
                            dense
                            :items="paymentMethodList"
                            :rules="[rules.required]"

                          ></v-autocomplete>
                        </v-col>

                        <v-col lg="12" sm="12" md="12" cols="12">
                          <v-textarea
                            v-model.lazy="editedItem.note"
                            label="Note"
                            outlined
                            dense
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        align-end
                        color="primary"
                        rounded
                        class="px-6 mt-4"
                        :disabled="!valid"
                        :loading="loading"
                        @click="save()"
                        >Save and Submit
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredOrders"
            :search="search"
            item-key="id"
            class="elevation-0"
            single-expand
            show-expand
            :expanded.sync="expanded"
          >
            <template v-slot:item.created_at="{ item }">
              {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col lg="4">
                    <v-card class="my-4 mx-4">
                      <v-card-text>
                        <h3>Shipping Address</h3>
                        <v-divider class="my-2"></v-divider>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Name</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.shipping && item.shipping.name }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Phone</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.shipping && item.shipping.phone }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Country</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{
                              item.shipping.country &&
                                item.shipping.country.name
                            }}
                          </p>
                        </div>
                        <!-- <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Province</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.shipping && item.shipping.state }}
                          </p>
                        </div> -->
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">District</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.shipping.district }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">City</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.shipping.city }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Area</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.shipping.area }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Street</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.shipping.street_address }}
                          </p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col lg="4">
                    <v-card class="my-4">
                      <v-card-text>
                        <h3>Order Note</h3>
                        <v-divider class="my-2"></v-divider>
                        <div
                          v-if="item.note"
                          class="d-flex align-items-center "
                        >
                          {{ item.note }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-left align-items-center" :id="item.id">
                <div class="my-2">
                  <v-btn
                    color="error"
                    small
                    dark
                    @click="
                      dialog = true;
                      orderItem = item.id;
                    "
                  >
                    <v-icon dark size="14" class="pr-2">mdi-eye</v-icon>
                    View Products
                  </v-btn>
                </div>
              </div>
            </template>
            <template
              v-if="type === 'New' || 'Pending Custom'"
              v-slot:item.status="{ item }"
            >
              <v-select
                v-if="type === 'New' && item.status === 'New'"
                v-model="item.status"
                :items="status"
                dense
                @change="updateCustomOrder(item)"
              ></v-select>
              <v-select
                v-if="type === 'Pending Custom'"
                v-model="item.status"
                :items="status"
                dense
                @change="updateCustomOrder(item)"
              ></v-select>
              <p v-if="type === 'New' && item.status !== 'New'">
                {{ item.status }}
              </p>
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="dialog" max-width="1000px">
          <v-card elevation="5">
            <v-card-title
              >Product Details
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="product_headers"
              :items="orderedProducts"
              :search="search"
            >
              <template v-slot:item.pname="{ item }">
                <a
                  v-if="item.product.category"
                  :href="
                    url(
                      'product/' +
                        item.product.category.slug +
                        '/' +
                        item.product.id
                    )
                  "
                  target="_blank"
                >
                  {{ item.product.name }}
                </a>
              </template>
              <template v-slot:item.quantity="{ item }">
                {{ item.qty }}
              </template>
              <template v-slot:item.total="{ item }">
                {{ item.buying_unit_price }}
              </template>
              <template v-slot:item.vname="{ item }">
                {{ item.product.vendor && item.product.vendor.business_name }}
              </template>
              <template v-slot:item.vphone="{ item }">
                {{ item.product.vendor && item.product.vendor.office_phone }}
              </template>

              <template v-slot:item.variations="{ item }">
                <p class="mb-0">
                  Color:
                  {{
                    item.variation.colors ? item.variation.colour.name : "----"
                  }}
                </p>
                <p class="mb-0">
                  Size:
                  {{ item.variation.size ? item.variation.sizes.code : "----" }}
                </p>
                <p class="mb-0">
                  Type: {{ item.variation.type ? item.variation.type : "----" }}
                </p>
                <p class="mb-0">
                  Weight:
                  {{ item.variation.weight ? item.variation.weight : "----" }}
                </p>
              </template>
            </v-data-table>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { dateMixin } from "../../mixin/helperMixin";
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  mixins: [dateMixin],
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      paymentMethodList: [
        "COD",
        "Esewa",
        "Imepay",
        "Prabhu Pay",
        "Khalti",
        "Other",
      ],
      orderedProduct: [{ productUrl: "", variations: "" }],
      variation: [],
      variations: [],
      index: 0,
      productUrl: [""],
      dialog: false,
      valid: false,
      addDialog: false,
      editedItem: {},
      search: "",
      orderItem: [],
      rules: {
        required: (v) => !!v || "This field is required.",
      },
      expanded: [],
      status: ["New", "Processing", "Delivered", "Cancelled"],

      product_headers: [
        { text: "S.N.", value: "sn" },
        { text: "Product Name", value: "pname" },
        { text: "Variation", value: "variations" },
        { text: " Quantity", value: "quantity" },
        { text: " Price", value: "total" },
        { text: "Seller Name", value: "vname" },
        { text: "Seller Phone", value: "vphone" },
        { text: "Status", value: "status" },
      ],
      cities: "",
      areas: ["Inside RingRoad", "Outside RingRoad"],
      country: "Nepal",
      colorToggle: 0,
      eggless: 0,
      sugarless: 0,
      sizeToggle: 0,
      typeToggle: 0,
      weightToggle: 0,
      quantity: [],
      colorPatches: [],
      sizes: [],
      types: [],
      weights: [],
      activeVariation: [{}],
      loading: false,
      wishlistLoading: false,
      buynowLoading: false,
      product: [],
      deliveryCharge: 0,
      count: 0,
    };
  },
  watch: {
    variations() {
      this.setVariations();
    },
  },
  computed: {
    ...mapState({
      districts: (state) => state.districtsOfNepal,
      states: (state) => state.statesOfNepal,
    }),
    filteredOrders() {
      return this.orders.map((item, index) => ({
        ...item,
        sn: index + 1,
        order_code: item.code,
        customer_name: item.shipping.name,
        customer_phone: item.shipping.phone,
        coupons: item.coupon ? item.coupon.code : "NA",
        delivery_price: item.delivery_charge,
        payment_type: item.payment_method,
        total_price: "Rs." + item.total,
        ordered_date: item.order && item.order.created_at,
      }));
    },
    orderedProducts() {
      const orders = this.orders.filter(
        (item) => item.id === this.orderItem
      )[0];
      if (orders) {
        return orders.custom_order_detail.map((item, index) => ({
          ...item,
          sn: index + 1,
          vname: item.product.vendor.business_name,
        }));
      } else {
        return [];
      }
    },
    filteredDistrict() {
      if (this.editedItem.state) {
        const dist = this.districts.filter(
          (item) => item.state === this.editedItem.state
        );
        if (dist.length) return dist[0].districts;
        else console.log(this.editedItem.state);
        return [];
      } else {
        return [];
      }
    },
    filteredArea() {
      let data;
      if (this.editedItem.state === "Bagmati State") {
        if (this.editedItem.district === "Bhaktapur") {
          data = ["Outside RingRoad"];
        } else if (
          this.editedItem.district === "Kathmandu" ||
          this.editedItem.district === "Lalitpur"
        ) {
          data = ["Inside RingRoad", "Outside RingRoad"];
        } else {
          data = ["Inside RingRoad", "Outside RingRoad", "Outside Valley"];
        }
      } else {
        data = ["Outside Valley"];
      }
      return data;
    },
    // filteredCity() {
    //   if (this.editedItem.district) {
    //     const cities = this.shippingCities.filter(
    //       (item) => item.district === this.editedItem.district
    //     );
    //     if (cities.length) return cities[0].cities;
    //     else return ["Default"];
    //   } else {
    //     return [];
    //   }
    // },
  },
  methods: {
    ...mapActions([
      "updateCustomOrderStatus",
      "getProductVariation",
      "customOrder",
    ]),
    url(path) {
      if (process.env.APP_URL) {
        return process.env.APP_URL + path;
      } else {
        return "https://sajhadeal.com/" + path;
      }
    },
    updateCustomOrder(item) {
      let temp = this.orders.filter((value) => value.id === item.id)[0];
      this.editedIndex = this.orders.indexOf(temp);
      let data = { id: item.id, status: item.status };
      this.updateCustomOrderStatus(data)
        .then((response) => {
          this.$store.commit("setCustomStatus", response.data);

          // Object.assign(temp.response.data);
        })
        .catch((error) => {
          console.log(error.status);
          // alert(error);
        });
      this.dialog = false;
      this.product = {};
    },

    save() {
      this.loading = true;
      // Object.assign(this.orderedProducts,this.quantity);
      const newArr = this.orderedProduct.map((item, index) => ({
        qty: this.quantity[index],
        variation: item.variation,
        unit_price: this.activeVariation[index].selling_price,
        otherDetail: this.editedItem,
      }));
      let data = [newArr, this.editedItem];
      if (this.$refs.form.validate())
        this.customOrder(data)
          .then(() => {
            this.variations = [];
            this.colorPatches = [];
            this.weight = [];
            this.activeVariation = [];
            this.sizes = [];
            this.product = [];
            this.types = [];
            this.quantity = [];
            this.colorToggle = 0;
            this.eggless = 0;
            this.sugarless = 0;
            this.sizeToggle = 0;
            this.typeToggle = 0;
            this.weightToggle = 0;
            Swal.fire(
              "Success!",
              "Custom Order Placed Successfully",
              "success"
            );
            this.addDialog = false;
            this.$refs.form.reset();
            this.loading = false;
          })
          .catch(() => {
            Swal.fire("Oops...", "Something went wrong!", "error");
          });
    },

    addOrderedProduct() {
      this.orderedProduct.push({ productUrl: "", variations: "" });
      this.index = this.orderedProduct.length - 1;
    },
    deleteOrderedProduct(index) {
      this.product.splice(index, 1);
      if (this.product.length < 1) {
        this.orderedProduct[0].productUrl = "";
        this.orderedProduct[0].variation = {};
      } else {
        this.orderedProduct.splice(index, 1);
        this.orderedProducts.splice(index, 1);
      }

      this.activeVariation.splice(index, 1);
      this.variations.splice(index, 1);
      this.index = this.orderedProduct.length - 1;
    },
    submitUrl(index) {
      this.count += 1;
      let arr = this.orderedProduct[index].productUrl.split("/");
      let id = arr[arr.length - 1];
      this.getProductVariation(id).then((res) => {
        this.variations.push(res.data.variations);
        this.product.push(res.data);
        this.quantity.push(parseInt(1));
        this.buildOrderObject();
        if (JSON.stringify(this.activeVariation[this.index]) === "{}") {
          this.setVariations();
        }
      });
    },
    updateVariation(val, type) {
      // if (type === 'colors') {
      // this.$emit('updatephoto', val.id)
      // }
      switch (type) {
        case "colors":
          this.variations[this.index].forEach((variation) => {
            if (
              variation["colour"].id === val.id &&
              (variation.size
                ? variation.sizes.id ===
                  this.activeVariation[this.index].sizes.id
                : true) &&
              variation.weight === this.activeVariation[this.index].weight &&
              variation.type === this.activeVariation[this.index].type
              // variation.sugarless === this.activeVariation.sugarless &&
              // variation.eggless === this.activeVariation.eggless
            ) {
              this.activeVariation[this.index] = variation;
              this.orderedProduct[this.index].variation = variation;
            }
          });
          break;
        case "sizes":
          this.variations[this.index].forEach((variation) => {
            if (
              variation[type].id === val.id &&
              (variation.colors
                ? variation.colour.id ===
                  this.activeVariation[this.index].colour.id
                : true) &&
              variation.weight === this.activeVariation[this.index].weight &&
              variation.type === this.activeVariation[this.index].type
              // variation.sugarless === this.activeVariation.sugarless &&
              // variation.eggless === this.activeVariation.eggless
            ) {
              this.activeVariation[this.index] = variation;
              this.orderedProduct[this.index].variation = variation;
            }
          });
          break;
        case "type":
          this.variations[this.index].forEach((variation) => {
            if (
              variation[type] === val &&
              (variation.colors
                ? variation.colour.id ===
                  this.activeVariation[this.index].colour.id
                : true) &&
              variation.weight === this.activeVariation[this.index].weight &&
              (variation.size
                ? variation.sizes.id ===
                  this.activeVariation[this.index].sizes.id
                : true)
              // variation.sugarless === this.activeVariation.sugarless &&
              // variation.eggless === this.activeVariation.eggless
            ) {
              this.activeVariation[this.index] = variation;
              this.orderedProduct[this.index].variation = variation;
            }
          });
          break;
        case "weight":
          this.variations[this.index].forEach((variation) => {
            if (
              variation[type] === val &&
              (variation.colors
                ? variation.colors.id === this.activeVariation.colors.id
                : true) &&
              (variation.size
                ? variation.size.id === this.activeVariation.size.id
                : true) &&
              variation.type === this.activeVariation.type
              // variation.sugarless === this.activeVariation.sugarless &&
              // variation.eggless === this.activeVariation.eggless
            ) {
              this.activeVariation[this.index] = variation;
              this.orderedProduct[this.index].variation = variation;
            }
          });
          break;
      }
    },

    setVariations() {
      if (this.variations[this.index]) {
        // Define Color Patches
        this.colorPatches[this.index] = this.removeDuplicatesFromJson(
          this.variations[this.index],
          "name",
          "colour"
        );

        // Define Size
        this.sizes[this.index] = this.removeDuplicatesFromJson(
          this.variations[this.index],
          "code",
          "sizes"
        );

        // Define Type
        this.types[this.index] = this.removeDuplicatesFromJson(
          this.variations[this.index],
          "type",
          "type"
        );

        // Define Weight
        this.weights[this.index] = this.removeDuplicatesFromJson(
          this.variations[this.index],
          "weight",
          "weight"
        );

        // Define varation
        if (this.variations[this.index].length !== 0) {
          this.activeVariation[this.index] = this.variations[this.index][0];
          this.orderedProduct[this.index].variation = this.variations[
            this.index
          ][0];

          this.colorToggle = this.activeVariation[this.index].colour
            ? this.activeVariation[this.index].colour.id
            : 0;
          this.sizeToggle = this.activeVariation[this.index].sizes
            ? this.activeVariation[this.index].sizes.id
            : 0;
          this.typeToggle = this.activeVariation[this.index].type;
          this.weightToggle = this.activeVariation[this.index].weight;
        }

        if (this.activeVariation[this.index])
          this.eggless = this.activeVariation[this.index].eggless;
      }
    },
    removeDuplicatesFromJson(arr, prop, which) {
      return arr.reduce((acc, cur) => {
        if (prop === "weight" || prop === "type") {
          if (!acc.find((ob) => ob === cur[which]) && cur[which]) {
            acc.push(cur[which]);
          }
        } else if (
          !acc.find((ob) => ob[prop] === cur[which][prop]) &&
          cur[which]
        ) {
          acc.push(cur[which]);
        }
        return acc;
      }, []);
    },

    addQty() {
      this.quantity.push(this.quantity[this.index]++);
      this.quantity.splice(this.index + 1, 1);
    },

    reduceQty() {
      this.quantity.push(this.quantity[this.index]--);
      this.quantity.splice(this.index + 1, 1);
    },
    addUnitPrice() {
      this.orderedProduct[this.index].unit_price = this.activeVariation[
        this.index
      ].selling_price;
    },

    buildOrderObject() {
      const newArr = this.orderedProduct.map((item, index) => ({
        qty: this.quantity[index],
        variation: item.variation,
        unit_price: this.activeVariation[index].selling_price,
      }));
      return newArr;
    },
    setArea() {
      switch (this.editedItem.district) {
        case "Bhaktapur":
          this.editedItem.area = "Outside RingRoad";
          this.editedItem.city = "Default";
          break;

        case "Kathmandu":
          this.editedItem.area = "Outside RingRoad";
          this.editedItem.city = "Default";
          break;

        case "Lalitpur":
          this.editedItem.area = "Inside RingRoad";
          this.editedItem.city = "Default";
          break;

        default:
          this.editedItem.area = "Outside Valley";
      }
    },
  },
};
</script>

<style>
.products-count {
  border-radius: 0.5rem;
  margin: 0.5rem 0;
}

.product-update {
  display: flex;
}

.product-update button {
  width: 25px;
  height: 25px;
  background-color: #fff;
  border: 1px solid #c10827de;
  border-radius: 0.5rem;
}

.product-update:hover,
.product-update:focus {
  opacity: 0.9;
}

/* .product-update:first-of-type{
            border-radius: 0.25rem 0 0 0.25rem;
    }
    .product-update:last-of-type{
            border-radius: 0 0.25rem 0.25rem 0;
    } */
.product-update input {
  background-color: #fff;
  width: 3rem;
  text-align: center;
  padding-left: 10px;
  /* border-top: 1px solid #c10827de;
        border-bottom: 1px solid #c10827de; */
  font-weight: bold;
  /* border-radius: 0.5rem; */
}
</style>
