<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="teal"
        ></v-progress-linear>
        <v-data-table
            :headers="headers"
            :items="getSubSubCategory"
            :search="search"
            class="elevation-5 pt-2"
        >
          <template #top>
            <v-toolbar
                flat
            >
              <v-toolbar-title>Sub SubCategories Details</v-toolbar-title>

              <v-dialog
                  v-model="dialog"
                  max-width="500px"

              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      small
                      class="ml-6"
                      v-bind="attrs"
                      v-on="on"
                      rounded
                  >
                    Create New Sub SubCategory
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text class="pa-0">
                    <v-container>
                      <v-form ref="form" v-model="valid">
                      <v-row>
                        <v-col
                            cols="12"
                            class="pb-0"
                        >
                          <v-text-field
                              v-model="editedItem.name"
                              :rules="[rules.required]"
                              outlined
                              dense
                              label="Sub SubCategory Name">
                            </v-text-field>
                          <v-text-field
                              v-model="editedItem.title"
                              outlined
                              dense
                              label="Sub SubCategory Title"
                              :rules="[rules.required]"
                          ></v-text-field>
                          <v-autocomplete
                              :items="subcategory"
                              v-model="editedItem.cat_id"
                              item-text="name"
                              item-value="id"
                              outlined
                              dense
                              label="Select SubCategory"
                          ></v-autocomplete>
                          <v-textarea
                              v-model="editedItem.description"
                              outlined
                              dense
                              label="Sub Sub Category Description"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="px-6 my-2"
                        :disabled="!valid"
                        rounded
                        dark
                        @click="save"
                    >
                      Save &amp; Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
              ></v-text-field>
              <v-dialog v-model="dialogDelete" max-width="400px">
                <v-card>
                  <v-card-text class="pt-4 text-center">
                    <v-icon color="error" size="50">mdi-close-circle</v-icon>
                    <h2 class="font-bold py-4">Are you sure want to delete this Item?</h2>
                    <h4 class="font-bold text-left">Please be aware that you cannot revert this item in future.</h4> 
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="px-8" rounded dark @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ formatDate(item.created_at) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from 'moment'
import Swal from "sweetalert2";


export default {
  data: () => ({
    dialog: false,
    loading:false,
    dialogDelete: false,
    search: '',
    rules: {
      required: (v) => !!v || "This field is required.",
    },
    valid: false,
    sub_subcategories: [],
    category: ['Home and Electronics', 'Bakery'],
    subcategory: ['Home and Electronics', 'Bakery'],
    headers: [
      {text: 'S.N.', value: 'sn'},
      {text: 'Sub Sub Category', value: 'name'},

      {
        text: 'SubCategory Name',
        value: 'subcategory_name',
      },
      // {text: 'Created Date', value: 'created_at'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    required: [
      v => !!v || 'This Field is required',
    ],
    editedIndex: -1,
    editedItem: {
      name: '',
      category_name: '',
      subcategory_name: '',
      created_at: '',
    },
    defaultItem: {
      name: '',
      category_name: '',
      subcategory_name: '',
      created_at: '',
    },
  }),

  computed: {
    ...mapGetters({
      getAllSubSubCategories: "getSubSubCategories",
      getAllSubCategories: "getSubCategories",
    }),
    formTitle() {
      return this.editedIndex === -1 ? 'Add New Sub SubCategory' : 'Edit Sub SubCategory Details'
    },
    getSubSubCategory() {
      return this.sub_subcategories.map((item, index) => ({
        ...item,
        sn: index + 1,
        name: item.name,
        title: item.title,
        description: item.description,
        subcategory_name: item.sub_category && item.sub_category.name,
      }));
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },



  methods: {
    ...mapActions(["getSubCategories", "storeSubSubCategory", "updateSubSubCategory", "deleteSubSubCategory", "getSubSubCategories"]),


    editItem(item) {
      let temp = this.sub_subcategories.filter(subcategory => subcategory.id === item.id)[0]
      this.editedIndex = this.sub_subcategories.indexOf(temp)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      let temp = this.sub_subcategories.filter(subcategory => subcategory.id === item.id)[0]
      this.editedIndex = this.sub_subcategories.indexOf(temp)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true},
    formatDate(value) {
      return moment(value).format("MMMM DD YYYY")
    },

    deleteItemConfirm() {
     this.loading=true;
      this.deleteSubSubCategory(this.editedItem.id).then(() => {
        this.sub_subcategories.splice(this.editedIndex, 1);
        this.$store.commit("setSubSubCategories", this.sub_subcategories);
        Swal.fire('Success!', 'Sub SubCategory Deleted Successfully!', 'success')
        this.loading=false;
        this.editedIndex = -1

      }).catch(() => {
        this.loading=false;
        Swal.fire('Oops...', 'Something went wrong!', 'error')
        this.editedIndex = -1

      });
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        // this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },

    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.editedIndex > -1) {
          let temp =Object.assign({},this.sub_subcategories[this.editedIndex], this.editedItem);
          let temp1 = this.subcategory.filter(subcat => subcat.id === this.editedItem.cat_id)[0];
          this.updateSubSubCategory(this.editedItem).then((response) => {
            Object.assign(this.sub_subcategories[this.editedIndex].sub_category, temp1);
            Object.assign(this.sub_subcategories[this.editedIndex], temp)
            this.$store.commit("setSubSubCategories", this.sub_subcategories);
            this.loading=false
            this.editedIndex = -1
            Swal.fire('Success!', 'Sub SubCategory Updated Successfully!', 'success')
              this.$refs.form.reset()
            console.log(response.status);
          }).catch(() => {
            this.loading=false
            this.editedIndex = -1
            Swal.fire('Oops...', 'Something went wrong!', 'error')
          });

        } else {
          this.storeSubSubCategory(this.editedItem).then((response) => {
            this.loading=false
            this.editedIndex = -1
            Swal.fire('Success!', 'Sub SubCategory Added Successfully!', 'success')
            this.$refs.form.reset()
            console.log(response.status);
          }).catch(() => {
            this.loading=false
            this.editedIndex = -1
            Swal.fire('Oops...', 'Something went wrong!', 'error')
          });
        }
        this.close()
      }
    },

  },
  mounted() {
    if (this.getAllSubCategories.length === 0) {
      this.getSubCategories().then((response) => {
        this.subcategory = response;
        console.log(response.status);
      });
    } else {
      this.subcategory = this.getAllSubCategories;
    }
    if (this.getAllSubSubCategories.length === 0) {
      this.getSubSubCategories().then((response) => {
        this.sub_subcategories = response;
      });
    } else {
      this.sub_subcategories = this.getAllSubSubCategories;
    }
  }
}
</script>
<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0 !important;
}
</style>