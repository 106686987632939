<template>
  <v-container>
    <v-card>
      <v-card-title>Edit Seller</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-stepper v-model="step" vertical flat>
          <v-stepper-step editable aria-sort="" :complete="step > 1" :step="1">
            Basic Information
            <small>Feel free to add basic details of your Company</small>
<!--            {{ selectedVendors }}-->
          </v-stepper-step>
          <v-stepper-content :step="1">
            <general-details
                :fillable="fillable"
                :company-details="selectedVendors"
                @updateStep="updateStep"
                @updatename="updateName"
                @updatephone="updatePhone"
            ></general-details>
          </v-stepper-content>

          <v-stepper-step editable aria-sort="" :complete="step > 2" :step="2">
            Add the Addresses
            <small>We collect your addresses for convienience</small>
          </v-stepper-step>
          <v-stepper-content :step="2">
            <address-details
                :address_details="selectedVendors.address_details"
                @updateStep="updateStep"
                :name="selectedVendors.owner_name"
                :phone="selectedVendors.phone"
                @update="update"
            ></address-details>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import Swal from "sweetalert2";

import AddressDetails from "../../../components/seller/add/AddressDetails.vue";
import GeneralDetails from "../../../components/seller/add/GeneralDetails.vue";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  components: {GeneralDetails, AddressDetails},
  data() {
    return {

      itemId: null,
      fillable:false,
      step: 1,
      name: "",
      phone: "",

    };
  },
  methods: {
    ...mapActions(["updateVendor"]),

    updateStep(val) {
      if (val === 1) {
        this.step = 1;
      } else {
        this.step += 1;
      }
    },
    update() {

      this.updateVendor({id: this.itemId})
          .then((res) => {
            console.log(res);
           this.$store.commit("setVendor", res);
            Swal.fire("Success", 'Seller Updated Successfully', "success");
          })
          .catch(() => {
            Swal.fire("Error", 'Something Went Wrong', "error");
          });
    },
    updateName(val) {
      this.name = val;
    },
    updatePhone(val) {
      this.phone = val;
    },
  },
  computed: {
    ...mapState({
      sellers: (state) => state.sellers,
    }),
    ...mapGetters({
      seller: "getSellersList",
    }),
    selectedVendors() {
      let verified = this.sellers.filter((item) => item.id === this.$route.params.id)[0];
      verified = {...verified, ...verified.details};
      const vendor = Object.assign(verified, {
        owner_name:  verified.name,
        phone:  verified.phone,
        office_phone:  verified.details.office_phone,
        email:  verified.email,
        title:  verified.meta_title,
        address_details: [
          {
            business_address: {
              street_address: verified.business_address && verified.business_address.street_address,
              district: verified.business_address && verified.business_address.district,
              area: verified.business_address && verified.business_address.area,
              city: verified.business_address && verified.business_address.city,
              state: verified.business_address && verified.business_address.state,
              country: verified.business_address && verified.business_address.country.name,

            }
          },
          {
            warehouse_address: {
              street_address: verified.warehouse_address && verified.warehouse_address.street_address,
              district: verified.warehouse_address && verified.warehouse_address.district,
              area: verified.warehouse_address && verified.warehouse_address.area,
              city: verified.warehouse_address && verified.warehouse_address.city,
              state: verified.warehouse_address && verified.warehouse_address.state,
              country: verified.warehouse_address && verified.warehouse_address.country.name,
            }
          },
          {
            return_address: {
              street_address: verified.return_address && verified.return_address.street_address,
              district: verified.return_address && verified.return_address.district,
              area: verified.return_address && verified.return_address.area,
              city: verified.return_address && verified.return_address.city,
              state: verified.return_address && verified.return_address.state,
              country: verified.return_address && verified.return_address.country.name,
            }
          },
        ],

      })
      return vendor;
      // return verified.map((item) => ({
      //   ...item,
      //   owner_name:'Aakash',
      // }));
    }

  },

  mounted() {
    this.itemId = this.$route.params.id;

  }
};
</script>
