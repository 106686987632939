<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title
          >Sellers Report
            <!-- <v-btn
              color="primary"
              small
              class="ml-6"
              :to="{ name: 'addSeller' }"
              >Add a Seller</v-btn
            > -->
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
            ></v-text-field>
            <v-btn color="primary" class="ml-4" rounded @click="exportToExcel">Export to Excel</v-btn>
          </v-card-title>
          <v-data-table
              v-model="selected"
              :headers="headers"
              :items="verifiedVendors"
              item-key="code"
              class="elevation-0"
              :search="search"
              single-expand
              show-expand
              :expanded.sync="expanded"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col lg="4">
                    <v-card class="my-4 mx-4">
                      <v-card-text v-if="item.details.business_address">
                        <h3>Business Address</h3>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Name</p>
                          <p  class="mb-1 font-bold ml-auto">
                            {{ item.details.business_address.name }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Phone</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.business_address.phone }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Province</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.business_address.state }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">District</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.business_address.district }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">City</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.business_address.city }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Area</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.business_address.area }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Street</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.business_address.street }}
                          </p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col lg="4">
                    <v-card class="my-4">
                      <v-card-text v-if="item.details.warehouse_address">
                        <h3>Warehouse Address</h3>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Name</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.warehouse_address.name }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Phone</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.warehouse_address.phone }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Province</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.warehouse_address.state }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">District</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.warehouse_address.district }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">City</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.warehouse_address.city }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Area</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.warehouse_address.area }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Street</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.warehouse_address.street }}
                          </p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col lg="4">
                    <v-card class="my-4">
                      <v-card-text v-if="item.details.return_address">
                        <h3>Return Address</h3>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Name</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.return_address.name }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center ">
                          <p class="mb-1 font-bold">Phone</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.return_address.phone }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Province</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.return_address.state }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">District</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.return_address.district }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">City</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.return_address.city }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Area</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.return_address.area }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="mb-1 font-bold">Street</p>
                          <p class="mb-1 font-bold ml-auto">
                            {{ item.details.return_address.street }}
                          </p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:item.logo="{ item }">
              <v-dialog :id="'dialog' + item.id" width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar size="60" v-bind="attrs" v-on="on">
                    <img
                        :src="item.logo"
                        alt="banner-image"
                        class="img-thumbnail my-2"
                        width="100"
                        height="60"
                    />
                  </v-avatar>
                </template>

                <v-card>
                  <v-card-title style="background-color: #163691; color: white"
                  >Shop Logo
                  </v-card-title>
                  <img
                      :src="item.logo"
                      width="100%"
                  />
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.documents="{ item }">
              <v-dialog :id="'dialog' + item.id" width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar size="60" v-bind="attrs" v-on="on">
                    <img
                        :src="item.documents"
                        alt="banner-image"
                        class="img-thumbnail my-2"
                        width="100"
                        height="60"
                    />
                  </v-avatar>
                </template>

                <v-card>
                  <v-card-title style="background-color: #163691; color: white"
                  >Shop Registration Document
                  </v-card-title>
                  <img
                      :src="item.document"
                      width="100%"
                  />
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";
import moment from "moment";
import {json2excel} from 'js2excel';

export default {
  data() {
    return {
      search: "",
      dialogDelete: false,
      selected: [],
      expanded: [],
      editedItem: {},
      editedIndex: -1,
      headers: [
        {text: "S.N.", value: "sn"},
        {
          text: "Seller Name",
          align: "start",
          sortable: false,
          value: "seller_name",
        },
        {text: "Shop Logo", value: "logo"},
        {text: "Shop Name", value: "shop_name"},
        {text: "Email", value: "email"},
        {text: "Phone", value: "phone"},
        {text: "Pan/Vat No", value: "pan_no"},
        {text: "Document", value: "documents"},
        {text: "Request Date", value: "created_at"},
      ],
    };
  },
  computed: {
    ...mapState({
      sellers: (state) => state.sellers,
    }),
    verifiedVendors() {
      const verified = this.sellers.filter((item) => item.details.is_verified === 1);
      return verified.map((item, index) => ({
        ...item,
        sn: index + 1,
        seller_name:item.name,
        logo: item.details.logo,
        shop_name: item.details.business_name,
        email:  item.email,
        phone:  item.phone,
        pan_no: item.details.pan,
        documents: item.details.document,
        status: item.user && !!item.user.emailverified,
        created_at: moment(item.created_at).format("MM DD YYYY"),
      }));
    },
  },
  methods: {
    ...mapActions(["getAllSellers"]),
    exportToExcel() {
      // this will be export a excel and the file's name is user-info-data.xlsx
      // the default file's name is excel.xlsx
      let data = this.verifiedVendors;
      try {
        json2excel({
          data,
          name: 'verified-seller-report',
          // formateDate: 'yyyy/mm/dd'
        });
      } catch (e) {
        alert("Something Went Wrong. Try Again!");
      }
    }

  },
  mounted() {
    if (!this.sellers.length) {
      this.getAllSellers();
    }
  },
};
</script>

<style></style>
