<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title
            >Seller Participation
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="getAllParticipationList"
            :search="search"
          >
            <template v-slot:item.featured="{ item }">
              <div class="d-flex justify-left align-items-center">
                <div class="pb-4">
                  <v-switch
                    v-model="item.is_featured"
                    @change="updateParticipantsStatus(item)"
                    color="success"
                    hide-details
                  ></v-switch>
                </div>
              </div>
            </template>
            <template v-slot:item.status="{ item }">
              <div class="d-flex justify-left align-items-center">
                <div class="pb-4">
                  <v-switch
                      v-model="item.is_accepted"
                      @change="updateParticipantsStatus(item)"
                      color="success"
                      hide-details
                  ></v-switch>
                </div>
              </div>
            </template>
            <template v-slot:item.homepage="{ item }">
              <div class="d-flex justify-left align-items-center">
                <div class="pb-4">
                  <v-switch
                    v-model="item.id"
                    @change="updateParticipantsStatus(item)"
                    color="success"
                    hide-details
                  ></v-switch>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      headers: [
        { text: "S.N.", value: "sn" },
        {
          text: "Deal Title",
          align: "start",
          sortable: false,
          value: "deal_title",
        },
        { text: "Seller Name", value: "seller_name" },
        { text: "Product Name", value: "product_name" },
        // { text: "Quantity", value: "quantity" },
        { text: "Discount", value: "discount" },
        { text: "Discount Type", value: "discount_type" },
        { text: "Status", value: "status" },
        { text: "Featured", value: "featured" },
        { text: "Date", value: "created_at", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      participants: (state) => state.participationList,
    }),

    getAllParticipationList() {
      return this.participants.map((item, index) => ({
        ...item,
        sn: index + 1,
        deal_title: item.flashdeal && item.flashdeal.title,
        seller_name: item.vendor && item.vendor.name,
        product_name: item.products && item.products.name,
        quantity: item.quantity,
        discount: item.discount,
        discount_type: item.discount_type,


      }));
    },
  },
  methods: {
    ...mapActions(["getAllPartipants","updateParticipantStatus"]),

    updateParticipantsStatus(item) {
     let status= item.is_accepted?1 :0;
     let featured=item.is_featured?1 :0;
     alert(featured);
      let temp = this.participants.filter(
          (participant) => participant.id === item.id
      )[0];
      this.editedIndex = this.participants.indexOf(temp);

      this.updateParticipantStatus({id:item.id,is_accepted:status,is_featured:featured})
          .then(() => {
            this.participants[this.editedIndex].is_featured=featured;
            this.participants[this.editedIndex].is_accepted=status;
            this.$store.commit("setParticipationList", this.participants);
            this.editedIndex = -1;
          })
          .catch(() => {
            Swal.fire("Oops...", "Something went wrong!", "error");
            this.editedIndex = -1;
          });
    },

  },
  mounted() {
    if (!this.participants.length) {
      this.getAllPartipants();
    }
  },
};
</script>

<style></style>
