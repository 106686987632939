<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title
            >Order Pick Up Details
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="filteredDetails"
            :search="search"
          >
     <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-left align-items-center" :id="item.id">
                <div class="my-2">

                  <v-btn
                      v-if="item.pickup===null"
                    color="primary"
                    class="my-2"
                    small
                    dark
                    @click="openDialog(item)"
                  >
                    Assign Rider
                  </v-btn>
                  <v-btn
                      v-else
                      color="success"
                      class="my-2"
                      small
                      dark

                  >
                    {{item.pickup.rider.name}}
                  </v-btn>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              Assign Driver
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-autocomplete
                  :items="riders"
                  v-model="editedItem.rider"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  label="Select Rider"
                ></v-autocomplete>
                <v-btn  @click="assignRider()" color="primary">Submit </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import {AddressMixin} from "../../../mixin/helperMixin";

export default {
  mixins:[AddressMixin],
  data() {
    return {
      dialog: false,
      editedItem:{},
      editedIndex:-1,
      search: "",
      // riders: ["Ram Sharma", "Shyam Sharma", "Hari Sharma"],
      headers: [
        { text: "S.N.", value: "sn" },
        {
          text: "Seller Name",
          align: "start",
          sortable: false,
          value: "seller_name",
        },
        { text: "Order code", value: "order_id" },
        { text: "Phone", value: "phone" },
        { text: "Product Name", value: "product_name" },
        { text: "Variant", value: "variant" },
        { text: "Quantity", value: "quantity" },
        // { text: "Pickup Address", value: "pickup_address" },
        // { text: "Received", value: "received" },
        { text: "Ordered Date", value: "ordered_date" },
        { text: "Actions", value: "actions" },
      ],
    };
  },

  computed: {
    ...mapGetters({
      pending: "getPendingOrder",
      riders:"getRiders",

    }),
    filteredDetails() {
        console.log(this.pending);
      return this.pending.map((item, index) => ({
        ...item,
        sn: index + 1,
        order_id: this.getOrderCode(item.order_id),
        // pickup_address:this.getAddress(item.vaddress),
        seller_name:item.product.vendor.name,
        phone:item.product.vendor.phone,
        product_name:item.product.name,
        variant:this.filterVariation(item.variation),
        quantity:item.qty,
        ordered_date: moment(item.created_at).format("DD MM YYYY"),
      }));
    },



  },
  methods: {
    getOrderCode(id){
   return  this.$store.state.orders.filter(item=>item.id===id)[0].code;
    },
    assignRider(){
      console.log(this.editedItem);
      let data={ordered_item_id:this.editedItem.id,status:'Picking Up',rider_id:this.editedItem.rider}
      this.assignRiders(data).then((res)=>{
        this.$store.commit("updateOrdered", res.data);
      }).catch(()=>{

      });
      this.dialog=false;
    },
    ...mapActions(["getAllOrders","getStaffs","assignRiders"]),
    filterVariation(val) {
      const color = val.colour ? "Color: " + val.colour.name : "";
      const size = val.sizes ? "Size: " + val.sizes.code : "";
      const type = val.type && val.type !== 'null' ? "Type: " + val.type : "";
      const weight = val.weight && val.weight !== 'null' ? "Weight: " + val.weight : "";
      return color + " " + size + " " + type + " " + weight;
    },
    openDialog(item){
      let temp = this.pending.filter(value => value.id === value.id)[0]
      this.editedIndex = this.pending.indexOf(temp)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    }
  },
  created() {
    if (!this.pending.length) {
      this.getAllOrders();
    }
    if(!this.riders.length){
      this.getStaffs();
    }


  },
};
</script>

<style></style>
