<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="teal"
        ></v-progress-linear>
        <v-data-table
          :headers="headers"
          :items="getSubCategory"
          :search="search"
          class="elevation-5 pt-2"
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>SubCategories Details</v-toolbar-title>

              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    small
                    class="ml-6"
                    v-bind="attrs"
                    v-on="on"
                    rounded
                  >
                    Create New SubCategory
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text class="pa-0">
                    <v-container>
                      <v-form ref="form" v-model="valid">
                        <v-row>
                          <v-col cols="12" class="pb-0">
                            <v-text-field
                              v-model="editedItem.name"
                              outlined
                              dense
                              label="Sub Category Name"
                              :rules="[rules.required]"
                            >
                            </v-text-field>
                            <v-text-field
                              v-model="editedItem.title"
                              outlined
                              dense
                              label="Sub Category Title"
                              :rules="[rules.required]"
                            ></v-text-field>
                            <v-autocomplete
                              :rules="[rules.required]"
                              :items="category"
                              v-model="editedItem.cat_id"
                              item-text="name"
                              item-value="id"
                              outlined
                              dense
                              label="Select Category"
                            ></v-autocomplete>
                            <v-text-field
                              v-model="editedItem.order"
                              type="number"
                              min="1"
                              step="1"
                              outlined
                              dense
                              label="Order"
                              :rules="[rules.required, rules.min]"
                            ></v-text-field>
                            <v-file-input
                              @change="imageSelect($event)"
                              v-model="image"
                              label="Category Image"
                              accept="image/*"
                              outlined
                              prepend-icon=""
                              dense
                              :rules="[rules.required]"
                            ></v-file-input>
                            <v-textarea
                              v-model="editedItem.description"
                              outlined
                              dense
                              label="Sub Category Description"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="px-6 my-2"
                      rounded
                      dark
                      @click="save"
                      :disabled="!valid"
                    >
                      Save &amp; Continue
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
              ></v-text-field>
              <v-dialog v-model="dialogDelete" max-width="400px">
                <v-card>
                  <v-card-text class="pt-4 text-center">
                    <v-icon color="error" size="50">mdi-close-circle</v-icon>
                    <h2 class="font-bold py-4">
                      Are you sure want to delete this Item?
                    </h2>
                    <h4 class="font-bold text-left">
                      Please be aware that you cannot revert this item in
                      future.
                    </h4>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      class="px-8"
                      rounded
                      dark
                      @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template #[`item.image`]="{ item }">
            <v-dialog :id="'dialog' + item.id" width="600">
              <template v-slot:activator="{ on, attrs }">
                <img
                  :src="getImage(item.image)"
                  alt="banner-image"
                  class="img-thumbnail my-2"
                  width="100"
                  height="60"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-card>
                <v-card-title style="background-color: #163691; color: white"
                  >Image
                </v-card-title>
                <img :src="getImage(item.image)" width="100%" />
              </v-card>
            </v-dialog>
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ formatDate(item.created_at) }}
          </template>
          <template #[`item.is_featured`]="{ item }">
            <div class="d-flex justify-left align-items-center">
              <div class="pb-4">
                <v-switch
                  @click="updateIsFeatured(item)"
                  v-model="item.is_featured"
                  color="success"
                  hide-details
                ></v-switch>
              </div>
            </div>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { imageMixin } from "../../../../mixin/helperMixin";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  mixins: [imageMixin],
  data: () => ({
    dialog: false,
    loading: false,
    valid: false,
    rules: {
      required: (v) => !!v || "This field is required.",
    },
    search: "",
    dialogDelete: false,
    category: [],
    subcategories: [],
    cat_id: "",
    headers: [
      { text: "S.N.", value: "sn" },
      { text: "Sub Category", value: "name" },
      { text: "Image", value: "image" },
      {
        text: "Category Name",
        align: "start",
        sortable: false,
        value: "category_name",
      },
      // { text: "Created Date", value: "created_at" },
      { text: "Feature Category", value: "is_featured" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      category_name: "",
      created_at: "",
    },
    defaultItem: {
      name: "",
      category_name: "",
      created_at: "",
    },
  }),

  computed: {
    ...mapGetters({
      getAllSubCategories: "getSubCategories",
      getAllCategories: "getCategories",
    }),
    getSubCategory() {
      return this.subcategories.map((item, index) => ({
        ...item,
        sn: index + 1,
        name: item.name,
        title: item.title,
        description: item.description,
        category_name: item.category && item.category.name,
      }));
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Add New SubCategory"
        : "Edit SubCategory Details";
    },
    image() {
      if (this.editedItem.image) {
        if (this.editedItem.image instanceof Blob) {
          return this.editedItem.image;
        }
        let ext1 = this.editedItem.image.split(".").pop();
        return new File(
          [this.editedItem.image],
          this.editedItem.name + "image." + ext1,
          {
            type: "image/*",
          }
        );
      } else {
        return "";
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // subcategories(oldval, newval) {
    //   console.log(oldval);
    //   console.log(newval);
    // },
  },

  methods: {
    ...mapActions([
      "getSubCategories",
      "storeSubCategory",
      "updateSubCategory",
      "deleteSubCategory",
      "updateFeaturedSubCategory",
      "getCategories",
    ]),

    imageSelect(event) {
      this.editedItem.image = event;
    },

    formatDate(value) {
      return moment(value).format("MMMM DD YYYY");
    },

    editItem(item) {
      let temp = this.subcategories.filter(
        (subcategory) => subcategory.id === item.id
      )[0];
      this.editedIndex = this.subcategories.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      let temp = this.subcategories.filter(
        (subcategory) => subcategory.id === item.id
      )[0];
      this.editedIndex = this.subcategories.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    updateIsFeatured(item) {
      item.is_featured = item.is_featured ? 1 : 0;
      let data = { id: item.id, is_featured: item.is_featured ? 1 : 0 };
      let temp = this.subcategories.filter(
        (category) => category.id === item.id
      )[0];
      this.editedIndex = this.subcategories.indexOf(temp);
      let temp1 = Object.assign({}, this.subcategories[this.editedIndex], item);
      this.updateFeaturedSubCategory(data)
        .then(() => {
          Object.assign(this.subcategories[this.editedIndex], temp1);
          this.$store.commit("setCategories", this.subcategories);
          this.editedIndex = -1;
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.editedIndex = -1;
        });
    },

    deleteItemConfirm() {
      this.loading = true;
      this.deleteSubCategory(this.editedItem.id)
        .then(() => {
          this.subcategories.splice(this.editedIndex, 1);
          this.$store.commit("setSubCategories", this.subcategories);
          Swal.fire("Success!", "SubCategory Deleted Successfully!", "success");
          this.editedIndex = -1;

          this.loading = false;
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.loading = false;
          this.editedIndex = -1;
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        let fd;
        fd = new FormData();
        if (typeof this.editedItem.image === "object")
          fd.append("image", this.editedItem.image);
        fd.append("name", this.editedItem.name);
        fd.append("title", this.editedItem.title);
        fd.append("cat_id", this.editedItem.cat_id);
        fd.append("order", this.editedItem.order);
        fd.append("description", this.editedItem.description);

        this.loading = true;
        if (this.editedIndex > -1) {
          let temp = Object.assign(
            {},
            this.subcategories[this.editedIndex],
            this.editedItem
          );
          let temp1 = this.category.filter(
            (category) => category.id === this.editedItem.cat_id
          )[0];

          this.updateSubCategory({ id: this.editedItem.id, fd: fd })
            .then((response) => {
              Object.assign(this.subcategories[this.editedIndex], temp);
              Object.assign(
                this.subcategories[this.editedIndex].category,
                temp1
              );
              this.$store.commit("setSubCategories", this.subcategories);
              this.editedIndex = -1;
              this.loading = false;
              this.$refs.form.reset();
              Swal.fire(
                "Success!",
                "SubCategory Updated Successfully!",
                "success"
              );
              console.error(response.status);
            })
            .catch((error) => {
              this.editedIndex = -1;
              this.loading = false;
              Swal.fire("Oops...", "Something went wrong!", "error");
              console.log(error);
            });
        } else {
          this.storeSubCategory(fd)
            .then(() => {
              this.loading = false;
              Swal.fire(
                "Success!",
                "SubCategory Added Successfully!",
                "success"
              );
              this.$refs.form.reset();
            })
            .catch(() => {
              this.loading = false;
              this.editedIndex = -1;
              Swal.fire("Oops...", "Something went wrong!", "error");
            });
        }
        this.close();
      }
    },
  },
  mounted() {
    if (this.getAllCategories.length === 0) {
      this.getCategories().then((response) => {
        this.category = response;
      });
    } else {
      this.category = this.getAllCategories;
    }
    if (this.getAllSubCategories.length === 0) {
      this.getSubCategories().then((response) => {
        this.subcategories = response;
      });
    } else {
      this.subcategories = this.getAllSubCategories;
    }
  },
};
</script>
<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0 !important;
}
</style>
