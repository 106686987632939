<template>
  <v-container>
      <v-row>
          <v-col cols="12">
              <v-card elevation="5">
                  <v-card-title>Staff Permissions &amp; Roles
                    <!-- <v-btn rounded color="primary" class="ml-4" dark @click="dialog=true">Add Roles</v-btn> -->
                    <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                ></v-text-field>
            </v-card-title>
                <v-data-table
                    :headers="headers"
                    :items="roles"
                    :search="search"
                >
                <template v-slot:item.actions="{ item }">
                    <div class="d-flex justify-left align-items-center">
                        <div class="my-2">
                            <v-btn color="error" fab x-small dark @click="deleteData(item.id)">
                                <v-icon dark>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </template>
              </v-data-table>
            </v-card>
            <v-dialog v-model="dialog" max-width="500px">
                    <v-card>
                        <v-card-title>
                            Add Roles
                        </v-card-title>
                        <v-card-text>
                            <v-form>
                                <v-text-field
                                    outlined
                                    dense
                                    label="Roles"
                                ></v-text-field>
                                
                                <v-btn color="primary" @click="submit"
                                    >Submit</v-btn
                                >
                            </v-form>
                        </v-card-text>
                    </v-card>
            </v-dialog>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            search: '',
            roles: [{sn: '1', role: 'Admistrator', created_at: '04/05/2021'}],
            headers: [
                { text: "S.N.", value: "sn" },
                {
                text: 'Roles',
                align: 'start',
                sortable: false,
                value: 'role',
                },
                { text: 'Created Date', value: 'created_at' },
                { text: 'Actions', value: 'actions' }
            ],
        }
    }

}
</script>

<style>

</style>