<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title
            >All Customer Details
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              @keyup.enter="searchs"
              append-icon="mdi-magnify"
              label="Search"
              placeholder="Press Enter to search"
              single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="
              filtered.length > 0 || noItem ? filtered : getAllCustomersList
            "
            @pagination="paginate"
            :footer-props="{ 'items-per-page-options': [10, 15, 20] }"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.profile_photo="{ item }">
              <v-dialog
                v-if="item.profile_photo !== null"
                :id="'dialog' + item.id"
                width="600"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar size="60" v-bind="attrs" v-on="on">
                    <img
                      :src="item.profile_photo"
                      alt="banner-image"
                      class="img-thumbnail my-2"
                      width="100"
                      height="60"
                      @error="item.profile_photo = `/img/sajha.jpg`"
                    />
                  </v-avatar>
                </template>
                <v-card>
                  <v-card-title style="background-color: #163691; color: white"
                    >User Profile Image</v-card-title
                  >
                  <img :src="item.profile_photo" width="100%" />
                </v-card>
              </v-dialog>
              <p v-else>NA</p>
            </template>
            <template v-slot:item.balance="{ item }">
              {{ getWalletBalance(item) }}
            </template>
            <template v-slot:item.status="{ item }">
              <div class="d-flex justify-left align-items-center">
                <div class="pb-4">
                  <v-switch
                    @change="updateUserStatus(item)"
                    v-model="item.status"
                    color="success"
                    hide-details
                  ></v-switch>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { imageMixin } from "../../../mixin/helperMixin";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  mixins: [imageMixin],

  data() {
    return {
      search: "",
      loading: false,
      noItem: false,
      filtered: [],
      selected: [],
      expanded: [],
      wallet_balance: "",
      headers: [
        { text: "S.N.", value: "sn" },
        {
          text: "Customer Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Profile", value: "profile_photo" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "DOB", value: "dob" },
        { text: "Wallet", value: "balance" },
        { text: "Status", value: "status" },
        { text: "Joined At", value: "created_at" },
      ],
    };
  },
  computed: {
    ...mapState({
      customers: (state) => state.customers,
    }),
    getAllCustomersList() {
      return this.customers.map((item, index) => ({
        ...item,
        sn: index + 1,
        name: item.name,
        profile_photo: item.details && item.details.avatar,
        email: item.email,
        phone: item.phone,
        dob: (item.details && item.details.dob) ?? 'N/A',
        status: !!item.emailverified,
        created_at: moment(item.created_at).format("DD MM YYYY"),
      }));
    },
  },
  methods: {
    getWalletBalance(item) {
      if (item.wallet.length === 0) {
        return 0;
      } else {
        const finalTotal = item.wallet.reduce((tot, cur) => {
          if (cur.credit === 1) {
            tot = tot + parseInt(cur.amount);
          }
          if (cur.debit === 1) {
            tot = tot - parseInt(cur.amount);
          }
          return tot;
        }, 0);
        return finalTotal > 0 ? finalTotal : 0;
      }
    },
    ...mapActions(["getAllCustomers", "updateCustomerStatus"]),

    updateUserStatus(item) {
      let temp = this.customers.filter(
        (customer) => customer.id === item.id
      )[0];
      this.editedIndex = this.customers.indexOf(temp);
      this.updateCustomerStatus([item.id])
        .then((response) => {
          let value;
          if (response.data.data === null) {
            value = null;
          } else {
            value = response.data.data;
          }
          this.customers[this.editedIndex].emailverified = value;
          this.$store.commit("setCustomerLists", this.customers);
          this.editedIndex = -1;
        })
        .catch(() => {
          Swal.fire("Oops...", "Something went wrong!", "error");
          this.editedIndex = -1;
        });
    },
    onImgError(event) {
      event.target.src = `/img/sajha.jpg`;
    },
    paginate(e) {
      if (this.search === "" || this.search.indexOf(" ") >= 0) {
        if (this.$store.state.pages.customers < e.page) {
          this.$store.commit("setCustomerPage", e.page);
          this.getAllCustomers(e.page);
        }
      }
    },
    searchs() {
      if (this.search === "") {
        this.noItem = false;
        this.filtered = [];
      } else {
        this.loading = true;
        this.$store
          .dispatch("getCustomerSearch", this.search)
          .then((res) => {
            console.log(res);
            this.loading = false;
            this.filtered = this.mapSearch(res);

            if (res.length === 0) {
              this.noItem = true;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    mapSearch(item) {
      return item.map((item, index) => ({
        ...item,
        sn: index + 1,
        name: item.name,
        profile_photo: item.details && item.details.avatar,
        email: item.email,
        phone: item.phone,
        dob: item.details && item.details.dob,
        status: !!item.emailverified,
        created_at: moment(item.created_at).format("DD MM YYYY"),
      }));
    },
  },
  mounted() {
    if (!this.customers.length) {
      this.getAllCustomers();
    }
  },
};
</script>

<style></style>
