export default {
  methods: {
    formatLabel(val) {
      const key = Object.keys(val)[0];
      return key.split("_").reduce((acc, cur) => {
        cur = cur.charAt(0).toUpperCase() + cur.slice(1);
        acc = acc + " " + cur;
        return acc;
      }, "");
    },
  },
};
