import { Bar } from "vue-chartjs";

export default {
    extends: Bar,
    props: ['options','cdata'],
    data: function () {
		return {
			datacollection: {
				labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
				datasets: [
					{
						label: 'Customer',
						backgroundColor: '#ff8101f2',
						pointBackgroundColor: 'white',
						borderWidth: 1,
						pointBorderColor: '#249EBF',
						data: this.cdata,
					},
					
                ]
			},
			customoptions: {
				
				responsive: true,
				maintainAspectRatio: false,
            }
		}
	},
	mounted () {
		// this.chartData is created in the mixin
		this.renderChart(this.datacollection, this.customoptions)
	}
}