<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>Wishlists Report
            <div class="pt-4 px-4">
              <v-autocomplete
                  v-model="selectedCategory"
                  :items="cats"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  label="Filter According to Category"
                  @change="filterData"
              ></v-autocomplete>
            </div>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
            ></v-text-field>
            <v-btn color="primary" class="ml-4" rounded @click="exportToExcel">Export to Excel</v-btn>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="filterWishListsWithSN"
              :search="search"
          >
          </v-data-table>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {json2excel} from 'js2excel';
import {mapGetters, mapState} from "vuex";

export default {
  Components: {json2excel},
  data() {
    return {
      dialog: false,
      search: '',
      // wishlists: [{sn: '1', products_name: 'Samsung Television 32Inch', seller_name: 'Samsung Electronics', total_number: '10' }],
      headers: [
        {text: "S.N.", value: "sn"},
        {
          text: 'Products Name',
          align: 'start',
          sortable: false,
          value: 'products_name',
        },
        {text: 'Seller Name', value: 'seller_name'},
        {text: 'Total Number', value: 'total_number'},
      ],
      selectedCategory: "",
      filteredWishLists: [],
      filterWishListsWithSN: [],
    }
  },
  watch: {
    selectedCategory(val) {
        if (val)
        this.filteredWishLists = this.wishLists.filter(s => (s.category_id === val));
      else
        this.filteredWishLists = this.wishLists;
    },
    filteredWishLists(val) {
      this.filterWishListsWithSN = val.map((w, index) => ({...w, sn: index+1}));
    },
    wishLists(val) {
      this.filteredWishLists = val;
    },
  },
  computed: {
    ...mapState(["wishLists", "categories"]),
    cats() {
      return this.getCategories();
    },
  },
  mounted() {
    if (this.wishLists.length === 0) {
      this.$store.dispatch("getWishLists");
    }

    if (this.categories.length === 0) {
      this.$store.dispatch("getCategories");
    }
  },
  methods: {
    exportToExcel() {
      // this will be export a excel and the file's name is user-info-data.xlsx
      // the default file's name is excel.xlsx
      let data = this.filterWishListsWithSN;
      try {
        json2excel({
          data,
          name: 'wishlists-report',
          // formateDate: 'yyyy/mm/dd'
        });
      } catch (e) {
        alert("Something Went Wrong. Try Again!");
      }
    },
    filterData(val) {
      let filtered = this.wishLists.filter(w => w.category_id === val);
      if (filtered.length === 0) return this.wishLists;
      else return filtered;
    },
    ...mapGetters(["getCategories"]),
  },

}
</script>

<style>

</style>