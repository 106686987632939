<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>Notifications</v-card-title>
          <v-card-text>
            <div v-if="!loading">
              <div v-if="notifications.length" class="notifications">
                <div v-for="item in notifications" :key="item.orderCode">
                  <h5>
                    <strong>{{ item.user }}</strong> has placed a new order with
                    code <strong> {{ item.orderCode }} </strong>
                  </h5>
                  <p class="font-small">
                    <v-icon class="pr-2" size="14">mdi-clock</v-icon>
                    {{ humanizeTime(item.created_at) }}
                  </p>
                  <v-divider></v-divider>
                </div>
              </div>
              <p v-else>No Notifications</p>
            </div>

            <v-skeleton-loader
              v-else
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      notifications: [],
      loading: true,
    };
  },
  methods: {
    humanizeTime(date) {
      return moment(String(date)).fromNow();
    },
  },
  created() {
    this.$store.dispatch("getAllNotification").then((res) => {
      this.loading = false;
      this.notifications = res;
    });
    this.$store.dispatch("markAsRead");
    this.show = false;
  },
};
</script>

<style>
.font-small {
  font-size: 0.7rem;
  margin-bottom: 0.25rem !important;
}
</style>
