<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="teal"
        ></v-progress-linear>

        <v-data-table
            :headers="headers"
            :items="getAllJobApplications"
            :search="search"
            class="elevation-5 pt-2"
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>Job Application Details</v-toolbar-title>


              <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
              ></v-text-field>
              <v-dialog v-model="dialogDelete" max-width="400px">
                <v-card>
                  <v-card-text class="pt-4 text-center">
                    <v-icon color="error" size="50">mdi-close-circle</v-icon>
                    <h2 class="font-bold py-4">Are you sure want to delete this Item?</h2>
                    <h4 class="font-bold text-left">Please be aware that you cannot revert this item in future.</h4>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="px-8" rounded dark @click="deleteItemConfirm"
                    >OK</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.resume`]="{ item }">
            <a :href="item.resume" target="_blank" style="color: black;text-decoration: none">View Resume</a>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ formatDate(item.created_at) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">

            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {dateMixin, imageMixin} from "../../../mixin/helperMixin";
import Swal from "sweetalert2";

export default {
  mixins: [dateMixin, imageMixin],
  data: () => ({
    loading: false,
    valid: false,
    dialog: false,
    dialogDelete: false,
    search: "",
    jobApplications: [],
    headers: [
      {text: "S.N.", value: "sn"},
      {
        text: "Applied For",
        align: "start",
        sortable: false,
        value: "job",
      },
      {text: "Name", value: "name"},
      {text: "Email", value: "email"},
      {text: "Phone", value: "phone"},
      {
        text: "Resume",
        align: "start",
        sortable: false,
        value: "resume",
      },


      {text: "Applied On", value: "created_at"},
      {text: "Actions", value: "actions", sortable: false},
    ],
    editedIndex: -1,
    editedItem: {

    },
    defaultItem: {

    },

  }),

  computed: {
    ...mapGetters({
      getAllJobApplications: "getJobApplications",
    }),

    getAllJobApplications() {
      return this.jobApplications.map((item, index) => ({
        ...item,
        sn: index + 1,
        name: item.name,
        email:item.email,
        phone:item.phone,
        resume:item.resume,
        job:item.job && item.job.job_title

      }));
    },


  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions(["getJobApplication","deleteJobApplication"]),



    deleteItem(item) {
      let temp = this.jobApplications.filter((jobApplication) => jobApplication.id === item.id)[0];
      this.editedIndex = this.jobApplications.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading=true;
      this.deleteJobApplication(this.editedItem.id)
          .then(() => {
            this.loading=false;
            this.$store.commit("setJobApplications", this.jobApplications);
            this.jobApplications.splice(this.editedIndex, 1);
            Swal.fire('Success!', 'JobApplication Deleted Successfully!', 'success')
            this.editedIndex = -1;
          })
          .catch(() => {
            this.loading=false;
            Swal.fire('Oops...', 'Something went wrong!', 'error')
            this.editedIndex = -1;
          });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);

        // this.editedIndex = -1
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1;
      });
    },


  },
  mounted() {
    if (!this.getAllJobApplications.length) {
      this.getJobApplication().then((response) => {
        this.jobApplications = response;
        console.log(response.status);
      });
    } else {
      this.jobApplications = this.getAllJobApplications;
    }
  },
};
</script>
<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0 !important;
}
</style>
