<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title
            >Categories Report
            <!-- <div class="pt-4 px-4">
              <v-autocomplete
                  :items="sub_categories"
                  outlined
                  dense
                  label="Filter Sub Category"
              ></v-autocomplete>
            </div> -->
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
            ></v-text-field>
            <v-btn color="primary" class="ml-4" rounded @click="exportToExcel"
              >Export to Excel</v-btn
            >
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="formattedData"
            :search="search"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { json2excel } from "js2excel";
import { mapState } from "vuex";

export default {
  Components: { json2excel },
  data() {
    return {
      dialog: false,
      search: "",
      sub_categories: ["Mobile Phone", "Furnitures", "Books"],
      headers: [
        { text: "S.N.", value: "sn" },
        {
          text: "Categories Name",
          align: "start",
          sortable: false,
          value: "categories_name",
        },
        { text: "Total Products", value: "total_products" },
        { text: "Total Quantity", value: "total_quantity" },
        { text: "Total Sales", value: "sales" },
      ],
      // formattedData: [],
    };
  },
  computed: {
    ...mapState(["categoryReports"]),
    formattedData() {
      return this.categoryReports.map((item, index) => {
        if (!item.sales) item.sales = 0;
        return { ...item, sn: index + 1 };
      });
    },
  },
  watch: {},
  methods: {
    exportToExcel() {
      // this will be export a excel and the file's name is user-info-data.xlsx
      // the default file's name is excel.xlsx
      let data = this.categories;
      try {
        json2excel({
          data,
          name: "categories-report",
          // formateDate: 'yyyy/mm/dd'
        });
      } catch (e) {
        alert("Something Went Wrong. Try Again!");
      }
    },
  },
  mounted() {
    if (this.categoryReports.length === 0) {
      this.$store.dispatch("getCategoryReport");
    }
  },
};
</script>

<style></style>
