<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="6">
        <v-card elevation="5">
          <v-card-title>Change Password</v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-text-field
                v-model="passwordObj.oldpassword"
                label="Old Password"
                outlined
                dense
                :rules="passwordRules"
              ></v-text-field>
              <v-text-field
                v-model="passwordObj.password"
                label="New Password"
                outlined
                dense
                :rules="passwordRules"
              ></v-text-field>
              <v-text-field
                v-model="passwordObj.newpassword"
                label="Confirm Password"
                outlined
                dense
                :rules="passwordRules"
              ></v-text-field>
              <v-btn @click="submit" color="success" rounded>
                Change Password
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card elevation="5">
          <v-card-title>Profile</v-card-title>
          <v-card-text>
            <div class="text-center">
              <div class="profile-image" style="width: 100%; height: 100px">
                <img
                  src="/img/logo1.png"
                  alt="Doormeet"
                  width="100px"
                  height="100px"
                  style="border-radius: 50%"
                />
              </div>
              <div class="profile-details mt-4">
                <h3 class="font-bold">Username: Admin</h3>
                <h3 class="font-bold my-2">Role: Admin</h3>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 6) || "Password must have 6+ characters",

        (v) => /(?=.*\d)/.test(v) || "Password must have one number",
      ],
      passwordObj: {
        newpassword: "",
        oldpassword: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions(["changePassword"]),
    submit() {
      if (this.$refs.form.validate()) {
        this.changePassword(this.passwordObj).then((res) => {
          if (res.data === 204) {
            Swal.fire(
              "something wrong",
              "Please check if your old password is correct, or new passwords match.",
              "error"
            );
          } else if (res.data === 200) {
            this.$refs.form.reset();
            Swal.fire("Password changed!", "", "success");
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.font-bold {
  color: #0f0f0f;
  font-weight: bold;
}
</style>
