<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>New Tickets
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="getSupport"
              :search="search"
          >
            <template v-slot:item.subject="{ item }">
              <v-dialog :id="'descriptionDialog' + item.id" width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                    <v-icon size="14" class="pr-2">mdi-eye</v-icon>
                    Subject
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title
                  >Subject Details
                  </v-card-title
                  >
                  <v-card-text class="py-2">
                    <p>
                      {{item.subject}}</p>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.description="{item}">
              <v-dialog :id="'descriptionDialog' + item.id" width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                    <v-icon size="14" class="pr-2">mdi-eye</v-icon>
                    Description
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title
                  >Support Description
                  </v-card-title
                  >
                  <v-card-text class="py-2">
                    <p>{{item.description}}</p>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.support_image="{ item }">
              <v-dialog :id="'imageDialog' + item.id" width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-if="item.image" small color="primary" dark v-bind="attrs" v-on="on">
                    <v-icon size="14" class="pr-2">mdi-eye</v-icon>
                    Image
                  </v-btn>
                  <p v-else>NA</p>
                </template>

                <v-card>
                  <v-card-title
                  >Support Image
                  </v-card-title
                  >
                  <v-card-text class="py-2">
                    <img :src="item.image" width="100%"
                         alt="">
                  </v-card-text>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-left align-items-center">
                <div class="my-2">
                  <v-btn color="primary" class="my-2" small dark @click="updateCase(item,'status')">
                    Accept
                  </v-btn>

                  <v-dialog :id="'rejectDialog' + item.id" width="600">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small color="primary" dark v-bind="attrs" v-on="on">
                        Reject
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                      >Reason
                      </v-card-title
                      >
                      <v-card-text class="py-2">
                        <v-textarea v-model="item.reason"></v-textarea>
                        <v-btn @click="updateCase(item,'status')">Submit</v-btn>
                      </v-card-text>
                    </v-card>

                  </v-dialog>
                </div>
              </div>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ formatDate(item.created_at) }}
            </template>
          </v-data-table>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapActions} from "vuex";
import {dateMixin} from "../../../mixin/helperMixin";

export default {
  mixins: [dateMixin],

  data() {
    return {
      dialog: false,
      search: '',
      new_tickets: [

      ],
      headers: [
        {text: "S.N.", value: "sn"},
        {text: "Ticket Id", value: "ticket_id", align: 'start'},
        {text: "Customer Name", value: "customer_name"},
        {
          text: 'Category',
          value: 'category',
        },
        {
          text: 'Subject',
          value: 'subject',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {text: 'Image', value: 'support_image'},
        {text: 'Support Type', value: 'support_type'},
        {text: 'Opened Date', value: 'created_at'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
    }
  },
  computed: {
    getSupport() {
      return this.new_tickets.map((item, index) => ({
        ...item,
        sn: index + 1,
        ticket_id:item.code,
        category: item.category,
        subject: item.subject,
        support_type: item.type,
        description: item.description,
        is_closed: item.is_closed,
        is_resolved: item.is_resolved,
        customer_name:item.user&&item.user.name



        // banner_image:item.banner,
      }));
    },
  },
  methods: {
    ...mapActions([
      "getSupports",
      "updateSupport"
    ]),
    editItem(item) {
      this.editedIndex = this.new_tickets.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    updateCase(item, type) {

      let value;
      if (type === "status") {
        if (item.status === 0)
          value = 1;
        else
          value = 0;
      }


      let temp = this.new_tickets.filter(supports => supports.id === item.id)[0];

      let data;
      this.editedIndex = this.new_tickets.indexOf(temp);


      data = {'id': item.id, 'status': value, 'reason': item.reason};
      temp.status = value;
      temp.reason = item.reason;


      this.updateSupport(data).then(response => {

        this.new_tickets.splice(this.editedIndex, 1);
        this.$store.commit("updateSupports", response.data.support);


      })
          .catch(error => {

            console.error(error);
          });


    },
  },
  mounted() {
    if (!this.$store.state.supports) {
      this.getSupports().then((response) => {
        console.log(response.support);
        this.new_tickets = response.newCase;


      });
    } else {
      this.new_tickets = this.$store.state.supports.newCase;


    }

  }

}
</script>

<style>

</style>
